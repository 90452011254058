

const COUNTRY_CODE = "+1";


/**
 * Use this for type hints under js file
 * @type import('naive-ui').GlobalThemeOverrides
 */
 const themeOverrides = {
    common: {
      backgroundActiveColor: '#e5effa',
      backgroundSelectedColor: '#c2dcf2',
      backgroundDeleteColor: '#dadfe2',
      backgroundMeColor: '#ccf2cf',
      autoPlayColor: '#455247',
      autoCancelColor: '#eb4034',
      autoConfirmColor: '#1ba65b',
      messageColor: '#67717a',
      timeStampColor: '#a2aeb8',
      timeStampMessageColor: '#828c94',
      dateMessageColor: '#505a62',
      backgroundReactionColor: '#eeeeee',
      backgroundReactionMeColor: '#cfecf5',
      borderStyleReactionMe: '#3b98b8',
      reactionCounterMe: '#0b59b3',
      emojiReaction: '#0000004d',
      borderStyleInput: '#e1e4e8',
      dropdownMessageBackground: '#00000040',
      markdownColor: '#e01e5a',
      borderColorSearch: '#e1e5e8',
      backgroundMedia: '#00000026',
      backgroundReply: '#00000014',
      stateColor: '#4caf50',
      badgeColor: '#0696C7',
      contentColor: '#f8f9fa',
      replyColor: '#6e6e6e',
      tagColor: '#0D579C',
      tagActiveColor: '#e5e5e6',
      spinnerColor: '#333333',
      borderChatColor: '#9CA6AF',
      borderColor: '#DDDDDD',
      borderLightColor: '#D4D4D4',
      borderHoverColor: '#AAAEB7',
      hoverColor: '#F3F3F3',
      iconColor: '#959595',
      darkColor: '#121417',
      darkColorHover: '#121417',
      darkColorPressed: '#121417',
      darkColorSuppl: '#121417',
      primaryColor: '#0B40A8',
      primaryColorHover: '#0B40A8',
      primaryColorPressed: '#0B40A8',
      primaryColorSuppl: '#0B40A8',
      secondaryColor: '#C0C4CC',
      secondaryColorHover: '#C0C4CC',
      secondaryColorPressed: '#C0C4CC',
      secondaryColorSuppl: '#C0C4CC',
      warningColor: '#F8D613',
      warningColorHover: '#F8D613',
      warningColorPressed: '#F8D613',
      warningColorSuppl: '#F8D613',
      successDisabledColor: '#A3D9B1',
      successColor: '#18A058',
      successColorHover: '#18A058',
      successColorPressed: '#18A058',
      successColorSuppl: '#18A058',
      dangerColor: '#FF6F60',
      dangerColorHover: '#FF6F60',
      dangerColorPressed: '#FF6F60',
      dangerColorSuppl: '#FF6F60',
      infoColor: '#70C0E8',
      infoColorHover: '#70C0E8',
      infoColorPressed: '#70C0E8',
      infoColorSuppl: '#70C0E8',
      blackColor: '#000000',
      whiteColor: '#FFFFFF',
      greenColor: '#2E8518',
      greenDarkColor: '#0E7A0D',
      redColor: '#FF0000',
      orangeColor: '#FCB007',
      grayDarkColor: '#2C3E50',
      graySemiColor: '#DBDBDB',
      grayMediumColor: '#9E9E9E',
      grayColor: '#EFEFEF',
      grayLightColor: '#EFEFF5',
      deleteColor: '#757e85',
      disabledColor: '#F6F6F6'
    },
    DataTable: {
      tdPaddingSmall: "5px",
      thIconColor:"#FFFFFF",
      thColor: "#0B40A8",
      thColorHover: "#0B40A8",
      thTextColor: "#FFFFFF",
      thIconColorActive: "#FCB007",
      loadingColor: "#0B40A8"
    }
  }



  export { themeOverrides, COUNTRY_CODE}
