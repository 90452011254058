import { Auth } from 'aws-amplify';

export const auth = {
    namespaced: true,
    state: () => ({
        contextUser: null,
        cognitoIdentityId: null
    }),
    getters: {
        is_authenticated: (state) => state.contextUser !== null,
        email: (state) => state.contextUser?.attributes.email,
        user_id: (state) => state.contextUser?.attributes.sub,
        roles: (state) => state.contextUser?.signInUserSession.idToken.payload["cognito:groups"]
    },
    mutations: {
        setContextUser (state, newContextUser) {
            state.contextUser = newContextUser;
        },
        clearContextUser (state) {
            state.contextUser = null;
            state.cognitoIdentityId = null;
        },
        setCognitoIdentityId (state, id) {
            state.cognitoIdentityId = id;
        }
    },
    actions: {
        setContextUser ({ commit }){            
            Auth.currentAuthenticatedUser()
                    .then(
                        (x) => commit('setContextUser', x))
                    .catch(
                        () => true
                    );
            Auth.currentCredentials().then((info) => {
                commit('setCognitoIdentityId', info.identityId);
                });
                    
        },
        clearContextUser ({ commit, dispatch }) {
            commit('clearContextUser');
            dispatch('user/cleanUser',{}, {root:true})
        }
    }
}