// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PublicationState = {
  "ACTIVE": "ACTIVE",
  "PAUSED": "PAUSED",
  "INACTIVE": "INACTIVE",
  "DRAFT": "DRAFT"
};

const UnitTime = {
  "DAY": "DAY",
  "WEEK": "WEEK",
  "MONTH": "MONTH",
  "YEAR": "YEAR"
};

const InputType = {
  "STRING": "STRING",
  "BOOLEAN": "BOOLEAN",
  "NUMBER": "NUMBER"
};

const RentState = {
  "UNPAID": "UNPAID",
  "PENDING": "PENDING",
  "IN_PROCESS": "IN_PROCESS",
  "RETURNED": "RETURNED",
  "FINISHED": "FINISHED",
  "ARBITRATION_IN_PROCESS": "ARBITRATION_IN_PROCESS",
  "LOST": "LOST",
  "PENDING_FOR_PAY_EXTRA_DAYS": "PENDING_FOR_PAY_EXTRA_DAYS",
  "PENDING_FOR_CHANGE_PAYMENT_METHOD": "PENDING_FOR_CHANGE_PAYMENT_METHOD",
  "DEPOSIT_PENDING": "DEPOSIT_PENDING",
  "CANCELLED": "CANCELLED"
};

const RangeOfDatesType = {
  "INITIAL": "INITIAL",
  "EXTENSION": "EXTENSION",
  "MODIFICATION": "MODIFICATION",
  "DELAYED_RETURN": "DELAYED_RETURN"
};

const ReversePaymentInfoType = {
  "REVERSE": "REVERSE",
  "REFUND": "REFUND"
};

const PaymentType = {
  "INITIAL": "INITIAL",
  "FOR_EXTRA_DAYS": "FOR_EXTRA_DAYS",
  "FOR_DELAY_RETURN": "FOR_DELAY_RETURN",
  "FOR_DEPOSIT": "FOR_DEPOSIT"
};

const IssueRentState = {
  "OPEN": "OPEN",
  "CLOSED": "CLOSED",
  "IN_PROCESS": "IN_PROCESS",
  "WAITING_REFUND": "WAITING_REFUND"
};

const TypeIssueResolution = {
  "ONE": "ONE",
  "TWO": "TWO",
  "THREE": "THREE",
  "FOUR": "FOUR",
  "FIVE": "FIVE",
  "SIX": "SIX"
};

const MaintenanceState = {
  "PENDING": "PENDING",
  "DONE": "DONE",
  "DELETED": "DELETED"
};

const SentState = {
  "PENDING": "PENDING",
  "SENT": "SENT",
  "ERROR": "ERROR"
};

const BalanceTransactionType = {
  "INCOME": "INCOME",
  "WITHDRAWAL": "WITHDRAWAL"
};

const BalanceTransactionStatus = {
  "PENDING": "PENDING",
  "PROCESSING": "PROCESSING",
  "PROCESSED": "PROCESSED",
  "FAILED": "FAILED",
  "RETURNED": "RETURNED"
};

const PayoutProfilePayoutMethodType = {
  "BANK_TRANSFER": "BANK_TRANSFER",
  "PAYPAL": "PAYPAL"
};

const DepositTransactionsToRefundStatus = {
  "IN_QUEUE": "IN_QUEUE",
  "IN_PROGRESS": "IN_PROGRESS",
  "REFUNDED": "REFUNDED",
  "FAILED": "FAILED"
};

const { Publication, Category, ToolRent, RentIssue, Question, User, Notification, UserBalanceTransaction, Parameter, ChatMessage, ChatRoom, Review, DepositTransactionsToRefund, SearchActivePublicationsObjectConnection, PublicationCosts, LocationInfo, GeoPoint, CheckListField, RangeOfDatesHistory, RangeOfDates, PaymentInfo, ReversePaymentInfo, PaymentStatusHistoryItem, PaymentAmount, PaymentReverseRefundedTransaction, DeliveryInfo, CheckListValues, Evidence, Resolution, Maintenance, Address, Avatar, TargetRoute, KeyValue, NotificationSettings, NotificationChannel, MobileDevice, PayoutProfileAddress, PayoutProfilePayoutMethod, PayoutProfile, ChatFile, ChatUser } = initSchema(schema);

export {
  Publication,
  Category,
  ToolRent,
  RentIssue,
  Question,
  User,
  Notification,
  UserBalanceTransaction,
  Parameter,
  ChatMessage,
  ChatRoom,
  Review,
  DepositTransactionsToRefund,
  PublicationState,
  UnitTime,
  InputType,
  RentState,
  RangeOfDatesType,
  ReversePaymentInfoType,
  PaymentType,
  IssueRentState,
  TypeIssueResolution,
  MaintenanceState,
  SentState,
  BalanceTransactionType,
  BalanceTransactionStatus,
  PayoutProfilePayoutMethodType,
  DepositTransactionsToRefundStatus,
  SearchActivePublicationsObjectConnection,
  PublicationCosts,
  LocationInfo,
  GeoPoint,
  CheckListField,
  RangeOfDatesHistory,
  RangeOfDates,
  PaymentInfo,
  ReversePaymentInfo,
  PaymentStatusHistoryItem,
  PaymentAmount,
  PaymentReverseRefundedTransaction,
  DeliveryInfo,
  CheckListValues,
  Evidence,
  Resolution,
  Maintenance,
  Address,
  Avatar,
  TargetRoute,
  KeyValue,
  NotificationSettings,
  NotificationChannel,
  MobileDevice,
  PayoutProfileAddress,
  PayoutProfilePayoutMethod,
  PayoutProfile,
  ChatFile,
  ChatUser
};