import { API, graphqlOperation, Auth, PubSub } from "aws-amplify";
import { listDeepNotifications } from "../../graphql/custom/queries";
import { updateNotification } from "../../graphql/mutations";
import { notificationActionToRoute } from "../../shared/navigations"


const apiName = "notifications";
const pathCreate = "/create";
const apiRoleName = "iotroles";
const pathRoles = "/iot/roles";

const compareDates = (a,b) => {
    const da = Date.parse(a.datetime);
    const db = Date.parse(b.datetime);
    if ( da < db ){
      return -1;
    }
    if ( da > db ){
      return 1;
    }
    return 0;
  }

export const notifications = {  
    namespaced: true,
    state: () => ({
        list: [],
        pubsub: null
    }),
    mutations: {
        addNotification: (st, value) => {
            st.list.push(value);
            st.list.sort(compareDates).reverse();
        },
        addNotifications: (st, value) => {
            st.list.push(...value);
            st.list.sort(compareDates).reverse();
        },
        setPubSub: (st, value) => st.pubsub = value,
        clearNotifications: (state) => state.list = []
    },
    actions: {
        FillNotifications({ commit }) {
            API.graphql({...graphqlOperation(listDeepNotifications)
                           , authMode: 'AMAZON_COGNITO_USER_POOLS'
                        }).then((response) => {
                            commit('addNotifications', response.data.listNotifications.items);
                        });
        },
        FireNotification(_context, notification) {  
            console.debug("FireNotification - started", notification)
            const payload = {
                ...notification,
                datetime: new Date().toISOString(),
                read: false,
                sentState: 'PENDING'
            }     
            Auth.currentSession().then((session) => {
                API.post(apiName, pathCreate, {
                    body: {
                        auth: session.getAccessToken().getJwtToken(),
                        payload
                    }
                }).then((res) => {
                    payload.id = res.data.createNotification.id;
                    //commit('addNotification', payload)
                });
            });
            console.debug("FireNotification - end")
        },
        MarkNotificationAsRead({ state }, notification){

            API.graphql({...graphqlOperation(updateNotification, {input: { id: notification.id, read: true, _version: notification._version}})
                , authMode: 'AMAZON_COGNITO_USER_POOLS'
             }).then(()=> state.list.forEach((n) => { if (n.id == notification.id){ n.read = true}}));            
        },
        ConvertToRoute(context, route) {
            if (route) {
                return notificationActionToRoute(route);
            }
        },
        SubscribeNotificationWeb({ commit, state }, userId) {
            if (state.pubsub === null) {
            Auth.currentCredentials().then((info) => {            
                const cognitoIdentityId = info.identityId;
                
                API.post(apiRoleName, pathRoles, {
                    body: {
                        identity: cognitoIdentityId
                    }
                }).then(() => {
                    const sub = PubSub.subscribe(`/${userId}`).subscribe({
                        next: data => {
                            commit('addNotification', data.value);
                        },
                        error: error => console.error(error),
                        });
                    commit('setPubSub', sub);
                });
            });
            }
        },
        UnsubscribeNotificationWeb({commit, state}){
            state.pubsub?.unsubscribe();
            commit('setPubSub', null);
            commit('clearNotifications');
            Auth.currentCredentials().then((info) => {               
                const cognitoIdentityId = info.identityId;
                API.del(apiRoleName, pathRoles, {
                    body: {
                        identity: cognitoIdentityId
                    }
                });
            });
            
        }
    }
}
