<template>
  <n-divider title-placement="left">
    {{ t("home.notifications.tabs.settings.title") }}
  </n-divider>
  <n-grid cols="2" responsive="screen" :y-gap="8" v-if="user">
    <n-gi class="text-left pl-2">{{
      t("home.notifications.tabs.settings.options.mobile")
    }}</n-gi
    ><n-gi class="text-center"
      ><n-switch
        :loading="isUpdating"
        @update:value="handleChange"
        v-model:value="user.notificationSettings.channels.mobile" /></n-gi
    ><n-gi class="text-left pl-2">{{
      t("home.notifications.tabs.settings.options.web")
    }}</n-gi
    ><n-gi class="text-center"
      ><n-switch
        :loading="isUpdating"
        @update:value="handleChange"
        v-model:value="user.notificationSettings.channels.web" /></n-gi
    ><n-gi class="text-left pl-2">{{
      t("home.notifications.tabs.settings.options.email")
    }}</n-gi
    ><n-gi class="text-center"
      ><n-switch
        :loading="isUpdating"
        @update:value="handleChange"
        v-model:value="user.notificationSettings.channels.mail" /></n-gi
    ><n-gi class="text-left pl-2">{{
      t("home.notifications.tabs.settings.options.sms")
    }}</n-gi
    ><n-gi class="text-center"
      ><n-switch
        :loading="isUpdating"
        @update:value="handleChange"
        v-model:value="user.notificationSettings.channels.sms" /></n-gi
  ></n-grid>
</template>

<style lang="scss" scoped>
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
</style>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { NGrid, NGi, NSwitch, NDivider, useMessage } from "naive-ui";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Settings",
  components: { NGrid, NGi, NSwitch, NDivider },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const userRef = ref(null);
    const store = useStore();
    const message = useMessage();
    const isUpdatingRef = ref(false);

    onMounted(() => {
      userRef.value = store.state.user.userFromDB;
      if (!userRef.value.notificationSettings) {
        userRef.value.notificationSettings = {
          channels: { web: true, mobile: true, mail: true, sms: true },
        };
      }
    });
    return {
      t,
      user: userRef,
      isUpdating: isUpdatingRef,
      handleChange: async () => {
        await store.dispatch("app/lockUI");
        isUpdatingRef.value = true;
        store
          .dispatch("user/updateNotificationSettings", userRef.value.notificationSettings)
          .then(() =>
            message.success(t("home.notifications.tabs.settings.sucessMessage"))
          )
          .catch((error) => {
            console.error(error);
            message.error(t("home.notifications.tabs.settings.errorMessage"));
          })
          .finally(() => {
            isUpdatingRef.value = false;
            store.dispatch("app/unlockUI");
          });
      },
    };
  },
});
</script>
