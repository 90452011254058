import { API } from "aws-amplify";

const apiName = "profile";
const pathVerficationToken = "/create-verification-session";

export const verifyId = {
  namespaced: true,
  state: () => ({
    userFromDB: null,
    tmpCurrentLocation: null,
  }),
  actions: {
    async getSession(_context, userId) {
      const response = await API.post(apiName, pathVerficationToken, {
        body: { userId: userId },
      });
      console.log(response);
      return response.session;
    },
  },
};
