export default {
  "signIn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "form": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar correo electrónico"])}
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar clave"])}
      },
      "firstName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar nombre"])}
      },
      "lastName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar apellido"])}
      },
      "newPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar contraseña nueva"])}
      },
      "repeatPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])}
      },
      "phone": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar número de teléfono"])}
      },
      "code": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserta el código recibido"])}
      },
      "validations": {
        "passwordNotSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clave no coincide, por favor verifique."])}
      }
    },
    "links": {
      "forgotPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ha olvidado su clave?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar clave"])}
      },
      "createAccount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Nuevo usuario?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])}
      },
      "haveAccount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya está registrado?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])}
      },
      "lostCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No recibiste el código?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar código"])}
      }
    },
    "messages": {
      "errorAuthenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha ocurrido un problema durante la autenticación, intente de nuevo"])},
      "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código enviado de manera exitosa"])},
      "codeSentForNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código enviado, por favor revise su email y acceda con el nuevo codigo y contraseña."])}
    }
  },
  "home": {
    "header": {
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vamos a encontrar la herramienta correcta"])},
        "validations": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una palabra o frase"])}
        }
      },
      "publishButton": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listar"])}
      },
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorías"])},
        "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis Publicaciones"])},
        "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros"])},
        "rents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramientas alquiladas"])},
        "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas"])}
      },
      "categoryFilter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las categorías"])}
      },
      "locationFilter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])}
      }
    },
    "notifications": {
      "tabs": {
        "unread": {
          "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay notificaciones por revisar"])}
        },
        "settings": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo desea obtener las notificaciones?"])},
          "options": {
            "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicación móvil"])},
            "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
            "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
          },
          "sucessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración actualizada"])},
          "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha presentado un error durante la actualización"])}
        }
      }
    },
    "messages": {
      "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página no encontrada"])}
    }
  },
  "searchResult": {
    "sideBar": {
      "item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["No hay publicaciones"]), _normalize([_interpolate(_named("count")), " publicación"]), _normalize([_interpolate(_named("count")), " publicaciones"])])}
    },
    "item": {
      "deliveryTypeLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tipo de entrega: ", _interpolate(_named("deliveryType"))])},
      "specialOffersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas especiales disponibles"])}
    },
    "deliveryTypes": {
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilio"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])}
    }
  },
  "profile": {
    "contactInformation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de contacto"])},
      "form": {
        "firstName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar nombre"])}
        },
        "lastName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar apellido"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo Electrónico"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar correo electrónico"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar teléfono"])}
        }
      }
    },
    "addresses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])}
    },
    "password": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "form": {
        "currentPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar contraseña actual"])}
        },
        "newPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva Contraseña"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar contraseña nueva"])}
        },
        "repeatPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir Contraseña"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar contraseña nueva"])}
        }
      },
      "buttonChange": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])}
      }
    },
    "payoutMethodsInformation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de pago"])},
      "form": {
        "address": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "notificationWhenNoAddressRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay un dirección registrada en su perfil"])},
          "buttonTextWhenNoAddressRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al perfil para añadir una dirección"])}
        },
        "paymentMethods": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de pago"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el método de pago"])},
            "options": {
              "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia bancaria"])},
              "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal"])}
            }
          },
          "routingNumber": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de ruta"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el número de ruta"])}
          },
          "accountNumber": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de cuenta"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el número de cuenta"])}
          },
          "accountHolder": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del propietario de la cuenta"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el nombre del propietario de la cuenta"])}
          }
        }
      }
    },
    "disclaimer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
      "goToProfileButton": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a perfil"])}
      },
      "dialogs": {
        "disclaimerRejected": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones rechazadas"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamentamos no haber llegado a un acuerdo y pedimos disculpas por las molestias causadas. En este momento será sacado de la plataforma. Gracias"])}
        }
      }
    },
    "verification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación del perfil"])},
      "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El perfil ha sido verificado satisfactoriamente"])},
      "unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debes verificar tu cuenta para utilizar algunas de las funciones"])},
      "verifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificando"])},
      "result": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu perfil ha sido verificado de manera exitosa"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu perfil no pudo ser verificado, intenta nuevamente"])}
      }
    }
  },
  "categories": {
    "createUpdate": {
      "form": {
        "parent": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría principal"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione una categoría principal"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre"])}
        },
        "showInHome": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en inicio"])}
        },
        "referentialAmounts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad referencial para alquileres"])},
          "pricePerDay": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio referencial por día"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100.000"])}
          },
          "deposit": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito referencial"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100.000"])}
          }
        },
        "baseChecklist": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación para entrega/devolución"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un tipo"])}
          },
          "name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre"])}
          }
        }
      }
    }
  },
  "publications": {
    "actions": {
      "changeState": {
        "publishAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar todo"])},
        "pauseAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausar todo"])},
        "deactivateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar todo"])}
      }
    },
    "itemActions": {
      "labels": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendario"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas"])}
      }
    },
    "dialogConfirmation": {
      "publishAll": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas activar todas las publicaciones?"])}
      },
      "pauseAll": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas pausar todas las publicaciones?"])}
      },
      "deactivateAll": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación (¡Esto no puede ser deshecho!)"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar todas las publicaciones?"])}
      },
      "deactivate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación (¡Esto no puede ser deshecho!)"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar todas las publicaciones?"])}
      }
    },
    "messages": {
      "publicationActivated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publicación ", _interpolate(_named("publicationTitle")), " fue activada"])},
      "publicationDeactivated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publicación ", _interpolate(_named("publicationTitle")), " fue desactivada"])},
      "publicatitionPaused": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publicación ", _interpolate(_named("publicationTitle")), " fue pausada"])},
      "noPublicationsChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las publicaciones no fueron cambiadas"])}
    },
    "createUpdate": {
      "warningOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta publicación se encuentra activa, cualquier cambio será actualizado instantáneamente"])},
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre la herramienta"])},
          "form": {
            "title": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un título para la publicación"])}
            },
            "category": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])}
            },
            "description": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una descripción"])}
            }
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade algunas fotos de tus herramientas"])},
          "form": {
            "files": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])}
            }
          }
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acerca de la ubicación y opciones de entrega"])},
          "form": {
            "offerDelivery": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ofrece entrega a domicilio?"])}
            },
            "location": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Dónde está la herramienta?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comience a escribir para ver las opciones"])}
            },
            "postalCode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código postal"])}
            },
            "availabilityRatio": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio de disponibilidad"])}
            }
          }
        },
        "four": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios"])},
          "form": {
            "rent": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler"])},
              "price": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
                "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por día"])}
              },
              "offerDiscount": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])}
              },
              "offerCancelationFree": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ofrecer cancelación gratis?"])}
              }
            },
            "discounts": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuentos"])},
              "weekly": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuentos por más de 1 semana"])}
              },
              "monthly": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuentos por más de 1 mes"])}
              }
            },
            "cancelation": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación"])},
              "daysBeforeRent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de días para la cancelación gratuita"])}
              }
            },
            "tool": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta"])},
              "depositAmount": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito requerido"])}
              }
            },
            "helpSection": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito"])},
              "titleWithFeeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito y tarifa de servicio"])},
              "lines": {
                "depositMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad de depósito será retenido y será usado como seguro por si algo ocurre durante el proceso de alquiler"])},
                "feeMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["RTR Cargará la comisión de ", _interpolate(_named("fee")), " del total recibido por el alquiler"])}
              }
            }
          }
        },
        "five": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación Entrega/Devolución"])}
        },
        "six": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa y publicar"])}
        }
      }
    },
    "dialogs": {
      "publicationReady": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu herramienta ha sido publicada"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu herramienta ", _interpolate(_named("publicationTitle")), " fue publicada exitosamente y está disponible"])}
      }
    },
    "faqs": {
      "questions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntar al propietario"])},
        "form": {
          "question": {
            "placeholderForSignedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu pregunta aquí..."])},
            "placeholderForUnsignedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingresa primero con tu usuario y contraseña..."])}
          }
        },
        "notifications": {
          "newQuestionReceived": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tienes una nueva pregunta en tu publicación ", _interpolate(_named("publicationTitle"))])}
          }
        }
      },
      "answers": {
        "filters": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta enviada"])}
        },
        "notifications": {
          "answerReceived": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tienes una nueva respuesta en tu publicación ", _interpolate(_named("publicationTitle"))])}
          }
        }
      },
      "timeline": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas preguntas realizadas"])},
        "question": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta"])}
        },
        "answer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escribe tu respuesta aquí..."])}
        },
        "dialogs": {
          "deleteConfirmation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación (¡Esto no puede ser deshecho!)"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas eliminar esa pregunta?"])}
          }
        },
        "messages": {
          "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta eliminada"])}
        }
      },
      "checkContentProcess": {
        "dialogs": {
          "content": {
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor inténtalo otra vez"])},
            "personalInformationDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sugerimos no ingresar información personal en esta sección"])},
            "badSentimentsDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor inténtalo otra vez, pero de manera más amigable"])}
          },
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
          }
        },
        "messages": {
          "contents": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al enviarse el mensaje por favor intenta otra vez"])}
          }
        }
      }
    },
    "resume": {
      "labels": {
        "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reseña"]), _normalize(["Reseñas"])])},
        "deliveryTypes": {
          "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe ser retirado"])},
          "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofrece entrega a domicilio"])}
        },
        "specialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas especiales"])},
        "discounts": {
          "perWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por más de 1 semana"])},
          "perMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por más de 1 mes"])}
        }
      }
    },
    "footerSection": {
      "mobileView": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la publicación"])}
      }
    },
    "states": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activado"])},
      "PAUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausado"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
      "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrador"])}
    }
  },
  "balance": {
    "labels": {
      "currentBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Balance actual: ", _interpolate(_named("currentBalance"))])}
    },
    "actions": {
      "labels": {
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar retiro"])}
      }
    },
    "itemActions": {
      "labels": {
        "rentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del alquiler"])}
      }
    },
    "dialogs": {
      "withdrawalConfirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de retiro"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu balance actual es ", _interpolate(_named("currentBalance")), ", ¿Deseas realizar un retiro?"])}
      },
      "completeYourPayoutProfile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil de pago"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor completa tu datos de pago para proceder"])},
        "positiveButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a registrar cuenta bancaria"])}
        }
      }
    },
    "messages": {
      "withdrawalRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro registrado"])},
      "errorRegisteringWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentamos algunos errores procesando su retiro, intente de nuevo. "])},
      "payoutProfileRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil de pago registrado"])},
      "errorRegisteringPayoutProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentamos algunos problemas registrando su perfil de pago, intente nuevamente"])}
    },
    "notifications": {
      "withdrawalRegistered": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro registrado"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Su retiro de ", _interpolate(_named("amount")), " se está en procesando, le dejaremos saber cuándo esté culminado el proceso. Gracias por usar RTR."])}
      }
    },
    "status": {
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
      "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
      "PROCESSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesado"])},
      "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallido"])},
      "RETURNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reversado"])}
    },
    "types": {
      "INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso"])},
      "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])}
    }
  },
  "parameters": {
    "createUpdate": {
      "form": {
        "type": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tipo"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre"])}
        },
        "value": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un valor"])}
        }
      }
    }
  },
  "rent": {
    "createUpdate": {
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas y entrega"])}
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])}
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Culminado con éxito!"])}
        },
        "dialogs": {
          "changesRegistered": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios registrados"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios en su alquiler han sido realizados con éxito, contacte al propietario para concretar los detalles de la entrega"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por utilizar nuestro servicio!"])}
          },
          "paymentDone": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago realizado"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su alquiler ha sido pagada con éxito, por favor contacte al propietario para coordinar la entrega"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por utilizar nuestro servicio!"])}
          },
          "maxModificationsReached": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su límite máximo de modificación de fechas fue alcanzado"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su alquiler alcanzó el límite máximo de cambios, considere cancelar esta orden y crear una nueva"])}
          },
          "checkDatesOnlyCanAddDays": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor. revisa las fechas"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta orden está en proceso, solo puede añadir días"])}
          },
          "checkDatesPaymentsInProcess": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revisa las fechas"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos procesando su pago, solo puede añadir días en este momento, por favor seleccione nuevamente el rango de fechas para su alquiler"])}
          },
          "checkDatesNoCancelationFree": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revisa las fechas"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler no contiene cancelación gratis, solo puede añadir los días, puede cancelarlo y crear otro nuevamente"])}
          },
          "checkDatesCanChangeDays": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revisa las fechas"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En este momento solo puedes cambiar las fechas preservando la cantidad de días"])}
          },
          "paymentError": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error durante el pago"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentamos algunos inconvenientes en el pago, favor intentarlo nuevamente"])}
          }
        },
        "messages": {
          "wrongUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El alquiler solo puede ser modificado por el arrendatario"])},
          "returnDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler tiene la devolución retrasada y debe ser terminada por el propietario"])},
          "rentCantBeModified": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este alquiler está en estado ", _interpolate(_named("status")), " y no puede ser modificado"])}
        },
        "notifications": {
          "rentUpdated": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler actualizado"])},
            "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Algunos cambios fueron registrados en el alquiler de la herramienta ", _interpolate(_named("publicationTitle")), "."])}
          }
        }
      }
    },
    "tabs": {
      "renter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como arrendatario"])}
      },
      "owner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como propietario"])}
      }
    },
    "itemActions": {
      "labels": {
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones"])},
        "checkDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregas"])},
        "checkReturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devoluciones"])}
      }
    },
    "detail": {
      "actions": {
        "labels": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "createIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reportar un problema"])},
          "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte tu opinión"])}
        }
      },
      "header": {
        "labels": {
          "rentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del alquiler"])},
          "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservado"])},
          "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutado"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])}
        }
      },
      "ownerInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del propietario"])},
        "joined": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se unió"])}
        }
      },
      "renterPaymentDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago del arrendador"])},
        "labels": {
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de la plataforma"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      },
      "deposit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del depósito"])},
        "labels": {
          "depositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de depósito"])}
        }
      },
      "ownerPayoutDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago al propietario"])},
        "labels": {
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de la plataforma"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total pago"])}
        }
      },
      "prices": {
        "labels": {
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "extraDay": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Día extra"]), _normalize(["Días extras"])])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de la plataforma"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "cancelationFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consto por cancelación"])},
          "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
          "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto Devolución"])},
          "amountToRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto a devolver"])},
          "pendingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance pendiente"])},
          "refundableDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito reembolsable"])},
          "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
          "retainedForDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retención por daños"])},
          "ownerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago al propietario"])},
          "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pago"])},
          "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])},
          "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverso"])}
        }
      }
    },
    "review": {
      "steps": {
        "zero": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo estuvo herramienta?"])},
          "form": {
            "content": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinión"])}
            }
          }
        },
        "one": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Cómo lo hizo ", _interpolate(_named("person")), "?"])},
          "form": {
            "content": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinión"])}
            }
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Hecho!"])},
          "confirmationDialog": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opinión registrada"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Gracias por compartir tu experiencia!"])}
          }
        }
      },
      "dialogs": {
        "rentMustBeFinished": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El alquiler debe estar terminado"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El alquiler debe haber culminado para escribir una opinión"])},
          "positiveButton:": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir al detalle del alquiler"])}
          }
        }
      }
    },
    "rangeOfDates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rango de fechas del Alquiler"])}
    },
    "resume": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reanudar"])},
      "labels": {
        "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta"])},
        "reservedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas reservadas"])},
        "reservedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días reservados"])},
        "executedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechas de ejecución"])},
        "executedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días de ejecutados"])},
        "extraDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días extras"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])}
      }
    },
    "checklist": {
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos y videos"])},
          "returnRequiredQuestions": {
            "needChargeDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesitas cobrar algún daños?"])},
            "detailsAboutDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles y costos"])}
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación"])}
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])}
        }
      },
      "dialogs": {
        "paymentIssues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallas en el proceso de pago"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler ha presentado fallas durante el proceso de pago, por favor contacta a nuestro equipo de soporte técnico"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir atrás"])}
          }
        },
        "tooEarly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy pronto..."])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este alquiler se agendó para ", _interpolate(_named("date")), ". Por favor espere hasta ese día"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir atrás"])}
          }
        },
        "tooLate": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muy tarde..."])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler está fuera del rango de fecha y debe ser finalizada. No te preocupes, el dinero de este alquiler aún es tuyo"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar este alquiler"])}
          }
        },
        "otherRentInProcess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay otro alquiler en proceso"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta herramienta tiene otro alquiler en proceso, debe finalizarlo antes de poder agendar el siguiente alquiler"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir atrás"])}
          }
        },
        "returnDelayed": {
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El retorno está ", _interpolate(_named("qtyDays")), " ", _interpolate(_named("daysLabel")), " retrasado, ¿Deseas cobrar los días extras a esta arrendatario?"])}
        },
        "startFinishConfirmation": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿", _interpolate(_named("action")), " Alquiler?"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esta acción ", _interpolate(_named("action")), " el alquiler en la plataforma y no puede ser reversada"])},
          "extraContentForRentThatJustStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler acaba de comenzar, si deseas eliminarlo ahora, el monto será devuelto al arrendatario y se te cobrará la comisión de la plataforma."])},
          "extraContentForRentWithRemainingDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Quedan ", _interpolate(_named("qtyDays")), " ", _interpolate(_named("daysLabel")), " para este alquiler"])},
          "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas continuar?"])}
        },
        "rentInWrongState": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alquiler en estado ", _interpolate(_named("state"))])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este alquiler se encuentra ", _interpolate(_named("state")), " y no puede ser modificado en este momento"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles"])}
          }
        }
      },
      "addNewField": {
        "form": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo campo"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
          },
          "name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un nombre"])}
          }
        }
      },
      "uploadFile": {
        "dialogs": {
          "fileTypeNotAllowed": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de campos soportados"])}
          }
        },
        "messages": {
          "errorDeletingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error eliminando el documento, por favor intente ora vez"])}
        }
      },
      "resume": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Resumen lista de verificación de ", _interpolate(_named("checklistType"))])}
      },
      "notifications": {
        "paymentReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago reversado"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El pago por la alquiler de la herramienta ", _interpolate(_named("publicationTitle")), " ha sido revertido, nos disculpamos por el inconveniente causado."])}
        },
        "paymentRequired": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago  requerido"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hay ", _interpolate(_named("qytDays")), " ", _interpolate(_named("daysLabel")), " pendientes por pagar debido a la entrega retrasada de la herramienta ", _interpolate(_named("publicationTitle")), ". Por favor presione el siguiente link para realizar el pago. Si este es un error, por favor comuníquese con el equipo técnico a través de ese mismo link por favor"])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar aquí"])}
        },
        "requestReview": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte tu experiencia con otros"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ahora que el alquiler de la herramienta ", _interpolate(_named("publicationTitle")), " ha terminado, por favor comparte con otros tu experiencia"])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click aquí para escribir una opinión"])}
        },
        "rentFinished": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu alquiler ha terminado"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu alquiler de la herramienta ", _interpolate(_named("publicationTitle")), " ha terminado, gracias por usar RTR"])}
        },
        "rentStartedFinished": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu alquiler por ", _interpolate(_named("publicationTitle")), " ha sido ", _interpolate(_named("action"))])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tu alquiler por ", _interpolate(_named("publicationTitle")), " ha sido ", _interpolate(_named("action"))])}
        },
        "depositIssue": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falla con el depósito"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuvimos problemas al procesar el depósito. Por favor ingresa otro método de pago para continuar con la transacción."])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar pago de depósito aquí"])}
        }
      },
      "messages": {
        "renStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler iniciado"])},
        "rentFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquiler terminado"])},
        "rentFinishedWithPaymentRequested": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("rent.checklist.messagges.rentFinished", undefined, _type), " y pago requerido"])},
        "errorUpdatingStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Presentamos algunos problemas, el alquiler se encuentra ", _interpolate(_named("status"))])},
        "issueCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema creado para gestionar los costos por daños"])},
        "errorCreatingIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error. Por favor comuniquese con el equipo técnico para solucionar el depósito por daños"])},
        "errorCapturingPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error procesando el pago, la alquiler se encuentra ", _interpolate(_named("status"))])},
        "errorHoldingDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al retener el depósito"])}
      },
      "others": {
        "issueSubjectWhenDamagesNoticed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolución con daños"])}
      }
    },
    "cancelation": {
      "notificationOfCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este alquiler no tiene cancelación gratis, si cancelas ahora, no se proporcionará un reembolso."])},
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del reembolso"])},
        "labels": {
          "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagado"])},
          "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa por cancelación"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impuesto"])},
          "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de reembolso"])}
        }
      },
      "confirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas continuar?"])},
      "dialogs": {
        "cancelationConfirmation": {
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El alquiler de la herramienta ", _interpolate(_named("publicationTitle")), " fue cancelado."])}
        }
      },
      "messages": {
        "errorCanceling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, por favor contacte al equipo técnico"])}
      }
    },
    "deliveryInformation": {
      "warnings": {
        "deliveryOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El lugar de entrega seleccionado está fuera de alcance"])}
      },
      "labels": {
        "pickAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Recogerlo en ", _interpolate(_named("location"))])}
      }
    },
    "states": {
      "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pagado"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
      "IN_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
      "RETURNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devuelto"])},
      "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminado"])},
      "ARBITRATION_IN_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitraje en proceso"])},
      "LOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdido"])},
      "PENDING_FOR_PAY_EXTRA_DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de pago por días extras"])},
      "PENDING_FOR_CHANGE_PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente de cambio de método de pago"])},
      "DEPOSIT_PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito pendiente"])},
      "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado"])}
    }
  },
  "issues": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas"])},
    "buttonTakeIssue": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomar problema"])}
    },
    "detail": {
      "tabs": {
        "issue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Problema en el alquiler: ", _interpolate(_named("publicationTitle"))])},
          "information": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
            "labels": {
              "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
              "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subjeto"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
              "previousRentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado previo del alquiler"])},
              "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado en"])}
            }
          },
          "usersInformation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
            "labels": {
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
              "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
              "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])}
            }
          },
          "evidences": {
            "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Adjunto"]), _normalize(["Adjuntos"])])},
            "from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subido en: ", _interpolate(_named("date"))])},
            "by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Por ", _interpolate(_named("user"))])},
            "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
            "noEvidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay archivos adjuntos"])}
          },
          "transactions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones"])},
            "labels": {
              "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
              "charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargo"])},
              "registeredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrado en"])},
              "reverseTypes": {
                "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverso"])},
                "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])}
              }
            }
          },
          "resolution": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolución"])},
            "labels": {
              "messageToUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje para los usuarios"])},
              "relatedTransactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relacionado con el id de las transacciones"])}
            },
            "messages": {
              "noResolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay resolución"])}
            }
          }
        },
        "rent": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alquiler"])}
        },
        "actions": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
          "form": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describir un breve resumen del caso y su resolución (solo para uso interno)"])},
              "validation": {
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, describe un breve resumen del caso y su resolución"])}
              }
            },
            "message": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese mensaje en el marcador"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ayuda relacionada con su alquiler ha sido terminada"])},
              "validation": {
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un mensaje de notificación para los clientes"])}
              }
            }
          },
          "buttonResolveIssue": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolver problema"])}
          },
          "cancelRent": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar el alquiler"])},
            "form": {
              "confirmationReverseTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres reversar la transacción asociada a este alquiler?"])},
              "confirmationReverseDeposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres reversar el depósito asociado a esta alquiler?"])}
            }
          },
          "changeDates": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de fechas"])},
            "notifications": {
              "depositIssue": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problemas con el depósito"])},
                "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentamos algunos problemas procesando su alquiler, por favor añade un nuevo método de pago"])},
                "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar medio de pago aquí"])}
              }
            },
            "dialogs": {
              "noTransactionsFound": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No encontramos transacciones disponibles"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay transacciones disponibles para el proceso de reembolso"])}
              }
            }
          },
          "suspectedStolenTool": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta sospechosa de robo"])},
            "form": {
              "confirmationProcessDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea procesar la transacción de depósito asociada a este alquiler? (Monto del depósito: ", _interpolate(_named("depositAmount")), ")"])}
            }
          },
          "disagreementClosing": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desacuerdo en el cierre"])},
            "form": {
              "confirmationProcessDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea procesar la transacción de depósito asociada a este alquiler? (Monto del depósito: ", _interpolate(_named("depositAmount")), ")"])},
              "confirmationReverseDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea revertir la transacción de depósito asociada a este alquiler? (Monto del depósito: ", _interpolate(_named("depositAmount")), ")"])},
              "confirmationProcessPartialDepositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea procesar una cantidad parcial de la transacción de depósito asociada a este alquiler?"])},
              "confirmationRefundPartialDepositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Desea reembolsar una cantidad parcial de la transacción de depósito asociada a este alquiler?"])},
              "amountToRefund": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto a reembolsar"])}
              }
            },
            "messages": {
              "noDepositPayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El arrendatario no pagó el depósito"])},
              "refundQueued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transacción de reembolso se ha enviado a la cola"])}
            }
          },
          "exceptionPaymentAdditionalDays": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de excepción por días adicionales"])},
            "form": {
              "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres omitir el pago por días adicionales?"])}
            }
          },
          "disagreementOnReturnChecklist": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desacuerdo en la lista de verificación de devolución"])}
          },
          "messages": {
            "noActionsInElThisStates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Este caso de resolución es solo para alquileres en estos estados: ", _interpolate(_named("states"))])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El problema fue resuelto con éxito"])}
          }
        }
      }
    },
    "create": {
      "form": {
        "subject": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el asunto"])}
        },
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese descripción"])}
        }
      },
      "dialogs": {
        "wantToAddEvidences": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Deseas adjuntar fotos (Fotos o videos)?"])}
        },
        "issueCreated": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema enviado"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El problema fue ingresado, por favor espere a respuesta por parte de nuestro equipo"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay archivos adjuntos a este problema"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirigir al alquiler"])}
          }
        }
      }
    }
  },
  "locationDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
    "form": {
      "address": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comience a escribir para ver las opciones"])}
      },
      "postalCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese código postal"])}
      },
      "availabilityRatio": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Disponibilidad de rango: ", _interpolate(_named("ratio")), " ", _interpolate(_named("unit"))])}
      }
    }
  },
  "aboutUser": {
    "labels": {
      "joinedIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se unió en ", _interpolate(_named("date"))])},
      "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Opinión"]), _normalize(["Opiniones"])])}
    }
  },
  "statsAndCalendar": {
    "labels": {
      "rentedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días alquilados"])},
      "reservedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días reservados"])},
      "maintenanceDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días de mantenimiento"])},
      "delayedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Días de retraso"])}
    },
    "dialogs": {
      "confirmMaintenanceDone": {
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea marcar como realizado el mantenimiento programado desde ", _interpolate(_named("from")), " hasta ", _interpolate(_named("to")), "?"])}
      },
      "confirmCancelMaintenance": {
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Desea cancelar el mantenimiento programado desde ", _interpolate(_named("from")), " hasta ", _interpolate(_named("to")), "?"])}
      }
    },
    "form": {
      "placeholders": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un rango de fechas"])}
      }
    }
  },
  "chat": {
    "errors": {
      "connectionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesión cerrada, por favor ingrese otra vez"])}
    },
    "notifications": {
      "newMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nuevo mensaje de ", _interpolate(_named("user"))])},
      "fileReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo recibido"])}
    }
  },
  "commons": {
    "labels": {
      "information": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Información"]), _normalize(["Informaciones"])])},
      "state": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Estado"]), _normalize(["Estados"])])},
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
      "category": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Categoría"]), _normalize(["Categorías"])])},
      "field": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Campo"]), _normalize(["Campos"])])},
      "address": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Dirección"]), _normalize(["Direcciones"])])},
      "photo": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Foto"]), _normalize(["Fotos"])])},
      "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Nuevo ", _interpolate(_named("reference"))]), _normalize(["Nueva ", _interpolate(_named("reference"))])])},
      "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Tipo"]), _normalize(["Tipos"])])},
      "name": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Nombre"]), _normalize(["Nombres"])])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis"])},
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrega"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolución"])},
      "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Verificación"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actual"])},
      "notification": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Notificación"]), _normalize(["Notificaciones"])])},
      "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Reseña"]), _normalize(["Reseñas"])])},
      "payment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pago"]), _normalize(["Pagos"])])},
      "worldwide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
      "action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Acción"]), _normalize(["Acciones"])])},
      "filter": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Filtro"]), _normalize(["Filtros"])])},
      "file": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Archivo"]), _normalize(["Archivos"])])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado en"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizado en"])},
      "parameter": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Parámetro"]), _normalize(["Parámetros"])])},
      "publication": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Publicación"]), _normalize(["Publicaciones"])])},
      "profit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ganancia"]), _normalize(["Ganancias"])])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos"])},
      "pending": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Pendiente"]), _normalize(["Pendientes"])])},
      "maintenance": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Mantenimiento"]), _normalize(["Mantenimientos"])])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
      "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconveniente"])},
      "cancelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelación"])},
      "detail": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Detalle"]), _normalize(["Detalles"])])},
      "unread": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["No Leído"]), _normalize(["No Leídos"])])},
      "setting": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Configuración"]), _normalize(["Configuraciones"])])},
      "rent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Alquiler"]), _normalize(["Alquileres"])])},
      "code": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Código"]), _normalize(["Códigos"])])},
      "account": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cuenta"]), _normalize(["Cuentas"])])},
      "email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Correo"]), _normalize(["Correos"])])},
      "password": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Contraseña"]), _normalize(["Contraseñas"])])},
      "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Descripción"]), _normalize(["Descripciones"])])},
      "term": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Término"]), _normalize(["Términos"])])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacidad"])},
      "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sin Días"]), _normalize(["Dia"]), _normalize(["Días"])])},
      "date": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sin Fecha"]), _normalize(["Fecha"]), _normalize(["Fechas"])])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éxito"])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Usuario"]), _normalize(["Usuarios"])])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Término"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
      "value": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Valor"]), _normalize(["Valores"])])},
      "amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Monto"]), _normalize(["Montos"])])}
    },
    "form": {
      "validation": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo requerido"])},
        "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor invalido"])},
        "rangeOfDatesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, selecciona un rango de fechas"])},
        "maxSelectedDaysReached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La cantidad de días seleccionados no pueden ser mayor a ", _interpolate(_named("maxDays"))])},
        "fileTypeNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de archivo no permitido"])},
        "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ya existe"])},
        "valueMustBeThis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El valor debe ser ", _interpolate(_named("reference"))])},
        "valueOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor fuera de rango"])}
      }
    },
    "lists": {
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin datos"])},
      "noContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin contenido"])}
    },
    "actions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
      "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Añadir ", _interpolate(_named("reference"))])},
      "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Guardar ", _interpolate(_named("reference"))])},
      "search": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Buscar ", _interpolate(_named("reference"))])},
      "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aceptar ", _interpolate(_named("reference"))])},
      "cancel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancelar ", _interpolate(_named("reference"))])},
      "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crear ", _interpolate(_named("reference"))])},
      "update": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Actualizar ", _interpolate(_named("reference"))])},
      "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Eliminar ", _interpolate(_named("reference"))])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
      "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comenzar ", _interpolate(_named("reference"))])},
      "finish": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Terminar ", _interpolate(_named("reference"))])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzado"])},
      "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminado"])},
      "viewDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vista ", _interpolate(_named("reference")), " Detalles"])},
      "resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolver"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
      "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subir ", _interpolate(_named("reference"))])},
      "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
      "select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seleccionar ", _interpolate(_named("reference"))])},
      "send": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enviar ", _interpolate(_named("reference"))])},
      "publish": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publicar ", _interpolate(_named("reference"))])},
      "pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pagar ", _interpolate(_named("reference"))])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
      "confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Confirmado"])},
      "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Guardado"])},
      "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Creado"])},
      "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Actualizado"])},
      "change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cambio ", _interpolate(_named("reference"))])},
      "changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Cambiado"])},
      "check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Revisar ", _interpolate(_named("reference"))])},
      "registered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Registrado"])},
      "canceled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Cancelado"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denegado"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alquilar"])}
    },
    "confirmation": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " hecho"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    },
    "errors": {
      "creating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error creando ", _interpolate(_named("reference"))])},
      "saving": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error guardando ", _interpolate(_named("reference"))])},
      "updating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error actulizando ", _interpolate(_named("reference"))])},
      "bad": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mal ", _interpolate(_named("reference"))])}
    },
    "calendar": {
      "placeholders": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un rango de fechas"])}
      }
    },
    "persons": {
      "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrendatario"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario"])},
      "allMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
      "allFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])}
    },
    "financial": {
      "balance": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Saldo"]), _normalize(["Saldos"])])},
      "transaction": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Transacción"]), _normalize(["Transacciones"])])},
      "charge": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Cargo"]), _normalize(["Cargos"])])},
      "currencySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
    },
    "distances": {
      "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])}
    },
    "inputTypes": {
      "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secuencia"])},
      "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si/No"])},
      "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])}
    }
  }
}