/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a8d5c18b-5d68-4c4e-98de-2d40112e8af1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_G2KwAzjPO",
    "aws_user_pools_web_client_id": "7ov0i7ik3gvuk3ghcptg4p36j7",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://4xzaxlyylvhnhf5moluzk7ps2a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6vhjmeu7y5hhnknbdicmj5hqki",
    "aws_cloud_logic_custom": [
        {
            "name": "payments",
            "endpoint": "https://d7kc4e8nf9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "taxrates",
            "endpoint": "https://ccw78xl01d.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "notifications",
            "endpoint": "https://2k0svqw95i.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "iotroles",
            "endpoint": "https://nyp0gknj0k.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "questions",
            "endpoint": "https://5n4q8wa624.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "device",
            "endpoint": "https://u1a0hvhwj7.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "payouts",
            "endpoint": "https://4a1zvu7iaf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "payoutWebhook",
            "endpoint": "https://p0sxynhlp8.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "profile",
            "endpoint": "https://mw6m35mzr6.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "multimedia205429-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "predictions": {
        "identify": {
            "identifyLabels": {
                "proxy": false,
                "region": "us-east-1",
                "defaults": {
                    "type": "UNSAFE"
                }
            }
        }
    },
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "rtrMap-dev": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "rtrMap-dev"
            },
            "search_indices": {
                "items": [
                    "rtrSearchIndex-dev"
                ],
                "default": "rtrSearchIndex-dev"
            }
        }
    }
};


export default awsmobile;
