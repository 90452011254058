import { InputType, UnitTime } from '../../models/index'
import  i18n  from "@/i18n";

const { t } = i18n.global

function convertToOptions(enums) {
    return Object.entries(enums).map((x) => {
        return { label: x[0], value: x[1]}});
}


export const shared = {
    namespaced: true,
    state: () => ({
    }),
    getters: {
        options_inputType: () => {
            const options = convertToOptions(InputType);
            return options.map((item) => {
                return {
                  label: t(`commons.inputTypes.${item.value}`),
                  value: item.value,
                };
              });
        },
        options_unitTime: () => convertToOptions(UnitTime)
    }
}