
import { API, graphqlOperation } from "aws-amplify";
import { listParameters } from "../../graphql/queries";
export const app = {
    namespaced: true,
    state: () => ({
        showHeader: true,
        showFooter: true,
        showSearchBar: true,
        isBusy: false,
        configuration: {},
        isMobile: false,
        isiOS: false,
        isAndroid: false,
    }),
    getters: {
        showHeader: (state) => state.showHeader,
        showSearchBar: (state) => state.showSearchBar,
        isBusy: (state) => state.isBusy,
        isMobile: (state) => state.isMobile
    },
    mutations: {
        setShowHeader(state, newState) {
            state.showHeader = newState
        },
        setShowFooter(state, newState) {
            state.showFooter = newState
        },
        setShowSearchBar(state, newState) {
            state.showSearchBar = newState
        },
        setIsBusy(state, newState) {
            state.isBusy = newState
        },
        setConfigurations(state, parameters) {
            parameters.forEach((parameter) => {
                state.configuration[parameter.name] = parameter.type === 'BOOLEAN'?parameter.value === 'true':parameter.type === 'NUMBER'?Number(parameter.value):parameter.value
            })
            
        },
        setConfiguration(state, parameter) {
            state.configuration[parameter.name] = parameter.type === 'BOOLEAN'?parameter.value === 'true':parameter.type === 'NUMBER'?Number(parameter.value):parameter.value
        },
        setIsMobile(state, value){
            state.isMobile = value
        },
        setIsios(state, value){
            state.isiOS = value
        },
        setIsAndroid(state, value){
            state.isAndroid = value
        }
    },
    actions: {
        hideHeader (context) {
            context.commit('setShowHeader', false)
        },
        showHeader (context) {
            context.commit('setShowHeader', true)
        },
        hideFooter (context) {
            context.commit('setShowFooter', false)
        },
        showFooter (context) {
            context.commit('setShowFooter', true)
        },
        hideSearchBar(context) {
            context.commit('setShowSearchBar', false)
        },
        showSearchBar(context) {
            context.commit('setShowSearchBar', true)
        },
        lockUI(context) {
            context.commit('setIsBusy', true)
        },
        unlockUI(context) {
            context.commit('setIsBusy', false)
        },
        async loadConfiguration(context) {
            const parameters_raw = await API.graphql(graphqlOperation(listParameters));
            context.commit('setConfigurations', parameters_raw.data.listParameters.items)

        },
        async updateConfiguration(context, parameter) {
            context.commit('setConfiguration', parameter)

        },
        async updatePlatformIdentification({commit}, platform){

            switch (platform){
                case "ios":
                    commit('setIsios', true)
                    break;
                case "android":
                    commit('setIsAndroid', true)
                        break;
            }

        }
    }
}