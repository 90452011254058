<template>
  <n-thing content-indented>
    <template #avatar>
      <a @click="click"><n-avatar v-if="avatar" :size="80" :src="avatar" /></a>
    </template>
    <template #header>
      <a @click="click">
        <n-ellipsis :style="`max-width: ${windowsWidth * (isMobile?50:15)/100}px`">
          {{ notification.title }}
        </n-ellipsis>
      </a>
    </template>
    <template #header-extra>
      <n-button circle size="small" @click="remove">
        <template #icon>
          <close-icon />
        </template>
      </n-button>
    </template>
    <a @click="click">
      <n-ellipsis :style="`max-width: ${windowsWidth * (isMobile?80:20)/100}px`" line-clamp="2">
        {{ notification.message }}
      </n-ellipsis>
    </a>
  </n-thing>
  <n-divider />
</template>

<style lang="scss" scoped>
.n-thing {
  text-align: justify;
  padding: 0px 14px 0px 14px;
}
a {
  text-decoration: none;
  cursor: pointer;
}
</style>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { Close as CloseIcon } from "@vicons/ionicons5";
import { NThing, NAvatar, NButton, NDivider, NEllipsis } from "naive-ui";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Storage } from "aws-amplify";
import responsive from "@/mixins/responsive";

export default defineComponent({
  name: "Notification",
  components: {
    CloseIcon,
    NThing,
    NAvatar,
    NButton,
    NDivider,
    NEllipsis,
  },
  props: {
    notification: { type: Object, required: true },
  },
  mixins: [responsive],
  emits: ["onNavigate"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const avatar = ref(null);

    onMounted(() => {
      if (props.notification.avatar) {
        Storage.get(
          `publication/${props.notification.avatar.publicationID}/${props.notification.avatar.defaultImage}`,
          {
            level: "protected",
            identityId: props.notification.avatar.publicationUser,
          }
        ).then((image) => {
          avatar.value = image;
        });
      }
    });

    return {
      remove: () => {
        store.dispatch("notifications/MarkNotificationAsRead", props.notification);
      },
      click: () => {
        store.dispatch("notifications/MarkNotificationAsRead", props.notification);
        store
          .dispatch("notifications/ConvertToRoute", props.notification.targetRoute)
          .then((route) => {
            if (route) {
              router.push(route);
              emit("onNavigate");
            }
          });
      },
      avatar,
    };
  },
});
</script>
