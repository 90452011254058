import { API, graphqlOperation } from "aws-amplify";
import { parseStringToDate }  from "@/shared/utils";
import {  createUserBalanceTransaction  } from "../../../graphql/mutations";
import { listUserBalanceTransactions } from "../../../graphql/custom/queries";

export const maintainer_balance = {
    namespaced: true,
    state: () => ({
        balanceTransactions: []
    }),
    getters: {
    },
    mutations: {
        fillBalanceTransactions: (state, transactions) =>  state.balanceTransactions = transactions,
        addProfitTransactionToBalance: (state, transaction) =>  state.balanceTransactions.push(transaction),
    },
    actions: {
        async addProfitTransactionToBalance({commit}, {description, amount, user, rentId}){
            const input = {
                date: new Date(),
                description: description,
                type: amount > 0 ? "INCOME" : "WITHDRAWAL",
                status: amount > 0 ? "PROCESSED" : "PENDING",
                amount: amount,
                userId: user,
                rentId: rentId
            }

            const raw = await API.graphql({...graphqlOperation(createUserBalanceTransaction, {input: input})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});

                    

            commit("addProfitTransactionToBalance", raw.data.createUserBalanceTransaction)

            return raw.data.createUserBalanceTransaction
                                                        
        },
        async fillBalanceTransactions({commit}, user){
            const raw_data = await API.graphql({...graphqlOperation(listUserBalanceTransactions, { filter: { userId: { eq: user} }}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('fillBalanceTransactions', raw_data.data.listUserBalanceTransactions.items.map((item) => {return {...item, date: parseStringToDate(item.date,false)}}));
        }
    }
}