import { API, graphqlOperation, Storage } from "aws-amplify";
import { createPublication, updatePublication, updatePublicationResumed, deletePublication} from "../../../graphql/custom/mutations";
import { getPartialPublicationForRent, getPartialPublicationForView, getPublicationsRentsAndMaintenances, getPublicationToUpdate, listPartialPublications } from "../../../graphql/custom/queries";
import { PublicationState } from '../../../models/index'
import { parseStringToDate, getFileNameFromKey }  from "@/shared/utils";
import { checkFileMimeTypesFromBytes }  from "@/shared/file-checker";
import { Predictions } from '@aws-amplify/predictions';


function convertToOptions(enums) {
    return Object.entries(enums).map((x) => {
        return { label: x[0], value: x[1]}});
}

export const maintainer_publication = {
    namespaced: true,
    state: () => ({
        publications: []
    }),
    getters: {
        options_publicationState: () => convertToOptions(PublicationState)
    },
    mutations: {
        addPublication (state, newPublication) {
            state.publications.push(newPublication);
        },
        fillPublications(state, publications) {
            state.publications = publications
        }
    },
    actions: {
        async createPublication({ commit }, newPublication) {
            const publicationCreated = await API.graphql({...graphqlOperation(createPublication, {input: newPublication})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const data = publicationCreated.data.createPublication;
            commit('addPublication', data);
            return data;
        },
        // eslint-disable-next-line no-unused-vars
        async updatePublication({commit, state}, publication) {   
            const publicationUpdatedRaw = await API.graphql({...graphqlOperation(updatePublication, {input: publication})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});

            return publicationUpdatedRaw.data?.updatePublication
        },

        // eslint-disable-next-line no-unused-vars
        async updatePublicationState({commit, state}, publication) {   
            const publicationUpdatedRaw = await API.graphql({...graphqlOperation(updatePublicationResumed, {input: publication})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});

            const publicationUpdate = publicationUpdatedRaw.data?.updatePublication
            commit('fillPublications', state.publications.map((publication) => publication.id === publicationUpdate.id ? publicationUpdate : publication));
            return publicationUpdate
        },

        // eslint-disable-next-line no-unused-vars
        async updatePublicationMaintenances(context, {publicationId, maintenances, version}) {   
            const publicationUpdated = await API.graphql({...graphqlOperation(updatePublication, {input: {id: publicationId, maintenances: maintenances, _version: version}})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            return { 
                maintenances: publicationUpdated.data?.updatePublication.maintenances, 
                version: publicationUpdated.data?.updatePublication._version 
            };
        },

        async fillPublications({ commit }, userId) {
            const publications_raw = await API.graphql(graphqlOperation(listPartialPublications, { filter: { user: { eq: userId} }}));
            commit('fillPublications', publications_raw.data.listPublications.items);
        },
        async deletePublication(_context, publication) {
            const publicationFiltered = (
                ({ id, _version }) =>
                ({ id, _version }))(publication)

            await API.graphql(graphqlOperation(deletePublication, {input: publicationFiltered}));
        },
        async getPublicationByIdToUpdate({ dispatch }, publicationID) {
            const publication = await API.graphql(graphqlOperation(getPublicationToUpdate, {id: publicationID}));
            return dispatch('parseDates', publication.data.getPublication).then((response_publication) => {
                return response_publication;
            });
        },
        async getResumenPublicationById({ dispatch }, publicationID) {
            const publication = await API.graphql(graphqlOperation(getPartialPublicationForView, {id: publicationID}));
            return dispatch('parseDates', publication.data.getPublication).then((response_publication) => {
                return response_publication;
            });
        },
        async getPublicationByIdForView({ dispatch }, publicationID) {
            const publication = await API.graphql(graphqlOperation(getPartialPublicationForView, {id: publicationID}));
            return dispatch('parseDates', publication.data.getPublication).then((response_publication) => {
                return response_publication;
            });
        },
        async getPublicationByIdForRent({ dispatch }, publicationID) {
            const publication = await API.graphql(graphqlOperation(getPartialPublicationForRent, {id: publicationID}));
            return dispatch('parseDates', publication.data.getPublication).then((response_publication) => {
                return response_publication;
            });
        },
        async getPublicationsRentsAndMaintenancesByPublicationId({ dispatch }, publicationID) {
            const publication = await API.graphql(graphqlOperation(getPublicationsRentsAndMaintenances, {id: publicationID}));
            return dispatch('parseDates', publication.data.getPublication).then((response_publication) => {
                return response_publication;
            });
        },
        async getImagesByPublication(_context, {id, user, orderPhotos}) { 
            let result = await Storage.list(`publication/${id}/`, { level: 'protected', identityId: user });
            result = result.filter((file) => !file.key.endsWith('.deleted'))
            if (result?.length > 0) {
                
                if (orderPhotos?.length > 0) {   
                    result.sort((a, b) => orderPhotos.indexOf(getFileNameFromKey(a.key)) - orderPhotos.indexOf(getFileNameFromKey(b.key)));
                }
            }
            return result;
        },
        async getImagePublicationUrlByKey(_context, { key, ownerId }) {
            return Storage.get(key, { level: 'protected', identityId: ownerId })
        },
        async isFileAllowed(_context, {blob, file}) {
            console.log("blob", blob);
            console.log("file", file);
            const bytes = new Uint8Array(blob);
            // Primero chequeamos la extension
            if(!checkFileMimeTypesFromBytes(bytes, ['jpeg', 'png'])){
                return {
                    allowed: false,
                    message: 'File type not allowed'
                }
            }
            // Despues chequeamos el tamano del archivo
            if (file.file.size > 5000000){
                return {
                    allowed: false,
                    message: 'Max file size reached (5 Mbs)'
                }
            }
            // Por ultimo se chequea el contenido de la imagen
            try{
                const response = await Predictions.identify({
                    labels: {
                        source: {
                            file: file.file,
                        },
                        type: "UNSAFE"
                    }
                })

                console.log("response", response)
    
                if (response.unsafe === 'YES'){
                    return {
                        allowed: false,
                        message: 'We detected sensitive content, please try with another image'
                    }
                }
            } catch (error) {
                console.error('error', error);
                return {
                    allowed: false,
                    message: 'Error checking content image, please try again'
                }
            }
            return { allowed: true };
        },
        deletePublicationImages(_context, {deletedFiles, user}){
            if (deletedFiles.length > 0) {
                deletedFiles.forEach(async (file) => {
                  await Storage.remove(file.id, {
                    level: "protected",
                    identityId: user,
                  });
                });
              }
        },
        parseDates(_context, publication){
            publication.createdAt = parseStringToDate(publication.createdAt);
            publication.updatedAt = parseStringToDate(publication.updatedAt);
            if (publication.rents){
                publication.rents.items = publication.rents?.items
                ? publication.rents.items.map((rent) => {
                    rent.requestedRangeOfDates.start = parseStringToDate(
                    rent.requestedRangeOfDates.start
                    );
                    rent.requestedRangeOfDates.end = parseStringToDate(
                    rent.requestedRangeOfDates.end
                    );
                    if (rent.executedRangeOfDates) {
                    rent.executedRangeOfDates.start = parseStringToDate(
                        rent.executedRangeOfDates.start
                    );
                    rent.executedRangeOfDates.end = rent.executedRangeOfDates.end
                        ? parseStringToDate(rent.executedRangeOfDates.end)
                        : null;
                    }
                    return rent;
                })
                : [];
            }
            
        
            publication.maintenances = publication.maintenances
                ? publication.maintenances.map((maintenance) => {
                    maintenance.rangeOfDates.start = parseStringToDate(
                    maintenance.rangeOfDates.start
                    );
                    maintenance.rangeOfDates.end = parseStringToDate(
                    maintenance.rangeOfDates.end
                    );
                    return maintenance;
                })
                : [];
            return publication;

        }
    }
}