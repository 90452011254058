export const updateUserDeep = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      familyName
      phoneNumber
      acceptDoR
      verified
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
          coordinates {
            lat
            long
          }
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
          mobileDevices {
            id
            endpoint
            enable
          }
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserInformation = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      _version
    }
  }
`;
export const createPartialUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateChatRoomInToolRent = /* GraphQL */ `
  mutation UpdateToolRent(
    $input: UpdateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    updateToolRent(input: $input, condition: $condition) {
      id
      user
      relatedUsers
      chatRoomId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateChecklistInToolRent = /* GraphQL */ `
  mutation UpdateToolRent(
    $input: UpdateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    updateToolRent(input: $input, condition: $condition) {
      id
      user
      relatedUsers
      state
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      executedRangeOfDates {
        start
        end
      }
      executedDays
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updateChecklistAndStatusInToolRent = /* GraphQL */ `
  mutation UpdateToolRent(
    $input: UpdateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    updateToolRent(input: $input, condition: $condition) {
      id
      user
      relatedUsers
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const updatePublicationResumed = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      user
      title
      category {
        name
      }
      state
      createdAt
      _version
      _deleted
    }
  }
`;

export const updatePublicationsMaintenances = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      user
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const deletePublication = /* GraphQL */ `
  mutation DeletePublication(
    $input: DeletePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    deletePublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createToolRent = /* GraphQL */ `
  mutation CreateToolRent(
    $input: CreateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    createToolRent(input: $input, condition: $condition) {
      id
      state
      publicationID
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      limitDaysForCancelationFree
      paymentsInfo {
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        type
        amount
        taxAmount
        balance
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateToolRent = /* GraphQL */ `
  mutation UpdateToolRent(
    $input: UpdateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    updateToolRent(input: $input, condition: $condition) {
      id
      state
      publicationID
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      limitDaysForCancelationFree
      paymentsInfo {
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        type
        amount
        taxAmount
        balance
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNoReadRentIssue = /* GraphQL */ `
  mutation UpdateRentIssue(
    $input: UpdateRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    updateRentIssue(input: $input, condition: $condition) {
      id
    }
  }
`;
export const resolveRentIssue = /* GraphQL */ `
  mutation resolveRentIssue(
    $inputIssue: UpdateRentIssueInput!
    $inputToolRent: UpdateToolRentInput!
    $conditionIssue: ModelRentIssueConditionInput
    $conditionToolRent: ModelToolRentConditionInput
  ) {
    updateRentIssue(input: $inputIssue, condition: $conditionIssue) {
      id
      subject
      description
      datetime
      state
      snapshotRentState
      evidences {
        id
        subject
        datetime
        owner
        description
        keys
      }
      rentId
      rent {
        id
        user
        _version
        publication {
          id
          title
          user
          orderPhotos
        }
        deliveryCheckList {
          name
          value
          type
        }
        depositTransaction {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          bin
          lastFour
          cardType
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }

        deposit
        paymentsInfo {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        relatedTransactionsId
        type
        rentState
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    updateToolRent(input: $inputToolRent, condition: $conditionToolRent) {
      id
    }
  }
`;
export const updateAssistantRentIssue = /* GraphQL */ `
  mutation UpdateRentIssue(
    $input: UpdateRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    updateRentIssue(input: $input, condition: $condition) {
      id
      subject
      description
      datetime
      state
      snapshotRentState
      evidences {
        id
        subject
        datetime
        owner
        description
        keys
      }
      rentId
      rent {
        id
        user
        _version
        publication {
          id
          title
          user
          orderPhotos
        }
        deliveryCheckList {
          name
          value
          type
        }
        depositTransaction {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          bin
          lastFour
          cardType
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
        deposit
        paymentsInfo {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        relatedTransactionsId
        type
        rentState
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPartialRentIssue = /* GraphQL */ `
  mutation CreateRentIssue(
    $input: CreateRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    createRentIssue(input: $input, condition: $condition) {
      id
      subject
      description
      datetime
      state
      snapshotRentState
      evidences {
        id
        subject
        datetime
        owner
        ownerId
        description
        keys
      }
      rentId
      rent {
        id
        _version
        depositTransaction {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          bin
          lastFour
          cardType
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
        deposit
        publication {
          id
          title
          user
          orderPhotos
        }
        deliveryCheckList {
          name
          value
          type
        }
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        relatedTransactionsId
        type
        rentState
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
