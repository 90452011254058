import { API, graphqlOperation } from "aws-amplify";
import { getDeepChatRoom } from "../../graphql/custom/queries";
import { createChatRoom, updateChatRoom, createChatMessage, updateChatMessage  } from "../../graphql/mutations";

export const chat = {
    namespaced: true,
    state: () => ({
        
    }),
    getters: {
        
    },
    mutations: {
       
    },
    actions: {
        async createChatRoom(context, { rentId, roomName, users } ) {
            const chatRoom = await API.graphql({...graphqlOperation(createChatRoom, {input: {id: rentId, roomName: roomName, users: users, relatedUsers: users.map((user) => user._id)}})
                                                    ,authMode: 'AMAZON_COGNITO_USER_POOLS'});
            return chatRoom.data.createChatRoom.id
        },
        async updateChatRoom(context, chatRoom ) {
            
            const updatedChatRoom = await API.graphql({
                ...graphqlOperation(
                    updateChatRoom, {
                            input: chatRoom
                        }   
                    ),
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });

            return updatedChatRoom.data.updateChatRoom

        },
        async getChatRoom(context, id){
            const chatRoom = await API.graphql({...graphqlOperation(getDeepChatRoom, {id: id}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            return chatRoom.data.getChatRoom
        },
        async saveMessage(context, newMessage){
            const messageCreated = await API.graphql({
                ...graphqlOperation(
                    createChatMessage, {
                        input: newMessage
                    }
                ),authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            return messageCreated.data.createChatMessage
        },
        async markMessageAsRead(context, {messageId, version}){
            const messageUpdated = await API.graphql({
                ...graphqlOperation(
                    updateChatMessage, {
                        input: {
                                id: messageId,
                                _version: version,
                                distributed: true,
                                seen: true
                            }
                        }
                    )
                ,authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            return messageUpdated.data.updateChatMessage
        },
        async updateMessageFiles(context, {messageId, files, version} ) {
            
            const messageUpdated = await API.graphql({
                ...graphqlOperation(
                    updateChatMessage, {
                        input: {
                                id: messageId,
                                _version: version,
                                files: files
                            }
                        }
                    )
                ,authMode: 'AMAZON_COGNITO_USER_POOLS'
            })

            return messageUpdated.data.updateChatMessage

        },
        
    }
}
