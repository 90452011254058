import { API, graphqlOperation, Storage } from "aws-amplify";
import { searchActivePublications } from "../../graphql/custom/queries";
import { listPublications } from "../../graphql/queries";
import { getBoundsForCircle } from "../../shared/geo-utils";

export const searcher = {
    namespaced: true,
    state: () => ({
        searchString: null,
        searchResult: [],
        currentPage: 1,
        from: 0,
        size: 20,
        totalFounds: 0,
        pageCount: 0,
        searchInProgress: false,
        showFilter: false,
        categoriesIds: null,
        searchTriggered: false
    }),
    getters: {
        searchString: (state) => state.searchString,
        searchResult: (state) => state.searchResult,
        currentPage: (state) => state.currentPage,
        pageCount: (state) => state.pageCount,
        pageSize: (state) => state.size,
        totalFounds: (state) => state.totalFounds,
        searchInProgress: (state) => state.searchInProgress

    },
    mutations: {
        setSearchString (state, newSearchString) {
            state.searchString = newSearchString;
        },
        setSearchResult (state, newSearchResult) {
            state.searchResult = newSearchResult;
            state.pageCount = Math.round(state.totalFounds / state.size)
        },
        setTotalFounds (state, newTotalFounds) {
            state.totalFounds = newTotalFounds
        },
        setFrom (state, newFrom) {
            state.from = newFrom
        },
        setCurrentPage (state, newCurrentPage) {
            state.currentPage = newCurrentPage
        },
        clearSearchResult (state) {
            state.searchResult = [];
        },
        setSearchInProcessStatus(state, newState) {
            state.searchInProgress = newState
        },
        showFilter(state, value){
            state.showFilter = value;
        },
        setCategoriesIds(state, value){
            state.categoriesIds = value;
        },
        setSearchTriggered(state, value){
            state.searchTriggered = value;
        },
    },
    actions: {
        async searchActivePublications ({ commit, state, dispatch, rootGetters }, payload){
            try {
                commit('setSearchInProcessStatus', true)
                commit('setSearchTriggered', true) 
                if (payload.newSearchString?.length > 0 || state.categoriesIds) {
                    commit('setSearchString', payload.newSearchString)
                    commit('setCurrentPage', payload.pageNumber)
                    commit('setFrom', (state.size * (state.currentPage - 1)))    
                    
                    if (state.searchString=='marco polo') {
                        const publications_raw = await API.graphql(graphqlOperation(listPublications));
                        commit('setSearchResult', publications_raw.data.listPublications.items);
                    } else {
                        const paramsSearch = { from: state.from, size: state.size  };

                        if (payload.newSearchString?.length > 0 ) {
                            paramsSearch.title = payload.newSearchString
                        }

                        if (rootGetters['user/getCurrentLocation']) {

                            const coordinatesBoundary = getBoundsForCircle(
                                [
                                    rootGetters['user/getCurrentLocation'].coordinates.long,
                                    rootGetters['user/getCurrentLocation'].coordinates.lat,
                                ],
                                rootGetters['user/getCurrentLocation'].availabilityRatio
                            );

                            paramsSearch.minLat = coordinatesBoundary.minLat;
                            paramsSearch.maxLat = coordinatesBoundary.maxLat; 
                            paramsSearch.minLong = coordinatesBoundary.minLng; 
                            paramsSearch.maxLong = coordinatesBoundary.maxLng; 
                        } 

                        if (state.categoriesIds) {
                            paramsSearch.categoryIDs = state.categoriesIds
                        }
                        
                        const publications_raw = await API.graphql(graphqlOperation(searchActivePublications, paramsSearch));
                        
                        commit('setTotalFounds', publications_raw.data.searchActivePublications.total)
                        commit('setSearchResult', publications_raw.data.searchActivePublications.items);
                    }
                    if (state.searchResult?.length > 0) {
                        state.searchResult.forEach((item) => {
                            dispatch('setPublicationDefaultImg', item);
                        });
                    }
                    
                } else {
                    dispatch('clearSearching');
                }
                        
            } catch (error) {
                dispatch('clearSearching');
                throw error;
            } finally {
                commit('setSearchInProcessStatus', false) 
            }
        },
        async changePage({ state, dispatch }, pageNumber){
            dispatch('searchActivePublications', {'newSearchString': state.searchString, 'pageNumber': pageNumber})
        },
        clearSearching (context) {
            context.commit('setSearchString', '')
            context.commit('setCurrentPage', 1)
            context.commit('setFrom', 0)    
            context.commit('setTotalFounds', 0)
            context.commit('setSearchResult', [])
            context.commit('setSearchInProcessStatus', false) 
            
        },
        showFilter({commit}, value) {
            commit("showFilter", value);
        },
        // eslint-disable-next-line no-unused-vars
        async setPublicationDefaultImg(context, publication){
            if (publication.orderPhotos?.length > 0) {
                publication.urlDefaultImg = await Storage.get(`publication/${publication.id}/${publication.orderPhotos[0]}`,
                {
                    level: "protected",
                    identityId: publication.user
                })
            }
        },
        async changeCategoryFilter({commit},  newCategoriesIds){
            if (newCategoriesIds && Object.prototype.toString.call(newCategoriesIds) != "[object Array]") {
                throw new Error('Categories must be an array')
            }
            if (newCategoriesIds && newCategoriesIds.length === 0){
                commit("setCategoriesIds", null);
            }else{
                commit("setCategoriesIds", newCategoriesIds);
            }
        },
        async setSearchTriggered({commit},  searchTriggered){
            commit('setSearchInProcessStatus', searchTriggered)
        }    
    }
}