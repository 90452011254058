import { parseISO, differenceInCalendarDays } from "date-fns";
import { API } from "aws-amplify";
import { h } from "vue";
// Constantes para consultar taxes
const apiName = "taxrates";
const pathGetRate = "/tax-rates?postalCode=:postalCode&state=:state&city=:city";

const parseStringToDate = (date, ignoreTime = true) => {
  if (Object.prototype.toString.call(date) === "[object String]") {
    return ignoreTime ? parseISO(date.split("T")[0]) : parseISO(date);
  } else {
    return date;
  }
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const floatToCurrency = (value, unitTime) => {
  if (unitTime) {
    return `${formatter.format(value)}/${unitTime}`;
  }
  return formatter.format(value);
};

const round = (value) => {
  return Number(formatter.format(value).replace("$", ""));
};

const quantityDaysInRange = (from, to) => {
  from = parseStringToDate(from);
  to = parseStringToDate(to);

  return differenceInCalendarDays(to, from) + 1;
};

const formatDates = (from, to = null) => {
  if (Object.prototype.toString.call(from) == "[object String]") {
    from = parseStringToDate(from);
  }

  if (to && Object.prototype.toString.call(to) == "[object String]") {
    to = parseStringToDate(to);
  }

  let response = "";
  const day = from.getDate();
  const month = from.getMonth() + 1;
  const year = from.getFullYear();
  response += `${month}/${day}/${year}`;

  if (to) {
    const dayTo = to.getDate();
    const monthTo = to.getMonth() + 1;
    const yearTo = to.getFullYear();
    response += ` - ${monthTo}/${dayTo}/${yearTo}`;
  }

  return response;
};

const formatAMPM = (date) => {
  if (Object.prototype.toString.call(date) == "[object String]") {
    date = parseISO(date);
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

const getFileNameFromKey = (key) => {
  return key.split("/").pop();
};

const getExtensionFromFileName = (filename) => {
  return filename.split(".").pop();
};

const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

const getTaxRate = async (locationInfo) => {
  if (locationInfo) {
    let path = pathGetRate;
    path = path.replace(":postalCode", locationInfo.postalCode);
    path = path.replace(":state", locationInfo.region);
    path = path.replace(":city", locationInfo.municipality);
    return (await API.get(apiName, path))["tax-rate"];
  } else {
    return 0;
  }
};

const getMessageDialog = (lines) => {
  const content = [];
  for (const line of lines) {
    content.push(h("br", {}));
    content.push(h("div", {}, line));
  }
  return h("div", {}, content);
};

const removeNullAttrs = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      delete obj[key];
    }
  });
  return obj;
};

const removeAttrs = (obj, attrs) => {
  Object.keys(obj).forEach((key) => {
    if (attrs.includes(key)) {
      delete obj[key];
    }
  });
  return obj;
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export {
  parseStringToDate,
  floatToCurrency,
  quantityDaysInRange,
  formatDates,
  round,
  formatAMPM,
  getFileNameFromKey,
  getExtensionFromFileName,
  downloadBlob,
  getTaxRate,
  getMessageDialog,
  removeAttrs,
  removeNullAttrs,
  sleep,
};
