<template>
  <n-dropdown
    :options="menuOptions"
    trigger="click"
    @select="handleUpdateValue"
    placement="bottom-end"
    v-model:value="currentRouteName"
  >
    <n-button v-if="isMobile" text icon-placement="right" class="pt-2">
      <template #icon>
        <n-badge :value="notificationCount" :max="15">
          <n-icon size="30" :color="themeOverrides.common.primaryColor"
            ><UserAvatar
          /></n-icon>
        </n-badge>
      </template>
    </n-button>
    <n-button round v-else icon-placement="right">
      <template #icon>
        <n-icon size="30" :color="themeOverrides.common.primaryColor"
          ><UserAvatar
        /></n-icon>
      </template>
      <div class="mr-1" v-if="currentUser">{{ currentUser.name }}</div>
    </n-button>
  </n-dropdown>
</template>

<script>
import { defineComponent, h, computed } from "vue";
import { NIcon, NDropdown, NBadge, NButton } from "naive-ui";
import { useStore } from "vuex";
import { useRouter, useRoute, RouterLink } from "vue-router";
import kebabCase from "kebab-case";
import { Auth } from "aws-amplify";
import responsive from "@/mixins/responsive";
import { Plus as PlusIcon } from "@vicons/tabler";
import { UserAvatar } from "@vicons/carbon";
import { themeOverrides } from "@/shared/constants";
import Bell from "@/components/notifications/Bell.vue";
import { useI18n } from "vue-i18n";

function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) });
}

function mustBeRended(meta, is_authenticated, roles) {
  if (meta?.render == undefined) {
    return false;
  }

  if (is_authenticated && meta?.render.inSessionActive) {
    if (meta?.roles == undefined) {
      return true;
    }
    return meta?.roles?.some((rol) => roles.includes(rol));
  }

  if (!is_authenticated) {
    return meta?.render.inSessionInactive;
  }

  return false;
}

export default defineComponent({
  components: { NDropdown, NIcon, NBadge, UserAvatar, NButton },
  mixins: [responsive],
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isMobile = computed(() => store.state.app.isMobile);
    const currentUserRef = computed(() => store.state.user.userFromDB);

    const menuOptionsFromRoutes = computed(() => {
      let routes = router.getRoutes();
      routes = routes.filter((r) =>
        mustBeRended(
          r.meta,
          store.getters["auth/is_authenticated"],
          store.getters["auth/roles"]
        )
      );
      return routes.map(function (route) {
        return {
          label: () =>
            h(
              RouterLink,
              {
                to: { name: route.name },
              },
              { default: () => route.meta?.render?.name }
            ),
          key: kebabCase(route.meta?.render?.name),
          icon: renderIcon(route.meta?.render.icon),
          position: route.meta?.render.position,
          handleUpdateValue: route.meta?.handleUpdateValue,
        };
      });
    });

    const publishOption = {
      label: () =>
        h(
          RouterLink,
          {
            to: { name: "create-publication" },
          },
          { default: () => t("commons.actions.publish") }
        ),
      key: "publish",
      icon: renderIcon(PlusIcon),
      position: -999999,
    };

    const notifications = computed(() =>
      store.state.notifications.list.filter((notification) => !notification.read)
    );

    const notificationOption = {
      label: () =>
        h(
          RouterLink,
          {
            to: { name: "notifications" },
          },
          { default: () => t("commons.labels.notification", 2) }
        ),
      key: "notifications",
      icon: () =>
        h(NIcon, null, {
          default: () => h(Bell, { counter: notifications.value.length }),
        }),
      position: -999998,
    };

    return {
      handleUpdateValue: (key, item) => {
        switch (item.handleUpdateValue) {
          case "signout":
            fetch(Auth.signOut())
              .then(() => {
                store
                  .dispatch("auth/clearContextUser")
                  .then(() => store.dispatch("notifications/UnsubscribeNotificationWeb"));
              })
              .catch((err) => {
                console.error(err);
              });
            break;
        }
      },
      menuOptions: computed(() => {
        let options = [];
        if (isMobile.value) {
          options = [publishOption, ...menuOptionsFromRoutes.value];
          if (store.getters["auth/is_authenticated"]) {
            options.push(notificationOption);
          }
        } else {
          options = [...menuOptionsFromRoutes.value];
        }
        return options.sort((a, b) => {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });
      }),
      currentRouteName: computed(() => {
        return route.meta?.render?.name === undefined
          ? null
          : kebabCase(route.meta?.render?.name);
      }),
      notificationCount: computed(() => notifications.value.length),
      themeOverrides,
      currentUser: currentUserRef,
    };
  },
});
</script>
