<template>
  <div class="home">
    <search-result-list v-if="searchTriggered" />
    <home-categories-list v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import SearchResultList from "@/components/home/SearchResultList.vue";
import HomeCategoriesList from "@/components/home/categories/List.vue";
import { defineComponent, computed, onMounted } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Home",
  components: {
    SearchResultList,
    HomeCategoriesList,
  },
  setup() {
    const store = useStore();
    onMounted(() => {
      store.dispatch("home/loadCategories");
    });
    return {
      searchTriggered: computed(() => store.state.searcher.searchTriggered),
    };
  },
});
</script>
