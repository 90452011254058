<template>
  <n-grid cols="1 s:2 m:3 l:4" responsive="screen" :y-gap="8">
    <n-grid-item v-for="category in categories" :key="category.id"
      ><home-categories-category :category="category" />
    </n-grid-item>
  </n-grid>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { NGrid, NGridItem } from "naive-ui";
import { useStore } from "vuex";
import HomeCategoriesCategory from "@/components/home/categories/Category.vue";
export default defineComponent({
  name: "HomeCategoriesList",
  components: { NGrid, NGridItem, HomeCategoriesCategory },
  setup() {
    const store = useStore();

    onMounted(() => {});

    return {
      publicPath: process.env.BASE_URL,
      categories: computed(() =>
        store.state.home.categories.filter((category) => category.showInHome)
      ),
    };
  },
});
</script>
