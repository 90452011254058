<template>
  <n-grid cols="6 s:4" responsive="screen" style="padding-top: 30px">
    <n-gi>
      <div>
        <router-link to="/">
          <n-image
            :width="isMobile ? 30 : 100"
            :src="`${publicPath}assets/images/logo/logo${isMobile ? '-mini' : ''}.png`"
            :preview-disabled="true"
          />
        </router-link>
      </div>
    </n-gi>
    <n-gi span="4 s:2">
      <div><SearchBar v-if="showSearchBar" /></div>
    </n-gi>
    <n-gi>
      <n-space justify="center" size="large">
        <n-button round v-if="!isMobile" @click="goToPublish">
          <n-icon>
            <plus-icon />
          </n-icon>
          {{ t("commons.actions.publish") }}
        </n-button>
        <RightDrawer v-if="is_authenticated && !isMobile" />
        <MenuTop />
      </n-space>
    </n-gi>
  </n-grid>
</template>

<script>
import responsive from "@/mixins/responsive";
import MenuTop from "@/components/MenuTop.vue";
import SearchBar from "@/components/SearchBar.vue";
import { NGrid, NGi, NImage, NSpace, NButton, NIcon } from "naive-ui";
import { mapGetters } from "vuex";
import RightDrawer from "@/components/notifications/RightDrawer.vue";
import { Plus as PlusIcon } from "@vicons/tabler";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  components: {
    SearchBar,
    MenuTop,
    NGrid,
    NGi,
    NImage,
    RightDrawer,
    NSpace,
    NButton,
    PlusIcon,
    NIcon,
  },
  computed: {
    ...mapGetters("app", ["showSearchBar"]),
    ...mapGetters("auth", ["is_authenticated"]),
  },
  mixins: [responsive],
  data() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const router = useRouter();
    return {
      t,
      publicPath: process.env.BASE_URL,
      goToPublish: () => {
        router.push({ name: "create-publication" });
      },
    };
  },
};
</script>

<style scoped>
.header-logo {
  display: block !important;
}

.header-logo-mobile {
  display: none !important;
  justify-content: center;
  text-align: center;
}

@media (max-width: 650px) {
  .header-logo {
    display: none !important;
  }

  .header-logo-mobile {
    display: block !important;
  }
}
</style>
