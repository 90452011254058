import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  createPartialRentIssue,
  updateToolRent,
  updateNoReadRentIssue,
  resolveRentIssue,
  updateAssistantRentIssue,
} from "../../graphql/custom/mutations";
import {
  listPartialRentIssues,
  getPartialRentIssue,
  getPartialRelatedUsers,
} from "../../graphql/custom/queries";
import { checkFileMimeTypesFromBytes } from "@/shared/file-checker";

const generateDownloadUrl = async (issueId, key, ownerId) => {
  return Storage.get(`issues/${issueId}/${key}`, {
    level: "protected",
    identityId: ownerId,
  });
};

export const issues = {
  namespaced: true,
  state: () => ({
    list: [],
    selectedIssue: null,
    emailRelatedUsers: [],
  }),
  getters: {
    balanceFromSelectedIssue: (state) => {
      let balance = 0;
      if (state.selectedIssue) {
        const payments = state.selectedIssue.rent.paymentsInfo;
        for (const p of payments) {
          let currentBalance = p.amount;

          for (const r of p.reverseHistory ?? []) {
            if (r.type === "REVERSE") {
              currentBalance = 0;
              break;
            }
            balance -= r?.amount?.value ?? 0;
          }

          balance += currentBalance;
        }
      }
      return +(Math.round(balance + "e+2") + "e-2");
    },
  },
  mutations: {
    addIssues: (st, value) => st.list.push(...value),
    clearIssues: (st) => (st.list = []),
    clearSelectedIssue: (st) => (st.selectedIssue = null),
    setSelectedIssue: (st, value) => (st.selectedIssue = value),
    setEmailRelatedUsers: (st, value) => (st.emailRelatedUsers = value),
    setFixedEvidences: (st, value) => (st.selectedIssue.evidences = value),
  },
  actions: {
    async SaveIssue({ commit }, issue) {
      const issueCreated = await API.graphql({
        ...graphqlOperation(createPartialRentIssue, { input: issue }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const issueResult = issueCreated.data.createRentIssue;
      commit("setSelectedIssue", issueResult);
      await API.graphql({
        ...graphqlOperation(updateToolRent, {
          input: {
            id: issueResult.rentId,
            state: "ARBITRATION_IN_PROCESS",
            _version: issueResult.rent._version,
          },
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return issueResult;
    },
    async GetIssues({ commit }) {
      const issuesResult = await API.graphql({
        ...graphqlOperation(listPartialRentIssues),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("clearSelectedIssue");
      commit("clearIssues");
      commit("addIssues", issuesResult.data.listRentIssues.items);
    },
    async GetIssue({ commit, dispatch }, idIssue) {
      const issueDetail = await API.graphql({
        ...graphqlOperation(getPartialRentIssue, { id: idIssue }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setSelectedIssue", issueDetail.data.getRentIssue);
      await dispatch("GetDetailsFromRelatedUsers");
      return issueDetail.data.getRentIssue;
    },
    async GetDetailsFromRelatedUsers({ commit, state }) {
      if (state.selectedIssue) {
        const emails = await API.graphql({
          ...graphqlOperation(getPartialRelatedUsers, {
            id1: state.selectedIssue.relatedUsers[0],
            id2: state.selectedIssue.relatedUsers[1],
          }),
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        commit("setEmailRelatedUsers", [
          { ...emails.data.user1 },
          { ...emails.data.user2 },
        ]);
      }
    },
    isFileAllowed(_context, chunk) {
      const bytes = new Uint8Array(chunk);
      return checkFileMimeTypesFromBytes(bytes, ["jpeg", "png", "pdf"]);
    },
    async AddEvidence({ state }, evidence) {
      const issueEdited = {
        id: state.selectedIssue.id,
        _version: state.selectedIssue._version,
        evidences: state.selectedIssue.evidences,
      };
      if (issueEdited.evidences) {
        issueEdited.evidences.push(evidence);
      } else {
        issueEdited.evidences = [evidence];
      }

      await API.graphql({
        ...graphqlOperation(updateNoReadRentIssue, { input: issueEdited }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    },
    async AddFixedUrlsToEvidences({ state, commit }) {
      const evidences = state?.selectedIssue?.evidences;
      if (evidences) {
        evidences.forEach((e) => {
          if (e?.keys) {
            e.fixedKeys = [];
            e?.keys.forEach(async (k) => {
              e.fixedKeys.push({
                displayName: k.substring(k.indexOf("-") + 1),
                url: await generateDownloadUrl(
                  state.selectedIssue.id,
                  k,
                  e.ownerId
                ),
              });
            });
          }
        });
        commit("setFixedEvidences", evidences);
      }
    },
    async TakeIssue({ state, commit }) {
      const issue = state.selectedIssue;
      issue.state = "IN_PROCESS";
      const result = await API.graphql({
        ...graphqlOperation(updateAssistantRentIssue, {
          input: {
            id: issue.id,
            state: issue.state,
            _version: issue._version,
          },
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setSelectedIssue", result.data.updateRentIssue);
    },
    async SaveResolution(
      { state, commit, dispatch },
      { resolution, userId, rent, waiting }
    ) {
      const inputIssue = {
        id: state.selectedIssue.id,
        _version: state.selectedIssue._version,
        state: "CLOSED",
        resolution,
      };

      let inputToolRent = null;

      if (waiting) {
        inputIssue.state = "WAITING_REFUND";
      }

      if (rent) {
        delete rent.publication;
        delete rent._deleted;
        delete rent._lastChangedAt;
        delete rent.createdAt;
        delete rent.updatedAt;
        inputToolRent = rent;
      } else {
        inputToolRent = {
          id: state.selectedIssue.rentId,
          _version: state.selectedIssue.rent._version,
        };
      }

      inputToolRent.state = resolution.rentState;

      const result = await API.graphql({
        ...graphqlOperation(resolveRentIssue, {
          inputIssue,
          inputToolRent,
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const issue = result.data.updateRentIssue;
      if (inputIssue.state === "CLOSED") {
        issue.relatedUsers.forEach((element) => {
          dispatch(
            "notifications/FireNotification",
            {
              userId: element,
              fromUserId: userId,
              title: "Help Resolved!!!",
              message: resolution.message,
              avatar: {
                publicationID: issue.rent.publication.id,
                publicationUser: issue.rent.publication.user,
                defaultImage: issue.rent.publication.orderPhotos[0],
              },
              targetRoute: {
                name: "view-rent-detail",
                params: [
                  {
                    key: "id",
                    value: issue.rentId,
                  },
                ],
              },
            },
            { root: true }
          ).catch((err) => console.log(err));
        });
      }
      commit("setSelectedIssue", result.data.updateRentIssue);
    },
  },
};
