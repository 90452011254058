<template>
  <n-form @submit.prevent :model="formValue" :rules="rules" ref="formRef">
    <n-form-item @keyup.enter="searchAction" path="newSearchString">
      <n-input-group>
        <n-input
          :style="{ width: '100%' }"
          :placeholder="t('home.header.searchBar.placeholder')"
          v-model:value="formValue.newSearchString"
          :disabled="searchInProgress"
        >
          <template #prefix>
            <n-icon v-if="!isMobile">
              <SearchIcon />
            </n-icon>
          </template>
        </n-input>

        <n-button type="primary" ghost @click="searchAction" :disabled="searchInProgress">
          <span v-if="!isMobile">{{ t("commons.actions.search") }}</span>
          <n-icon v-if="isMobile"> <SearchIcon /> </n-icon>
        </n-button>
      </n-input-group>
    </n-form-item>
  </n-form>
  <n-space justify="space-between">
    <category-selector
      v-model:value="categoryID"
      v-model:children="subCategoriesIds"
      type="dropdown"
      checkStrategy="all"
    />
    <n-button type="primary" size="tiny" @click="showFilters" text>
      <template #icon>
        <n-icon>
          <location-icon />
        </n-icon>
      </template>
      {{ locationTag }}
    </n-button>
  </n-space>
  <n-modal v-model:show="showFilter" preset="dialog" :show-icon="false">
    <div>
      <n-h3 class="my-1 pb-2">{{ t("commons.labels.location") }}</n-h3>
      <location-finder v-model="locationModel" showAvailabilityRadio clearable />
      <n-divider style="margin: 5px 0px" />
      <n-space justify="end">
        <n-button @click="showFilter = false">{{ t("commons.actions.cancel") }}</n-button>
        <n-button @click="filter" type="primary">{{
          t("commons.confirmation.done")
        }}</n-button>
      </n-space>
    </div>
  </n-modal>
</template>

<script>
import {
  SearchOutline as SearchIcon,
  LocationOutline as LocationIcon,
} from "@vicons/ionicons5";
import responsive from "@/mixins/responsive";
import { defineComponent, ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  NForm,
  NFormItem,
  NInputGroup,
  NInput,
  NIcon,
  NButton,
  NSpace,
  NModal,
  NH3,
  NDivider,
} from "naive-ui";
import LocationFinder from "@/components/LocationFinder.vue";
import CategorySelector from "@/components/publication/CategorySelector.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "SearchBar",
  components: {
    SearchIcon,
    LocationFinder,
    LocationIcon,
    CategorySelector,
    NForm,
    NFormItem,
    NInputGroup,
    NInput,
    NIcon,
    NButton,
    NSpace,
    NModal,
    NH3,
    NDivider,
  },
  mixins: [responsive],
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const router = useRouter();
    const formValue = ref({ newSearchString: "" });
    const locationModelRef = ref({
      address: null,
      coordinates: null,
      availabilityRatio: 5,
      municipality: null,
      region: null,
    });
    const categoryIDRef = ref(null);
    const subCategoriesIdsRef = ref(null);
    const formRef = ref(null);
    const showFilter = computed({
      get: () => {
        return store.state.searcher.showFilter;
      },
      set: (value) => {
        store.dispatch("searcher/showFilter", value);
      },
    });

    onMounted(() => {
      store.dispatch("searcher/setSearchTriggered", false);
      if (store.state.searchString !== undefined && store.state.searchString !== "") {
        formValue.value.newSearchString = store.state.searchString;
      }
    });

    const filter = () => {
      store
        .dispatch(
          "user/updateCurrentLocation",
          locationModelRef.value.address ? locationModelRef.value : null
        )
        .then(() => {
          showFilter.value = false;
          searchAction();
        });
    };
    const currentLocationRef = computed(() => store.getters["user/getCurrentLocation"]);

    const searchAction = () => {
      formRef.value.validate(async (errors) => {
        if (!errors) {
          await store.dispatch("app/lockUI");
          store
            .dispatch("searcher/searchActivePublications", {
              newSearchString: formValue.value.newSearchString,
              pageNumber: 1,
            })
            .then(() => {
              store.dispatch("app/unlockUI");
              router.push("/");
            });
        }
      });
    };

    watch(categoryIDRef, (categoryIDRef) => {
      let categories = null;
      if (categoryIDRef) {
        if (subCategoriesIdsRef.value) {
          categories = [...subCategoriesIdsRef.value, categoryIDRef];
        } else {
          categories = [categoryIDRef];
        }
      }

      store.dispatch("searcher/changeCategoryFilter", categories).then(() => {
        searchAction();
      });
    });

    const showFilters = () => {
      if (currentLocationRef.value) {
        locationModelRef.value = currentLocationRef.value;
      }
      store.dispatch("searcher/showFilter", true);
    };

    return {
      t,
      rules: ref({
        newSearchString: {
          required: false,
          message: t("home.header.searchBar.validations.required"),
          trigger: ["input"],
        },
      }),
      formRef,
      formValue,
      showFilter,
      locationModel: locationModelRef,
      searchInProgress: computed(() => store.state.searchInProgress),
      currentLocation: currentLocationRef,
      currentLocationSelected: computed(
        () => currentLocationRef.value?.address?.length > 0
      ),
      widthDrawer: computed(() => {
        if (window.innerWidth < 800) {
          return window.innerWidth;
        }
        return window.innerWidth / 2;
      }),
      filter,
      searchAction,
      locationTag: computed(() => {
        if (!currentLocationRef.value) {
          return t("commons.labels.worldwide");
        } else {
          return `${currentLocationRef.value?.municipality}, ${
            currentLocationRef.value?.region
          } (${currentLocationRef.value?.availabilityRatio} ${t(
            "commons.distances.unit"
          )})`;
        }
      }),
      showFilters,
      categoryID: categoryIDRef,
      subCategoriesIds: subCategoriesIdsRef,
    };
  },
});
</script>

<style scoped>
.n-form-item {
  display: unset;
}
.align-end {
  text-align: end;
}
</style>
