import { mapMutations } from "vuex";

const MAX_WIDTH_MOBILE_SIZE = 640;

export default {
  data() {
    return {
      window: {width: 0, height: 0, isMobile: false},
    };
  },
  methods: {
    ...mapMutations({setIsMobile: 'app/setIsMobile'}),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.window.isMobile = this.window.width < MAX_WIDTH_MOBILE_SIZE;
      this.setIsMobile(this.window.isMobile)
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isMobile(){
      return this.window.isMobile
    },
    windowsWidth(){
      return this.window.width
    },
    windowsHeight(){
      return this.window.height
    }
  },
};
