
import { API, graphqlOperation } from "aws-amplify";
import { listCategories } from "../../graphql/queries";
export const home = {
    namespaced: true,
    state: () => ({
        categories: [],
        categorySelected: null,
    }),
    mutations: {
        setCategories(state, value){
            state.categories = value
        },
        setCategorySelected(state, value){
            state.categorySelected = value
        }
    },
    actions: {
        async loadCategories(context) {
            const parameters_raw = await API.graphql(graphqlOperation(listCategories));
            context.commit('setCategories', parameters_raw.data.listCategories.items)

        },
        async setCategorySelected({commit}, newCategorySelected){
            commit('setCategorySelected', newCategorySelected)
        }
    }
}