<template>
  <n-cascader
    v-if="type === 'cascader'"
    v-model:value="category"
    :placeholder="
      t('commons.actions.select', { reference: t('commons.labels.category', 1) })
    "
    expand-trigger="click"
    :options="categories"
    :check-strategy="checkStrategy"
    show-path
    filterable
    separator=" > "
    clearable
    :cascade="false"
    :filter="filter"
  />
  <n-dropdown
    v-if="type === 'dropdown'"
    :options="categories"
    :show="showDropdown"
    @select="handleSelect"
    key-field="value"
    :on-clickoutside="
      () => {
        showDropdown = false;
      }
    "
  >
    <span
      ><n-button type="primary" size="tiny" text @click="handlerShowDropdown">
        <template #icon>
          <n-icon>
            <FilterIcon />
          </n-icon>
        </template>
        {{
          category
            ? categoryLabel
            : `${t("commons.persons.allFemale")} ${t("commons.labels.category", 2)}`
        }} </n-button
      ><n-button
        class="pl-2"
        type="error"
        size="tiny"
        text
        v-if="category"
        @click="handlerShowDropdown(false)"
      >
        <template #icon>
          <n-icon>
            <ClearIcon />
          </n-icon>
        </template>
      </n-button>
    </span>
  </n-dropdown>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { NCascader, NDropdown, NButton, NIcon } from "naive-ui";
import { useStore } from "vuex";
import {
  FunnelOutline as FilterIcon,
  CloseCircleOutline as ClearIcon,
} from "@vicons/ionicons5";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CategorySelector",
  components: { NCascader, FilterIcon, ClearIcon, NDropdown, NButton, NIcon },
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
      default: "cascader",
    },
    children: {
      type: Object,
    },
    checkStrategy: { type: String, default: "child" },
  },
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const store = useStore();
    const categoriesRef = computed(() => store.state.home.categories);

    const modelRef = ref(props.value);
    const showDropdownRef = ref(false);

    const categorySelectedFromHome = computed(() => store.state.home.categorySelected);

    const getChildren = (parent) => {
      if (!parent) {
        return null;
      }
      const children = categoriesRef.value.filter((item) => item.parentID === parent.id);
      if (children.length > 0) {
        return children.map((item) => {
          const newItem = {
            label: item.name,
            value: item.id,
            children: getChildren(item),
          };
          if (!newItem.children) {
            delete newItem["children"];
          }
          return newItem;
        });
      } else {
        return null;
      }
    };

    const getChildsIds = (children) => {
      if (!children) {
        return [];
      }
      let ids = [];
      children.forEach((child) => {
        ids.push(child.value);
        if (child.children) {
          child.children.forEach((child) => {
            ids.push(child.value);
            ids = [...ids, getChildsIds(child.children)];
          });
        }
      });

      return ids;
    };

    const categoriesOptionsRef = computed(() =>
      categoriesRef.value
        ?.filter((category) => !category.parentID)
        .map((category) => {
          const newItem = {
            label: category.name,
            value: category.id,
            children: getChildren(category),
          };
          if (!newItem.children) {
            delete newItem["children"];
          }
          return newItem;
        })
    );

    watch(modelRef, (modelRef) => {
      emit("update:value", modelRef);
      emit(
        "update:children",
        getChildsIds(
          getChildren(
            categoriesRef.value.filter((category) => category.id === modelRef)[0]
          )
        )
      );
    });

    watch(props, (newProps) => (modelRef.value = newProps.value));

    watch(categorySelectedFromHome, (newCategory) => {
      modelRef.value = newCategory;
    });

    onMounted(async () => {
      categoriesRef.value = await store.dispatch("maintainer_category/getCategories");
    });

    return {
      t,
      category: modelRef,
      categories: categoriesOptionsRef,
      filter: (pattern, option) => {
        return option.label.toLowerCase().includes(pattern.toLowerCase());
      },
      handleSelect(key) {
        modelRef.value = key;
        showDropdownRef.value = false;
      },
      categoryLabel: computed(
        () =>
          categoriesRef.value.filter((category) => category.id === modelRef.value)[0].name
      ),
      showDropdown: showDropdownRef,
      handlerShowDropdown: (fromSelection = true) => {
        if (fromSelection) {
          showDropdownRef.value = !showDropdownRef.value;
        } else {
          modelRef.value = null;
        }
      },
    };
  },
});
</script>
