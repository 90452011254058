<template>
  <n-button text @click="() => (showDrawer = true)">
    <template #icon>
      <n-icon><bell :counter="notifications.length" /></n-icon>
    </template>
  </n-button>

  <n-drawer v-model:show="showDrawer" :width="'30%'" :placement="'right'">
    <n-drawer-content>
      <NotificationsView fullSize @onNavigate="showDrawer = false" />
    </n-drawer-content>
  </n-drawer>
</template>

<script>
import { defineComponent, h, computed, ref } from "vue";
import { NButton, NDrawer, NDrawerContent, NIcon } from "naive-ui";
import Bell from "@/components/notifications/Bell.vue";
import NotificationsView from "@/views/Notifications.vue";

import { useStore } from "vuex";

export default defineComponent({
  name: "RightDrawer",
  components: { Bell, NotificationsView, NButton, NDrawer, NDrawerContent, NIcon },
  setup() {
    const store = useStore();
    const notifications = computed(() =>
      store.state.notifications.list.filter((notification) => !notification.read)
    );
    const showDrawer = ref(false);

    return {
      notifications,
      list: [
        {
          key: "header",
          type: "render",
          render: () => h(NotificationsView, null),
        },
      ],
      showDrawer,
    };
  },
});
</script>
