import { API, graphqlOperation } from "aws-amplify";
import { createReview, updateReview } from "@/graphql/mutations";
import { listReviews } from "@/graphql/queries";
import { removeAttrs } from "../../shared/utils";
import { listUsersForReviews } from "../../graphql/custom/queries";


export const review = {
    namespaced: true,
    actions: {
        async getReviewByUserAndRent(context, {from, rentId} ) {
            const reviews_raw = await API.graphql({...graphqlOperation(listReviews, { filter: { from: { eq: from}, and: {rentId: {eq: rentId}}}}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const reviews = reviews_raw.data.listReviews.items
            
            return reviews.length > 0 ? reviews[0] : null
           
        },
        async getReviewByPublication(context, publicationId) {
          console.debug("getReviewByPublication ", publicationId)
          const reviews_raw = await API.graphql(
            {
              ...graphqlOperation(
                listReviews, 
                { 
                  filter: { 
                    publicationID: {
                      eq: publicationId
                    },
                    and: {
                      toolRate: { 
                        attributeExists: true 
                      }
                    }
                  } 
                }
              ), 
              authMode: 'AMAZON_COGNITO_USER_POOLS'
            }
          );
          return reviews_raw.data.listReviews.items
        },
        async getReviewsTo(context, to) {
          const reviews_raw = await API.graphql({...graphqlOperation(listReviews, { filter: { to: { eq: to }}}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
          return reviews_raw.data.listReviews.items
        },
        async save(context, review ) {
          if (review.id){
            const reviewUpdated = await API.graphql({...graphqlOperation(updateReview, { input: removeAttrs(review, ['createdAt','updatedAt','_deleted','_lastChangedAt']), }), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            review = reviewUpdated.data.updateReview
          }else{
            const reviewCreated = await API.graphql({...graphqlOperation(createReview, { input: review }), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            review = reviewCreated.data.createReview
          }
          return review
        },
        async getUsersById(context, ids) {
          const filter = {
            or: []
          }

          for(const id of ids){
            filter.or.push(
              {
                id: {
                  eq: id
                }
              }
            )
          }

          const result = await API.graphql(graphqlOperation(listUsersForReviews, {filter: filter}));
          return result.data?.listUsers.items
      },
      }
    }
