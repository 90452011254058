<template>
  <n-config-provider
    :theme-overrides="themeOverrides"
    :locale="naiveLocale"
    :date-locale="naiveDatelocale"
  >
    <n-dialog-provider>
      <n-notification-provider>
        <n-spin :show="isBusy">
          <n-layout :class="isiOSPlatform ? 'ios-safe-areas' : ''">
            <n-layout-header>
              <n-message-provider :duration="6000" :placement="'bottom'">
                <Header v-if="showHeader" />
              </n-message-provider>
            </n-layout-header>
            <n-layout-content>
              <n-message-provider :duration="6000" :placement="'bottom'">
                <router-view :key="route.fullPath" />
              </n-message-provider>
            </n-layout-content>
            <n-layout-footer>
              <Footer
                v-if="showFooter"
                showResume
                @expand="onExpandFooterChange"
                :detailDrawerHeight="`${(
                  (50 * parseInt(windowsHeight)) /
                  100
                ).toString()}px`"
                ><n-space :vertical="isMobile"
                  ><div :class="isMobile ? 'pl-1' : 'pl-5'">
                    <router-link
                      class="pr-2"
                      style="text-decoration: none; color: inherit; font-size: 17px"
                      to="/"
                      >© 2022 Right Tool Rental Inc.</router-link
                    >
                  </div>
                  <div :class="isMobile ? 'pl-1' : ''">
                    <n-icon class="pr-2" size="15"> <tools-icon /> </n-icon
                    ><router-link
                      class="pr-2"
                      style="text-decoration: none; color: inherit; font-size: 17px"
                      to="/privacy"
                      >{{ t("commons.labels.privacy") }}</router-link
                    >
                  </div>
                  <div :class="isMobile ? 'pl-1' : ''">
                    <n-icon class="pr-2" size="15"> <tools-icon /> </n-icon
                    ><router-link
                      class="pr-2"
                      style="text-decoration: none; color: inherit; font-size: 17px"
                      to="/terms"
                      >{{ t("commons.labels.term", 2) }}</router-link
                    >
                  </div>
                  <div class="pt-1" style="width: 60px" :class="isMobile ? 'pl-1' : ''">
                    <n-input-group>
                      <n-icon class="pr-2" size="15"> <locale-icon /> </n-icon>
                      <n-select
                        v-model:value="$i18n.locale"
                        size="tiny"
                        :options="
                          $i18n.availableLocales.map((locale) => {
                            return { label: locale, value: locale };
                          })
                        "
                        :consistent-menu-width="false"
                        placement="top"
                    /></n-input-group></div
                ></n-space>
              </Footer>
            </n-layout-footer>
          </n-layout>
        </n-spin>
      </n-notification-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, onBeforeMount, ref } from "vue";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { PushNotifications } from "@capacitor/push-notifications";
import Header from "./components/Header.vue";
import {
  NConfigProvider,
  NNotificationProvider,
  NDialogProvider,
  NSpin,
  NLayout,
  NLayoutHeader,
  NLayoutContent,
  NMessageProvider,
  NLayoutFooter,
  NIcon,
  NSpace,
  NSelect,
  NInputGroup,
  enUS,
  dateEnUS,
  esAR,
  dateEsAR,
} from "naive-ui";
import { themeOverrides } from "@/shared/constants.js";
import Footer from "@/components/Footer.vue";
import responsive from "@/mixins/responsive";
import { Tools as ToolsIcon } from "@vicons/carbon";
import { World as LocaleIcon } from "@vicons/tabler";
import { useI18n } from "vue-i18n";

export default {
  components: {
    NConfigProvider,
    Header,
    NNotificationProvider,
    NDialogProvider,
    NSpin,
    NLayout,
    NLayoutHeader,
    NLayoutContent,
    NMessageProvider,
    NLayoutFooter,
    Footer,
    NIcon,
    ToolsIcon,
    LocaleIcon,
    NSpace,
    NSelect,
    NInputGroup,
  },
  mixins: [responsive],
  setup() {
    const { t, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const is_authenticated = computed(() => store.getters["auth/is_authenticated"]);
    const is_acceptedDoR = computed(() => store.getters["user/is_acceptedDoR"]);
    const footerExpanded = ref(false);
    const isiOSPlatform = computed(() => store.state.app.isiOS);
    const updateUser = () => {
      if (is_authenticated.value) {
        store
          .dispatch("user/getUserById", store.getters["auth/user_id"])
          .then(async (user_db) => {
            if (is_authenticated.value && !is_acceptedDoR.value) {
              router.push({ name: "discharge-responsibility" });
            }
            if (Capacitor.getPlatform() !== "web") {
              const deviceId = await Device.getId();
              user_db.notificationSettings.channels.mobileDevices ??= [];
              const device = user_db.notificationSettings.channels.mobileDevices.filter(
                (x) => x.id == deviceId.uuid
              );
              if (device.length < 1) {
                await registerDevice(deviceId.uuid);
              } else if (!device[0].enable) {
                await registerDevice(deviceId.uuid, true, device[0].endpoint);
              }
              await PushNotifications.addListener(
                "pushNotificationActionPerformed",
                async (payload) => {
                  if ("targetRoute" in payload.notification.data) {
                    store
                      .dispatch(
                        "notifications/ConvertToRoute",
                        JSON.parse(payload.notification.data.targetRoute)
                      )
                      .then((targetRoute) => {
                        if (targetRoute) {
                          router.push(targetRoute);
                        }
                      });
                  }
                }
              );
            }
          });
        store
          .dispatch("notifications/FillNotifications", store.getters["auth/user_id"])
          .then(() =>
            store.dispatch(
              "notifications/SubscribeNotificationWeb",
              store.getters["auth/user_id"]
            )
          );
      } else {
        setTimeout(updateUser, 500);
      }
    };
    const registerDevice = async (deviceId, renew = false, endpoint = null) => {
      const registerListener = await PushNotifications.addListener(
        "registration",
        async (token) => {
          console.warn("token: " + token.value);
          registerListener.remove();
          if (renew) {
            console.warn("update");
            await store.dispatch("user/updateDevice", {
              token: token.value,
              endpoint: endpoint,
              deviceId: deviceId,
            });
          } else {
            await store.dispatch("user/registerDevice", {
              token: token.value,
              deviceId: deviceId,
            });
          }
        }
      );
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    };
    onBeforeMount(() => {
      store.dispatch("auth/setContextUser").then(() => updateUser());
      store.dispatch("app/loadConfiguration");
      store.dispatch("app/updatePlatformIdentification", Capacitor.getPlatform());
    });
    return {
      t,
      isiOSPlatform,
      isBusy: computed(() => store.getters["app/isBusy"]),
      showHeader: computed(() => store.state.app.showHeader),
      showFooter: computed(() => store.state.app.showFooter),
      route,
      themeOverrides,
      onExpandFooterChange: (expanded) => (footerExpanded.value = expanded),
      naiveLocale: computed(() => {
        switch (locale.value) {
          case "en":
            return enUS;
          case "es":
            return esAR;
          default:
            return enUS;
        }
      }),
      naiveDatelocale: computed(() => {
        switch (locale.value) {
          case "en":
            return dateEnUS;
          case "es":
            return dateEsAR;
          default:
            return dateEnUS;
        }
      }),
    };
  },
};
</script>

<style lang="scss">
#app,
.n-drawer-container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1500px;
  margin: 0 auto;
}
body {
  @media screen and (min-width: 799px) {
    margin-bottom: 70px !important;
  }
}
.n-layout-content {
  padding: 14px;
  min-height: calc(100vh - 250px);
}
.n-layout .n-layout-scroll-container {
  overflow-x: unset;
  box-sizing: unset;
}
.ios-safe-areas {
  padding: 12px;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
</style>
