<template>
  <div class="category">
    <a @click="filterByCategory()"
      ><n-image
        :height="imageHeight"
        :src="srcImage"
        :fallbackSrc="fallBackImage"
        :preview-disabled="true"
    /></a>
  </div>
  <div>{{ category.name }}</div>
</template>

<script>
import { defineComponent, computed } from "vue";
import { NImage } from "naive-ui";
import { useStore } from "vuex";
import { constantCase } from "constant-case";

export default defineComponent({
  name: "HomeCategoriesCategory",
  components: { NImage },
  props: {
    category: { type: Object, required: true },
  },
  setup(props) {
    const store = useStore();
    return {
      publicPath: process.env.BASE_URL,
      srcImage: computed(
        () =>
          `${process.env.BASE_URL}assets/images/categories/${constantCase(
            props.category.name.toLowerCase()
          )}.png`
      ),
      fallBackImage: computed(() => `${process.env.BASE_URL}assets/images/fallback.jpg`),
      imageHeight: 200,
      filterByCategory: () => {
        store.dispatch("home/setCategorySelected", props.category.id);
      },
    };
  },
});
</script>
