<template>
  <n-grid cols="1 s:8" responsive="screen">
    <n-grid-item span="0 s:1" offset="0 s:1">
      <div style="padding-top: 22px">
        <n-h2>{{ searchString }}</n-h2>
        <n-text v-if="totalFounds > 0">
          {{
            t("searchResult.sideBar.item", totalFounds, { count: totalFounds })
          }}</n-text
        >&nbsp;
      </div>
    </n-grid-item>
    <n-grid-item span="1 s:5">
      <div>
        <n-list>
          <template #footer>
            <n-space justify="center">
              <n-pagination
                v-if="pageCount > 0"
                v-model:page="currentPage"
                :page-count="pageCount"
                :on-update:page="changePage"
                :page-size="pageSize"
              />
            </n-space>
          </template>
          <n-list-item v-for="item in searchResult" :key="item.id">
            <template #prefix>
              <a @click="selectTool(item.id)">
                <img alt="" width="100" :src="item.urlDefaultImg ?? fallbackImg" />
              </a>
            </template>
            <a @click="selectTool(item.id)">
              <n-thing :title="item.title" :description="getDetailedPrice(item)">
                {{
                  t("searchResult.item.deliveryTypeLabel", {
                    deliveryType: getDeliveryType(item),
                  })
                }}
                <template #footer v-if="item.prices.offerDiscount">
                  {{ t("searchResult.item.specialOffersText") }}
                </template>
              </n-thing>
            </a>
          </n-list-item>
        </n-list>
      </div>
    </n-grid-item>
  </n-grid>
</template>

<script>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { floatToCurrency } from "@/shared/utils";
import {
  NGrid,
  NGridItem,
  NH2,
  NText,
  NList,
  NSpace,
  NPagination,
  NListItem,
  NThing,
} from "naive-ui";
import { useI18n } from "vue-i18n";

export default {
  components: {
    NGrid,
    NGridItem,
    NH2,
    NText,
    NList,
    NSpace,
    NPagination,
    NListItem,
    NThing,
  },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();
    const router = useRouter();
    return {
      t,
      fallbackImg: process.env.BASE_URL + "img/tool-box.png",
      searchString: computed(() => store.state.searcher.searchString),
      searchResult: computed(() => store.state.searcher.searchResult),
      currentPage: computed(() => store.state.searcher.currentPage),
      pageCount: computed(() => store.state.searcher.pageCount),
      pageSize: computed(() => store.state.searcher.pageSize),
      totalFounds: computed(() => store.state.searcher.totalFounds),
      searchInProgress: computed(() => store.state.searcher.searchInProgress),
      changePage: async (pageNumber) => store.dispatch("searcher/changePage", pageNumber),
      selectTool: (id) =>
        router.push({ name: "view-publication", params: { publicationId: id } }),
      getDetailedPrice: (item) => {
        return floatToCurrency(item.prices.pricePerDay, t("commons.labels.day", 1));
      },
      getDeliveryType: (item) => {
        if (item.hasDelivery) {
          return t("searchResult.deliveryTypes.delivery");
        } else {
          return t("searchResult.deliveryTypes.pickup");
        }
      },
    };
  },
};
</script>

<style scoped>
.n-grid {
  text-align: left;
}
a {
  text-decoration: none;
  cursor: pointer;
}
</style>
