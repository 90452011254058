<template>
  <n-button class="pt-2" text style="font-size: 20px">
    <n-badge :value="counter" :max="15">
      <n-icon :color="!isMobile ? themeOverrides.common.primaryColor : themeOverrides.common.darkColor"> <bell-icon /> </n-icon>
    </n-badge>
  </n-button>
</template>

<script>
import { defineComponent } from "vue";
import { NIcon, NButton, NBadge } from "naive-ui";
import { Bell as BellIcon } from "@vicons/tabler";
import responsive from "@/mixins/responsive";
import { themeOverrides } from "@/shared/constants";

export default defineComponent({
  name: "Bell",
  components: { NIcon, NButton, BellIcon, NBadge },
  mixins: [responsive],
  props: { counter: { type: Number, required: true } },
  data() {
    return {
      themeOverrides
    };
  },
  setup() {
    return {};
  },
});
</script>
