<template>
  <n-space
    justify="space-between"
    class="footer"
    :class="isiOSPlatform ? 'footer-ios' : ''"
    ><div v-if="showResume" class="content pl-2 pr-2">
      <n-collapse-transition :show="!showDetailedInfo">
        <slot></slot>
      </n-collapse-transition>
    </div>
    <n-button
      v-if="haveDetails"
      text
      @click="() => (showDetailedInfo = !showDetailedInfo)"
      class="pr-2 pt-2"
    >
      <template #icon>
        <n-icon>
          <UpIcon v-if="!showDetailedInfo" />
          <DownIcon v-else />
        </n-icon>
      </template> </n-button
  ></n-space>

  <n-drawer
    v-if="haveDetails"
    v-model:show="showDetailedInfo"
    placement="bottom"
    :height="detailDrawerHeight"
  >
    <n-drawer-content>
      <n-grid :cols="1">
        <n-gi>
          <n-space justify="end" size="small">
            <n-button
              text
              @click="() => (showDetailedInfo = !showDetailedInfo)"
              class="pr-2 pt-2"
            >
              <template #icon>
                <n-icon>
                  <UpIcon v-if="!showDetailedInfo" />
                  <DownIcon v-else />
                </n-icon>
              </template>
            </n-button>
          </n-space>
        </n-gi>
        <n-gi>
          <div class="content pl-2 pr-2">
            <n-collapse-transition :show="showDetailedInfo">
              <slot></slot>
            </n-collapse-transition>
          </div>
        </n-gi>
      </n-grid>
    </n-drawer-content>
  </n-drawer>
</template>

<style lang="scss" scoped>
.footer {
  @media screen and (min-width: 799px) {
    position: fixed;
  }
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background: white;
  padding-top: unset;
  text-align: left;
}

.footer-ios {
  @mixin supports-safe-area-insets {
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      @content;
    }
  }
  @include supports-safe-area-insets {
    height: calc(env(safe-area-inset-bottom) + 120px);
  }
}

:deep(.n-drawer-body-content-wrapper) {
  padding: unset !important;
  text-align: left;
}
</style>
<script>
import { defineComponent, watch, ref, computed } from "vue";
import {
  NDrawer,
  NDrawerContent,
  NButton,
  NIcon,
  NGrid,
  NGi,
  NSpace,
  NCollapseTransition,
} from "naive-ui";
import responsive from "@/mixins/responsive";

import {
  ArrowUpCircleOutline as UpIcon,
  ArrowDownCircleOutline as DownIcon,
} from "@vicons/ionicons5";

import { useStore } from "vuex";

export default defineComponent({
  name: "Footer",
  props: {
    showResume: {
      type: Boolean,
      default: false,
    },
    haveDetails: {
      type: Boolean,
      default: false,
    },
    detailDrawerHeight: {
      type: String,
      default: "251",
    },
  },
  components: {
    NDrawer,
    NDrawerContent,
    NButton,
    UpIcon,
    DownIcon,
    NIcon,
    NGrid,
    NGi,
    NSpace,
    NCollapseTransition,
  },

  emits: ["expand"],
  mixins: [responsive],
  setup(props, { emit }) {
    const store = useStore();
    const isiOSPlatform = computed(() => store.state.app.isiOS);

    const showDetailedInfo = ref(false);
    watch(showDetailedInfo, (showDetailedInfo) => emit("expand", showDetailedInfo));
    return {
      showDetailedInfo,
      isiOSPlatform,
    };
  },
});
</script>
