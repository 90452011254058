import { API, Auth, graphqlOperation } from "aws-amplify";
import { createDepositTransactionsToRefund } from"../../graphql/mutations"

const apiName = "payments";
const pathGetToken = "/customer/token";
const pathProcess = "/process";
const pathCaptureTransaction = "/capture-transactions";
const pathCreateCustomer = "/customer/create";
const pathRefund = "/refund";
const pathReverse = "/reverse";
const pathTransactions = "/transactions";
const pathCaptureDeposit = "/capture-deposit";


export const payments = {
  namespaced: true,
  state: () => ({
    clientToken: null,
    paymentMethod: null,
    transaction: null,
    refund: null
  }),
  mutations: {
    setClientToken: (st, value) => st.clientToken = value,
    setPaymentMethod: (st, value) => st.paymentMethod = value,
    setTransaction: (st, value) => st.transaction = value,
    setRefund: (st, value) => st.refund = value
  },
  actions: {
    async CreateCustomerId(_context, email) {
      const response = await API.post(apiName, pathCreateCustomer, {
        body: {
          email: email
        }
      });
      return response.data.createCustomer.customer.id;
    },
    async GetClientToken({ commit }, customerId) {
      const response = await API.post(apiName, pathGetToken, {
        body: {
            customerId: customerId
          }
      });      
      const clientToken = response.data.createClientToken.clientToken;
      commit('setClientToken', clientToken);
      return clientToken;
    },
    async Process({ commit, state }, { amount, taxAmount, paymentMethod, rent, publication, paymentType, mode }) {    
      commit('setPaymentMethod', paymentMethod);
      const session = await Auth.currentSession();
      const response = await API.post(apiName, pathProcess, {
        body: {
          rent,
          publication,
          auth: session.getAccessToken().getJwtToken(),
          nonce: state.paymentMethod.nonce,
          cardInfo: state.paymentMethod.details,
          mode: mode, 
          charge: {
            amount:  amount, 
            tax: taxAmount, 
            paymentType: paymentType 
          },
          deviceData: state.paymentMethod.deviceData
        },
      });
      console.debug("response",response)
      if (response === {}) {
        throw new Error("Error processing payment");
      }
      commit('setTransaction', response)
      return response;
    },
    async CaptureTransactions(_context, {rent, checklist}){
      const session = await Auth.currentSession();
      const response = await API.post(apiName, pathCaptureTransaction, {
        body: {
          auth: session.getAccessToken().getJwtToken(),
          rent: {
            id: rent.id, 
            paymentsInfo: rent.paymentsInfo,
            state: rent.state,
            user: rent.user, 
            _version: rent._version,
            deliveryCheckList: checklist,
            executedRangeOfDates: { start: new Date() }
          },
          publication: rent.publication
        },
      });
      console.debug("response",response)
      if (response === {}) {
        throw new Error("Error processing payment");
      }
      return response.rent;
    },
    async Refund(_context, {rentId, transactionId, amount}) {
      const session = await Auth.currentSession();
      const response = await API.post(apiName, pathRefund, {
        body: {
          rentId: rentId,
          transactionId: transactionId,
          amount: amount,
          auth: session.getAccessToken().getJwtToken(),
        },
      });
      if (response.errors?.length > 0) {
        console.error('tx error', response.errors);
        throw new Error(response.errors[0].message);
      }
      return response;
    },
    async Reverse(_context, {rentId, transactionId, isDeposit}) {
      const session = await Auth.currentSession();
      const response = await API.post(apiName, pathReverse, {
        body: {
          rentId: rentId,
          transactionId: transactionId,
          auth: session.getAccessToken().getJwtToken(),
          isDeposit
        },
      });
      if (response.errors?.length > 0) {
        console.error('tx error', response.errors);
        throw new Error(response.errors[0].message);
      }
       return response;
    },
    async getTransactionInfo(_context, transactionId) {
      const response = await API.get(apiName, pathTransactions, {
        queryStringParameters: { 
            id: transactionId,
        }
      });
      if (response.errors?.length > 0) {
        console.error('tx error', response.errors);
        throw new Error(response.errors[0].message);
      }
       return response;
    },
    async CaptureDeposit(_context, rent){
      const session = await Auth.currentSession();
      const response = await API.post(apiName, pathCaptureDeposit, {
        body: {
          auth: session.getAccessToken().getJwtToken(),
          rent: {
            id: rent.id, 
            depositTransaction: rent.depositTransaction,
            state: rent.state,
            user: rent.user, 
            _version: rent._version,
          },
          publication: rent.publication
        },
      });
      console.debug("response",response)
      if (response === {}) {
        throw new Error("Error processing payment");
      }
      return response.rent;
    },
    async createQueueDepositRefund(_context, queueRefund){
      await API.graphql(
        {
          ...graphqlOperation(
            createDepositTransactionsToRefund, 
            {
              input: { ...queueRefund, status: 'IN_QUEUE' }
            }), authMode: 'AMAZON_COGNITO_USER_POOLS'});
    }
  }
}