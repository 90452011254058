export const searchActivePublications = /* GraphQL */ `
  query SearchActivePublications(
    $title: String
    $from: Int!
    $size: Int!
    $minLat: Float
    $maxLat: Float
    $minLong: Float
    $maxLong: Float
    $categoryIDs: [String]
  ) {
    searchActivePublications(
      title: $title
      from: $from
      size: $size
      minLat: $minLat
      maxLat: $maxLat
      minLong: $minLong
      maxLong: $maxLong
      categoryIDs: $categoryIDs
    ) {
      items {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          createdAt
          updatedAt

          _deleted
          _lastChangedAt
        }
        maintenances {
          id
          state
        }
        createdAt
        updatedAt
        _deleted
        _lastChangedAt
      }
      total
    }
  }
`;

export const getUserDeep = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      familyName
      phoneNumber
      acceptDoR
      verified
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
          coordinates {
            lat
            long
          }
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
          mobileDevices {
            id
            endpoint
            enable
          }
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listUsersForReviews = /* GraphQL */ `
  query listUsers($filter: ModelUserFilterInput = {}) {
    listUsers(filter: $filter) {
      items {
        id
        name
        createdAt
      }
    }
  }
`;

export const getToolRentDetail = /* GraphQL */ `
  query GetToolRent($id: ID!) {
    getToolRent(id: $id) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        depositRequired
        haveDelivery
        categoryID
        orderPhotos
        locationInfo {
          address
          coordinates {
            lat
            long
          }
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      renterServiceFee
      ownerServiceFee
      deposit
      depositTransaction {
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
          amount {
            value
          }
          refundedTransaction {
            id
            amount {
              value
            }
            orderId
            status
          }
        }
        type
        amount
        taxAmount
        balance
        cardType
        lastTwo
        bin
        lastFour
      }
      paymentsInfo {
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
          amount {
            value
          }
          refundedTransaction {
            id
            amount {
              value
            }
            orderId
            status
          }
        }
        type
        amount
        taxAmount
        balance
        cardType
        lastTwo
        bin
        lastFour
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
      }
      limitDaysForCancelationFree
      chatRoomId
      user
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getToolRentDetailToContinue = /* GraphQL */ `
  query GetToolRent($id: ID!) {
    getToolRent(id: $id) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          coordinates {
            lat
            long
          }
          availabilityRatio
          municipality
          region
          postalCode
        }
        categoryID
        orderPhotos
        maintenances {
          id
          rangeOfDates {
            start
            end
          }
          state
        }
        rents {
          items {
            id
            state
            requestedRangeOfDates {
              start
              end
            }
            executedRangeOfDates {
              start
              end
            }
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      discount
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      renterServiceFee
      ownerServiceFee
      deposit
      paymentsInfo {
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          coordinates {
            lat
            long
          }
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
      }
      limitDaysForCancelationFree
      user
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listToolRentsForVaultManagerAvailability = /* GraphQL */ `
  query ListToolRents(
    $id: ID
    $filter: ModelToolRentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listToolRents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        state
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getPartialPublicationForRent = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      categoryID
      orderPhotos
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      rents {
        items {
          state
          requestedRangeOfDates {
            start
            end
          }
          executedRangeOfDates {
            start
            end
          }
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getPublicationsRentsAndMaintenances = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      user
      title
      state
      rents {
        items {
          state
          requestedRangeOfDates {
            start
            end
          }
          executedRangeOfDates {
            start
            end
          }
        }
        nextToken
        startedAt
      }
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getPartialPublicationForView = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      categoryID
      orderPhotos
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getPublicationToUpdate = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const listPartialPublications = /* GraphQL */ `
  query ListPublications(
    $filter: ModelPublicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        title
        category {
          name
        }
        state
        createdAt
        _version
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export const listDeepNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        fromUserId
        title
        message
        datetime
        read
        sentState
        avatar {
          publicationID
          publicationUser
          defaultImage
        }
        targetRoute {
          name
          params {
            key
            value
          }
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getDeepChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      roomName
      unreadCount
      index
      lastMessage {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        _id
        username
        avatar
        isOnline
      }
      messages {
        items {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          files {
            name
            size
            type
            extension
            audio
            duration
            key
            url
          }
        }
        nextToken
        startedAt
      }
      typingUsers {
        _id
        username
        avatar
        isOnline
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getUsername = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
    }
  }
`;

export const listToolRentsForMaintainer = /* GraphQL */ `
  query ListToolRents(
    $id: ID
    $filter: ModelToolRentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listToolRents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        state
        publicationID
        publication {
          id
          user
          title
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        pricePerDay
        pricePerDayDiscounted
        baseCost
        discount
        tax
        taxRate
        totalCost
        renterServiceFee
        ownerServiceFee
        deposit
        requestedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          range {
            start
            end
          }
          type
          datetime
        }
        user
        relatedUsers
        chatRoomId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const listPartialRentIssues = /* GraphQL */ `
  query ListRentIssues(
    $id: ID
    $filter: ModelRentIssueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRentIssues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        subject
        description
        datetime
        state
        evidences {
          id
          subject
          datetime
          owner
          description
          keys
        }
        rentId
        rent {
          id
          publication {
            id
            title
          }
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;

export const getPartialRentIssue = /* GraphQL */ `
  query GetRentIssue($id: ID!) {
    getRentIssue(id: $id) {
      id
      subject
      description
      datetime
      state
      snapshotRentState
      evidences {
        id
        subject
        datetime
        owner
        description
        keys
      }
      resolution {
        rentState
        summary
        message
        datetime
        relatedTransactionsId
        type
      }
      rentId
      rent {
        id
        _version
        user
        publication {
          id
          title
          user
          orderPhotos
        }
        deliveryCheckList {
          name
          value
          type
        }
        depositTransaction {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          bin
          lastFour
          cardType
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
        deposit
        paymentsInfo {
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          reverseHistory {
            id
            status
            amount {
              value
            }
            date
            type
          }
        }
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const getPartialRelatedUsers = /* GraphQL */ `
  query getPartialRelatedUsers($id1: ID!, $id2: ID!) {
    user1: getUser(id: $id1) {
      id
      email
      name
      familyName
      phoneNumber
      currentLocation {
        address
        municipality
        region
        postalCode
      }
    }
    user2: getUser(id: $id2) {
      id
      email
      name
      familyName
      phoneNumber
      currentLocation {
        address
        municipality
        region
        postalCode
      }
    }
  }
`;

export const listUserBalanceTransactions = /* GraphQL */ `
  query ListUserBalanceTransactions(
    $id: ID
    $filter: ModelUserBalanceTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserBalanceTransactions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        description
        type
        status
        amount
        notes
        rentId
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
