export default {
  "signIn": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "form": {
      "email": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your email"])}
      },
      "password": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your password"])}
      },
      "firstName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your first name"])}
      },
      "lastName": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your last name"])}
      },
      "newPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your new password"])}
      },
      "repeatPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat your new password"])}
      },
      "phone": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your phone"])}
      },
      "code": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the code received"])}
      },
      "validations": {
        "passwordNotSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is not same as re-entered password!"])}
      }
    },
    "links": {
      "forgotPassword": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])}
      },
      "createAccount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New User?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])}
      },
      "haveAccount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have Account?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])}
      },
      "lostCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost your code?"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Code"])}
      }
    },
    "messages": {
      "errorAuthenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem during the authentication process"])},
      "codeSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code sent successfully"])},
      "codeSentForNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code sent, please check your email and provide the code with your new password"])}
    }
  },
  "home": {
    "header": {
      "searchBar": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's find the right tool"])},
        "validations": {
          "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a search phrase"])}
        }
      },
      "publishButton": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish"])}
      },
      "menu": {
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile"])},
        "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
        "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Publications"])},
        "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
        "rents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Rents"])},
        "issues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issues"])}
      },
      "categoryFilter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Categories"])}
      },
      "locationFilter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worldwide"])}
      }
    },
    "notifications": {
      "tabs": {
        "unread": {
          "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications pending"])}
        },
        "settings": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How You Get Notifications"])},
          "options": {
            "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile App"])},
            "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
            "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "sms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])}
          },
          "sucessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings updated"])},
          "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the update"])}
        }
      }
    },
    "messages": {
      "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found!"])}
    }
  },
  "searchResult": {
    "sideBar": {
      "item": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["no items"]), _normalize([_interpolate(_named("count")), " item"]), _normalize([_interpolate(_named("count")), " items"])])}
    },
    "item": {
      "deliveryTypeLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delivery Type: ", _interpolate(_named("deliveryType"))])},
      "specialOffersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special offers available"])}
    },
    "deliveryTypes": {
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])}
    }
  },
  "profile": {
    "contactInformation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
      "form": {
        "firstName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your first name"])}
        },
        "lastName": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your last name"])}
        },
        "email": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your email"])}
        },
        "phone": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your phone"])}
        }
      }
    },
    "addresses": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])}
    },
    "password": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
      "form": {
        "currentPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your current password"])}
        },
        "newPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input your new password"])}
        },
        "repeatPassword": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat Password"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat your new password"])}
        }
      },
      "buttonChange": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])}
      }
    },
    "payoutMethodsInformation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Methods"])},
      "form": {
        "address": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "notificationWhenNoAddressRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address registered in your profile"])},
          "buttonTextWhenNoAddressRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to profile panel to add one"])}
        },
        "paymentMethods": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Methods"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a payment method type"])},
            "options": {
              "BANK_TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transfer"])},
              "PAYPAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal"])}
            }
          },
          "routingNumber": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routing Number"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the rounting number"])}
          },
          "accountNumber": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the account number"])}
          },
          "accountHolder": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of Account Holder"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the name of account holder"])}
          }
        }
      }
    },
    "disclaimer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimers"])},
      "goToProfileButton": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go To Profile"])}
      },
      "dialogs": {
        "disclaimerRejected": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer rejected"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be logged out"])}
        }
      }
    },
    "verification": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile's Verification"])},
      "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been satisfactorily verified"])},
      "unverified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must verify your profile in order to use some functions"])},
      "verifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verifying"])},
      "result": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been satisfactorily verified"])},
        "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile hasn't been verified"])}
      }
    }
  },
  "categories": {
    "createUpdate": {
      "form": {
        "parent": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Category"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a main category"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a name"])}
        },
        "showInHome": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show In Home"])}
        },
        "referentialAmounts": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referential Rental Amounts"])},
          "pricePerDay": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referential Price Per Day"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100.000"])}
          },
          "deposit": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referential Deposit Required"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100.000"])}
          }
        },
        "baseChecklist": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base Checklist for Delivery/Return"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a type"])}
          },
          "name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a name"])}
          }
        }
      }
    }
  },
  "publications": {
    "actions": {
      "changeState": {
        "publishAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish All"])},
        "pauseAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pause All"])},
        "deactivateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate All"])}
      }
    },
    "itemActions": {
      "labels": {
        "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar"])},
        "faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])}
      }
    },
    "dialogConfirmation": {
      "publishAll": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to activate all publications?"])}
      },
      "pauseAll": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to pause all publications?"])}
      },
      "deactivateAll": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation (This can't be undone!)"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete all publications?"])}
      },
      "deactivate": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation (This can't be undone!)"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to delete the publication ", _interpolate(_named("publicationTitle")), "?"])}
      }
    },
    "messages": {
      "publicationActivated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publication ", _interpolate(_named("publicationTitle")), " was activated"])},
      "publicationDeactivated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publication ", _interpolate(_named("publicationTitle")), " was deactivated"])},
      "publicatitionPaused": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Publication ", _interpolate(_named("publicationTitle")), " was paused"])},
      "noPublicationsChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No publications changed"])}
    },
    "createUpdate": {
      "warningOnTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This publication is active and any change will be published instantly"])},
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the tool"])},
          "form": {
            "title": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the publication title"])}
            },
            "category": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])}
            },
            "description": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a description"])}
            }
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload some pictures from your tool"])},
          "form": {
            "files": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])}
            }
          }
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location and Delivery Conditions"])},
          "form": {
            "offerDelivery": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Delivery?"])}
            },
            "location": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where is the tool?"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start to type to see options"])}
            },
            "postalCode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the postal code"])}
            },
            "availabilityRatio": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability Ratio"])}
            }
          }
        },
        "four": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
          "form": {
            "rent": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
              "price": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
                "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Day"])}
              },
              "offerDiscount": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Discount"])}
              },
              "offerCancelationFree": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer Cancelation Free?"])}
              }
            },
            "discounts": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
              "weekly": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts for 1+ weeks"])}
              },
              "monthly": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts for 1+ months"])}
              }
            },
            "cancelation": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelation"])},
              "daysBeforeRent": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit of Days for Cancelation Free"])}
              }
            },
            "tool": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tool"])},
              "depositAmount": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Required"])}
              }
            },
            "helpSection": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
              "titleWithFeeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit and Service Fee"])},
              "lines": {
                "depositMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Deposit is an amount of money that will be retained to the renter and will be used as secure for you if anything gone wrong at the renting process"])},
                "feeMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["RTR Will Charge the ", _interpolate(_named("fee")), " of the total amount received for the rent"])}
              }
            }
          }
        },
        "five": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist Delivery/Return"])}
        },
        "six": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview and Finish"])}
        }
      }
    },
    "dialogs": {
      "publicationReady": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your tool was published"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your tool ", _interpolate(_named("publicationTitle")), " was published and it is available"])}
      }
    },
    "faqs": {
      "questions": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask to owner"])},
        "form": {
          "question": {
            "placeholderForSignedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your question here..."])},
            "placeholderForUnsignedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, sign-in first..."])}
          }
        },
        "notifications": {
          "newQuestionReceived": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have a new question on publication ", _interpolate(_named("publicationTitle"))])}
          }
        }
      },
      "answers": {
        "filters": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
          "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])}
        },
        "messages": {
          "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer sent"])}
        },
        "notifications": {
          "answerReceived": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have a new answer from publication ", _interpolate(_named("publicationTitle"))])}
          }
        }
      },
      "timeline": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latests questions asked"])},
        "question": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])}
        },
        "answer": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your answer here..."])}
        },
        "dialogs": {
          "deleteConfirmation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation (This can't be undone!)"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this question?"])}
          }
        },
        "messages": {
          "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question deleted"])}
        }
      },
      "checkContentProcess": {
        "dialogs": {
          "content": {
            "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, try again"])},
            "personalInformationDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not suggest to include any personal info on this section"])},
            "badSentimentsDetected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, try again but in a nicer way"])}
          },
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
          }
        },
        "messages": {
          "contents": {
            "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending message, please try again."])}
          }
        }
      }
    },
    "resume": {
      "labels": {
        "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Review"]), _normalize(["Reviews"])])},
        "deliveryTypes": {
          "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be picked up"])},
          "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have Delivery"])}
        },
        "specialOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Offers"])},
        "discounts": {
          "perWeeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 1+ weeks"])},
          "perMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 1+ months"])}
        }
      }
    },
    "footerSection": {
      "mobileView": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publication Details"])}
      }
    },
    "states": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "PAUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paused"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
      "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draft"])}
    }
  },
  "balance": {
    "labels": {
      "currentBalance": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Current Balance: ", _interpolate(_named("currentBalance"))])}
    },
    "actions": {
      "labels": {
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Withdraw"])}
      }
    },
    "itemActions": {
      "labels": {
        "rentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Details"])}
      }
    },
    "dialogs": {
      "withdrawalConfirmation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal Confirmation"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["your current balance is ", _interpolate(_named("currentBalance")), ", do you want a withdrawal?"])}
      },
      "completeYourPayoutProfile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout Profile"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete your payout profile to proceed"])},
        "positiveButton": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Bank Account"])}
        }
      }
    },
    "messages": {
      "withdrawalRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal registered."])},
      "errorRegisteringWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had some problems registering your withdrawal"])},
      "payoutProfileRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment profile registered."])},
      "errorRegisteringPayoutProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had some problems registering your payment profile"])}
    },
    "notifications": {
      "withdrawalRegistered": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal registered"])},
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your withdrawal of ", _interpolate(_named("amount")), " was registered and it is pending to process, we'll let know when is done, Thanks for use RTR"])}
      }
    },
    "status": {
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
      "PROCESSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processed"])},
      "FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
      "RETURNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])}
    },
    "types": {
      "INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income"])},
      "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])}
    }
  },
  "parameters": {
    "createUpdate": {
      "form": {
        "type": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a type"])}
        },
        "name": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a name"])}
        },
        "value": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a value"])}
        }
      }
    }
  },
  "rent": {
    "createUpdate": {
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates and Delivery"])}
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume and Payment"])}
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done!"])}
        },
        "dialogs": {
          "changesRegistered": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes registered"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes in rent was registed sucessfully, please contact the owner for delivery details."])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for Rent with us!"])}
          },
          "paymentDone": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment done"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent payed sucessfully, please contact the owner for delivery details."])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for Rent with us.!"])}
          },
          "maxModificationsReached": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max modification of dates reached"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent reached the max changes of dates permitted, please consider cancel this rent and place a new one if need new dates."])}
          },
          "checkDatesOnlyCanAddDays": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your dates!"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent is in process and only can add days"])}
          },
          "checkDatesPaymentsInProcess": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your dates!"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are still processing the payments and only can add days to your rent at this moments, please select a range of dates with the same days or more, or try again later if you want less days"])}
          },
          "checkDatesNoCancelationFree": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your dates!"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent have no cancelation free, you can only add days or please consider cancel this rent and place new one"])}
          },
          "checkDatesCanChangeDays": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, check your dates!"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At this time you only can change the date preserving the quantity of days"])}
          },
          "paymentError": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Error"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had some problems with payment module"])}
          }
        },
        "messages": {
          "wrongUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent can be modified only by the renter`"])},
          "returnDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent has the return delayed and must be finish it by the owner"])},
          "rentCantBeModified": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This rent is ", _interpolate(_named("status")), " and can't be modified"])}
        },
        "notifications": {
          "rentUpdated": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent updated"])},
            "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Some changes was registered on a rent for the tool ", _interpolate(_named("publicationTitle")), "."])}
          }
        }
      }
    },
    "tabs": {
      "renter": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Renter"])}
      },
      "owner": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As Owner"])}
      }
    },
    "itemActions": {
      "labels": {
        "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
        "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
        "checkDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Delivery"])},
        "checkReturn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check Return"])}
      }
    },
    "detail": {
      "actions": {
        "labels": {
          "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "createIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a Problem"])},
          "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give a Review"])}
        }
      },
      "header": {
        "labels": {
          "rentStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent Status"])},
          "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved"])},
          "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])}
        }
      },
      "ownerInformation": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Information"])},
        "joined": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joined In"])}
        }
      },
      "renterPaymentDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renter Paid"])},
        "labels": {
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Fee"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
        }
      },
      "deposit": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Details"])},
        "labels": {
          "depositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Amount"])}
        }
      },
      "ownerPayoutDetails": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Payout"])},
        "labels": {
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Fee"])},
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payout"])}
        }
      },
      "prices": {
        "labels": {
          "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
          "extraDay": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Extra Day"]), _normalize(["Extra Days"])])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
          "platformFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Fee"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
          "cancelationFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelation Fee"])},
          "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payed"])},
          "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Amount"])},
          "amountToRefund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to Refund"])},
          "pendingBalance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Balance"])},
          "refundableDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refundable Deposit"])},
          "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
          "retainedForDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retained for Damages"])},
          "ownerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Payout"])},
          "totalPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Payout"])},
          "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
          "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse"])}
        }
      }
    },
    "review": {
      "steps": {
        "zero": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How was the tool?"])},
          "form": {
            "content": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])}
            }
          }
        },
        "one": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["How the ", _interpolate(_named("person")), " did it?"])},
          "form": {
            "content": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])}
            }
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done!"])},
          "confirmationDialog": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review Registered"])},
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for share your experience!"])}
          }
        }
      },
      "dialogs": {
        "rentMustBeFinished": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent must be finished"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rent must be finished to write a review"])},
          "positiveButton:": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect to rent"])}
          }
        }
      }
    },
    "rangeOfDates": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rental Date Range"])}
    },
    "resume": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resume"])},
      "labels": {
        "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tool"])},
        "reservedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Dates"])},
        "reservedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Days"])},
        "executedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed Dates"])},
        "executedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed Days"])},
        "extraDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Days"])},
        "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])}
      }
    },
    "checklist": {
      "steps": {
        "one": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures an Videos"])},
          "returnRequiredQuestions": {
            "needChargeDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need to charge any damage from deposit?"])},
            "detailsAboutDamages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details about damage and costs"])}
          }
        },
        "two": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist"])}
        },
        "three": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])}
        }
      },
      "dialogs": {
        "paymentIssues": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Issues"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent has some issues with the payments, please contact our support team to help you"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
          }
        },
        "tooEarly": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too Early..."])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This rent start at ", _interpolate(_named("date")), ". Please, wait till that date"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
          }
        },
        "tooLate": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too Late..."])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent is out of date and will be finished. Don't worry, the money for this rent is still yours!"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish this Rent"])}
          }
        },
        "otherRentInProcess": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other rent in process"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool has other rent in process and must be finished before check the delivery for this rent"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Back"])}
          }
        },
        "returnDelayed": {
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This return is ", _interpolate(_named("qtyDays")), " ", _interpolate(_named("daysLabel")), " delayed, do you want to charge this extra days to the renter?"])}
        },
        "startFinishConfirmation": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("action")), " Rent?"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This action will ", _interpolate(_named("action")), " the rent on the platform and it can't be undone."])},
          "extraContentForRentThatJustStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent just started, if you finish this now, the cost of the rent will be returned to the renter and we will charge you the platform fee."])},
          "extraContentForRentWithRemainingDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Left ", _interpolate(_named("qtyDays")), " ", _interpolate(_named("labelDays")), " for this rent"])},
          "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to continue?"])}
        },
        "rentInWrongState": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rent in state ", _interpolate(_named("state"))])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This rent is in status ", _interpolate(_named("state")), " and it can't modify the checklist at this moment"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See details"])}
          }
        }
      },
      "addNewField": {
        "form": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Field"])},
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
          },
          "name": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a name"])}
          }
        }
      },
      "uploadFile": {
        "dialogs": {
          "fileTypeNotAllowed": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported files type"])}
          }
        },
        "messages": {
          "errorDeletingFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting file, please try again"])}
        }
      },
      "resume": {
        "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("checklistType")), " Checklist Summary"])}
      },
      "notifications": {
        "paymentReverted": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reverted"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your payment for the rent ", _interpolate(_named("publicationTitle")), " has been reverted, we apologize for the inconvenience caused."])}
        },
        "paymentRequired": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment required"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There is ", _interpolate(_named("qytDays")), " ", _interpolate(_named("daysLabel")), " pending to pay for the delayed return or the tool ", _interpolate(_named("publicationTitle")), ". Please, visit the following link to process to pay.\n If this is an error, please, ask for help to our assistants in the same link without execute the payment."])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay here!"])}
        },
        "requestReview": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share your experience with others"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The rent for the tool ", _interpolate(_named("publicationTitle")), " is done now, please, share with others your experiences with this service."])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to make a review"])}
        },
        "rentFinished": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your rent has been finished"])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your rent for ", _interpolate(_named("publicationTitle")), " has been finished, Thanks for use RTR"])}
        },
        "rentStartedFinished": {
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your rent for ", _interpolate(_named("publicationTitle")), " has been ", _interpolate(_named("action"))])},
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your rent for ", _interpolate(_named("publicationTitle")), " has been ", _interpolate(_named("action")), ", please check the tool is according with this checklist and contact the owner if there is any difference"])}
        },
        "depositIssue": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit issue"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had some issues processing your deposit transaction. Please, provide a new payment method for deposit."])},
          "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make deposit here!"])}
        }
      },
      "messages": {
        "renStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent started"])},
        "rentFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent finished"])},
        "rentFinishedWithPaymentRequested": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("rent.checklist.messagges.rentFinished", undefined, _type), " and payment requested"])},
        "errorUpdatingStatus": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["We had some problems, the rent is in status ", _interpolate(_named("status"))])},
        "issueCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue created to manage the damage costs"])},
        "errorCreatingIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error creating issue. Please contact our assistant team to manage the damage costs"])},
        "errorCapturingPayment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Capture fail, rent in state ", _interpolate(_named("state"))])},
        "errorHoldingDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error holding deposit"])}
      },
      "others": {
        "issueSubjectWhenDamagesNoticed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damages on return"])}
      }
    },
    "cancelation": {
      "notificationOfCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rent has not free cancelation, if you want to cancel it, we will not make any refund."])},
      "details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Detail"])},
        "labels": {
          "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payed"])},
          "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelation Fee"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
          "refundAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund Amount"])}
        }
      },
      "confirmationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to continue?"])},
      "dialogs": {
        "cancelationConfirmation": {
          "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The rent for the tool ", _interpolate(_named("publicationTitle")), " was canceled."])}
        }
      },
      "messages": {
        "errorCanceling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error canceling this rent, please contact our assistants team"])}
      }
    },
    "deliveryInformation": {
      "warnings": {
        "deliveryOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery selected is out of range"])}
      },
      "labels": {
        "pickAt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pick at ", _interpolate(_named("location"))])}
      }
    },
    "states": {
      "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "IN_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Process"])},
      "RETURNED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returned"])},
      "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
      "ARBITRATION_IN_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitration In Process"])},
      "LOST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lost"])},
      "PENDING_FOR_PAY_EXTRA_DAYS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending For Pay Extra Days"])},
      "PENDING_FOR_CHANGE_PAYMENT_METHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending For Change Payment Method"])},
      "DEPOSIT_PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Pending"])},
      "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])}
    }
  },
  "issues": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issues"])},
    "buttonTakeIssue": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Issue"])}
    },
    "detail": {
      "tabs": {
        "issue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
          "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Rent Issue for publication: ", _interpolate(_named("publicationTitle"))])},
          "information": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
            "labels": {
              "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
              "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
              "previousRentState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Rent State"])},
              "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])}
            }
          },
          "usersInformation": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
            "labels": {
              "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
              "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
              "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
              "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])}
            }
          },
          "evidences": {
            "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Attachment"]), _normalize(["Attachments"])])},
            "from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uploaded at: ", _interpolate(_named("date"))])},
            "by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By ", _interpolate(_named("user"))])},
            "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files"])},
            "noEvidences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Files attached"])}
          },
          "transactions": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
            "labels": {
              "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
              "charge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge"])},
              "registeredAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered at"])},
              "reverseTypes": {
                "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse"])},
                "refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund"])}
              }
            }
          },
          "resolution": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolution"])},
            "labels": {
              "messageToUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message to users"])},
              "relatedTransactionId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related transaction id"])}
            },
            "messages": {
              "noResolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is not a resolution"])}
            }
          }
        },
        "rent": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])}
        },
        "actions": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "form": {
            "summary": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe a brief summary of case and its resolution (only for internal use)"])},
              "validation": {
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please describe a brief summary of case and its resolution"])}
              }
            },
            "message": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input a notification message for reporter"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The help related to your rent has been finished"])},
              "validation": {
                "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please input a notify message for clients"])}
              }
            }
          },
          "buttonResolveIssue": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve Issue"])}
          },
          "cancelRent": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel a Rent"])},
            "form": {
              "confirmationReverseTransactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reverse the transactions associated to this rent?"])},
              "confirmationReverseDeposits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to reverse the deposit associated to this rent?"])}
            }
          },
          "changeDates": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Dates"])},
            "notifications": {
              "depositIssue": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Issue"])},
                "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We had some issues processing your rent transaction. Please, provide a new payment method for rent."])},
                "linkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Rent Here!"])}
              }
            },
            "dialogs": {
              "noTransactionsFound": {
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not available transactions found!!!"])},
                "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not available transactions to process a refund"])}
              }
            }
          },
          "suspectedStolenTool": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspected Stolen Tool"])},
            "form": {
              "confirmationProcessDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to process the deposit transaction associate to this rent? (Deposit Amount: ", _interpolate(_named("depositAmount")), ")"])}
            }
          },
          "disagreementClosing": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagreement at Closing"])},
            "form": {
              "confirmationProcessDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to process the deposit transaction associated to this rent? (Deposit Amount: ", _interpolate(_named("depositAmount")), ")"])},
              "confirmationReverseDeposit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want to reverse the deposit transaction associated to this rent? (Deposit Amount: ", _interpolate(_named("depositAmount")), ")"])},
              "confirmationProcessPartialDepositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to process a partial amount from the deposit transaction associated to this rent?"])},
              "confirmationRefundPartialDepositAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to refund a partial amount from the deposit transaction associate to this rent?"])},
              "amountToRefund": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to refund"])}
              }
            },
            "messages": {
              "noDepositPayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renter didn't pay the deposit"])},
              "refundQueued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refund transaction has been submit to queue"])}
            }
          },
          "exceptionPaymentAdditionalDays": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exception Payment Additional Days"])},
            "form": {
              "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to skip payment for extra days?"])}
            }
          },
          "disagreementOnReturnChecklist": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disagreement on Return Checklist"])}
          },
          "messages": {
            "noActionsInThisStates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This resolution case is only for rents in this states: ", _interpolate(_named("states"))])},
            "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issue has been resolved successfully"])}
          }
        }
      }
    },
    "create": {
      "form": {
        "subject": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Subject"])}
        },
        "description": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input Description"])}
        }
      },
      "dialogs": {
        "wantToAddEvidences": {
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add evidence and/or upload files?"])}
        },
        "issueCreated": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue submitted"])},
          "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issue was submitted, please wait for a response from our team"])},
          "extra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are not files attached to this issue"])},
          "positiveButton": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirect to rent"])}
          }
        }
      }
    }
  },
  "locationDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "form": {
      "address": {
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start to type to see options"])}
      },
      "postalCode": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input the postal code"])}
      },
      "availabilityRatio": {
        "label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Availability Ratio: ", _interpolate(_named("ratio")), " ", _interpolate(_named("unit"))])}
      }
    }
  },
  "aboutUser": {
    "labels": {
      "joinedIn": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Joined in ", _interpolate(_named("date"))])},
      "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Review"]), _normalize(["Reviews"])])}
    }
  },
  "statsAndCalendar": {
    "labels": {
      "rentedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rented Days"])},
      "reservedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserved Days"])},
      "maintenanceDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance Days"])},
      "delayedDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delayed Days"])}
    },
    "dialogs": {
      "confirmMaintenanceDone": {
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mark as done the maintenance scheduled from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), "?"])}
      },
      "confirmCancelMaintenance": {
        "content": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancel the maintenance scheduled from ", _interpolate(_named("from")), " to ", _interpolate(_named("to")), "?"])}
      }
    },
    "form": {
      "placeholders": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Range of Dates"])}
      }
    }
  },
  "chat": {
    "errors": {
      "connectionClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection closed, please enter again"])}
    },
    "notifications": {
      "newMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["New Message from ", _interpolate(_named("user"))])},
      "fileReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File received"])}
    }
  },
  "commons": {
    "labels": {
      "information": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Information"]), _normalize(["Informations"])])},
      "state": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["State"]), _normalize(["States"])])},
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main"])},
      "category": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Category"]), _normalize(["Categories"])])},
      "field": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Field"]), _normalize(["Fields"])])},
      "address": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Address"]), _normalize(["Addresses"])])},
      "photo": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Photo"]), _normalize(["Photos"])])},
      "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["New ", _interpolate(_named("reference"))])},
      "type": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Type"]), _normalize(["Types"])])},
      "name": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Name"]), _normalize(["Names"])])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
      "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
      "checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
      "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
      "notification": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Notification"]), _normalize(["Notifications"])])},
      "review": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Review"]), _normalize(["Reviews"])])},
      "payment": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Payment"]), _normalize(["Payments"])])},
      "worldwide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Worldwide"])},
      "action": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Action"]), _normalize(["Actions"])])},
      "filter": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Filter"]), _normalize(["Filters"])])},
      "file": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["File"]), _normalize(["Files"])])},
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
      "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
      "parameter": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Parameter"]), _normalize(["Parameters"])])},
      "publication": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Publication"]), _normalize(["Publications"])])},
      "profit": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Profit"]), _normalize(["Profits"])])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "maintenance": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Maintenance"]), _normalize(["Maintenances"])])},
      "chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
      "issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
      "cancelation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelation"])},
      "detail": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Detail"]), _normalize(["Details"])])},
      "unread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unread"])},
      "setting": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Setting"]), _normalize(["Settings"])])},
      "rent": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Rent"]), _normalize(["Rents"])])},
      "code": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Code"]), _normalize(["Codes"])])},
      "account": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Account"]), _normalize(["Accounts"])])},
      "email": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Email"]), _normalize(["Emails"])])},
      "password": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Password"]), _normalize(["Passwords"])])},
      "description": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Description"]), _normalize(["Descriptions"])])},
      "term": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Term"]), _normalize(["Terms"])])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
      "day": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["No Days"]), _normalize(["Day"]), _normalize(["Days"])])},
      "date": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["No Dates"]), _normalize(["Date"]), _normalize(["Dates"])])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
      "user": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["User"]), _normalize(["Users"])])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
      "value": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Value"]), _normalize(["Values"])])},
      "amount": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Amount"]), _normalize(["Amounts"])])}
    },
    "form": {
      "validation": {
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field required"])},
        "invalidFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid value"])},
        "rangeOfDatesRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, select a range of dates"])},
        "maxSelectedDaysReached": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The selected days can't be greater than ", _interpolate(_named("maxDays"))])},
        "fileTypeNotAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File type not allowed"])},
        "alreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already Exists"])},
        "valueMustBeThis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Value must be ", _interpolate(_named("reference"))])},
        "valueOutOfRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value out of range"])}
      }
    },
    "lists": {
      "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])},
      "noContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Content"])}
    },
    "actions": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "add": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Add ", _interpolate(_named("reference"))])},
      "save": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Save ", _interpolate(_named("reference"))])},
      "search": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Search ", _interpolate(_named("reference"))])},
      "accept": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accept ", _interpolate(_named("reference"))])},
      "cancel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancel ", _interpolate(_named("reference"))])},
      "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create ", _interpolate(_named("reference"))])},
      "update": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Update ", _interpolate(_named("reference"))])},
      "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Delete ", _interpolate(_named("reference"))])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
      "start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start ", _interpolate(_named("reference"))])},
      "finish": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Finish ", _interpolate(_named("reference"))])},
      "started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started"])},
      "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
      "viewDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["View ", _interpolate(_named("reference")), " Details"])},
      "resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resolve"])},
      "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
      "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload ", _interpolate(_named("reference"))])},
      "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
      "select": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Select ", _interpolate(_named("reference"))])},
      "send": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Send ", _interpolate(_named("reference"))])},
      "publish": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["List ", _interpolate(_named("reference"))])},
      "pay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pay ", _interpolate(_named("reference"))])},
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "confirmed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Confirmed"])},
      "saved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Saved"])},
      "created": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Created"])},
      "updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Updated"])},
      "change": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Change ", _interpolate(_named("reference"))])},
      "changed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Changed"])},
      "check": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check ", _interpolate(_named("referece"))])},
      "registered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Registered"])},
      "canceled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Canceled"])},
      "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
      "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])}
    },
    "confirmation": {
      "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
      "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "done": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("reference")), " Done"])},
      "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])}
    },
    "errors": {
      "creating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error creating ", _interpolate(_named("reference"))])},
      "saving": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error saving ", _interpolate(_named("reference"))])},
      "updating": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error creating ", _interpolate(_named("reference"))])},
      "bad": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bad ", _interpolate(_named("reference"))])}
    },
    "calendar": {
      "placeholders": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a Range of Dates"])}
      }
    },
    "persons": {
      "renter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renter"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
      "allMale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "allFemale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
    },
    "financial": {
      "balance": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Balance"]), _normalize(["Balances"])])},
      "transaction": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Transaction"]), _normalize(["Transactions"])])},
      "charge": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Charge"]), _normalize(["Charges"])])},
      "currencySymbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
    },
    "distances": {
      "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mi"])}
    },
    "inputTypes": {
      "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
      "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes/No"])},
      "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])}
    }
  }
}