// https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern <-- sacar los enlaces desde este enlace
const fileMimeTypes = [
    {
      name: 'jpeg',
      mime: 'image/jpeg',
      pattern: [0xFF, 0xD8, 0xFF],
      mask: [0xFF, 0xFF, 0xFF],
    },
    {
      name: 'png',
      mime: 'image/png',
      pattern: [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A],
      mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
    },
    {
      name: 'pdf',
      mime: 'application/pdf',
      pattern: [0x25, 0x50, 0x44, 0x46, 0x2D],
      mask: [0xFF, 0xFF, 0xFF, 0xFF, 0xFF],
    }
  ]
  
const checkFileMimeTypesFromBytes = (bytes, allowedTypes) => {
  const allowedTypesJson = [];

  allowedTypes.forEach(element => {
    const item = fileMimeTypes.find(x => x.name === element)
    if (item) {
      allowedTypesJson.push(item);
    }
  });

  return allowedTypesJson.some((mime) => {
      for (var i = 0, l = mime.mask.length; i < l; ++i) {
          if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
              return false;
          }
      }
      return true;
  });
}

export {
    checkFileMimeTypesFromBytes
}