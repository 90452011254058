import { createStore } from "vuex";
import { auth } from "./module/auth";
import { shared } from "./module/shared";
import { maintainer_category } from "./module/maintainers/category";
import { maintainer_parameters } from "./module/maintainers/parameter";
import { maintainer_publication } from "./module/maintainers/publication";
import { maintainer_rents } from "./module/maintainers/rent";
import { maintainer_balance } from "./module/maintainers/balance";
import { user } from "./module/user";
import { searcher } from "./module/search";
import { app } from "./module/app";
import { payments } from "./module/payments";
import { rent } from "./module/rent";
import { notifications } from "./module/notifications";
import { chat } from "./module/chat";
import { questions } from "./module/questions";
import { issues } from "./module/issues";
import { review } from "./module/review";
import { home } from "./module/home";
import { verifyId } from "./module/verifyId";

export default createStore({
  modules: {
    app,
    auth,
    shared,
    maintainer_category,
    maintainer_parameters,
    maintainer_publication,
    searcher,
    user,
    payments,
    rent,
    maintainer_rents,
    maintainer_balance,
    notifications,
    chat,
    questions,
    issues,
    review,
    home,
    verifyId,
  },
});
