import { API, graphqlOperation } from "aws-amplify";
import { updateToolRent } from "@/graphql/custom/mutations";
import { getToolRentDetailToContinue, listToolRentsForMaintainer, listToolRentsForVaultManagerAvailability } from "@/graphql/custom/queries";
import { parseStringToDate, getTaxRate, quantityDaysInRange }  from "@/shared/utils";
import { addDays, differenceInCalendarDays } from "date-fns";
import * as Big from "js-big-decimal"

export const rent = {
    namespaced: true,
    actions: {
        async getRentById({ dispatch, rootGetters }, id ) {
            const rent_raw = await API.graphql({...graphqlOperation(getToolRentDetailToContinue, {id: id, user: rootGetters['auth/user_id']}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const rent = rent_raw.data.getToolRent
            if (rent.requestedRangeOfDates){
                rent.requestedRangeOfDates = {
                    start: parseStringToDate(rent.requestedRangeOfDates.start),
                    end: parseStringToDate(rent.requestedRangeOfDates.end),
                }
            }
            if (rent.executedRangeOfDates){
                rent.executedRangeOfDates = {
                    start: parseStringToDate(rent.executedRangeOfDates.start),
                    end: rent.executedRangeOfDates.end ? parseStringToDate(rent.executedRangeOfDates.end) : null,
                }
            }

            const {paymentsInfo: updatedPaymentsInfo, _version: updatedVersion} = await dispatch("refreshPaymentsStatus", rent)
            rent.paymentsInfo = updatedPaymentsInfo
            rent._version = updatedVersion

            const publication = await dispatch('maintainer_publication/parseDates', rent.publication, { root: true });
            
            rent.publication = publication;
            
            return rent
           
        },
        async updateRequestedRangeOfDates(_context, rent ) {
            const toolRentUpdated = await API.graphql({...graphqlOperation(
                updateToolRent, 
                {input: 
                    { 
                        id: rent.id, 
                        requestedRangeOfDates: rent.requestedRangeOfDates, 
                        requestedDays: rent.requestedDays,
                        pricePerDayDiscounted: rent.pricePerDayDiscounted,
                        pricePerDay: rent.pricePerDay,
                        baseCost: rent.baseCost,
                        discount: rent.discount,
                        subtotal: rent.subtotal,
                        renterServiceFee: rent.renterServiceFee,
                        ownerServiceFee: rent.ownerServiceFee,
                        tax: rent.tax,
                        totalCost: rent.totalCost,
                        totalPayed: rent.totalPayed,
                        pendingToPay: rent.pendingToPay,
                        taxOfPendingToPay: rent.taxOfPendingToPay,
                        extraDaysCost: rent.extraDaysCost,
                        _version: rent._version,
                        rangeOfDatesHistory: [
                          ...rent.rangeOfDatesHistory,
                          {
                            correlative: rent.rangeOfDatesHistory.length + 1,
                            range: rent.requestedRangeOfDates,
                            type: "MODIFICATION",
                            datetime: new Date(),
                            totalDays: rent.requestedDays,
                          }
                        ]
                    }
                }), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            return {
                ...toolRentUpdated.data.updateToolRent, 
                requestedRangeOfDates : {
                    start: parseStringToDate(toolRentUpdated.data.updateToolRent.requestedRangeOfDates.start),
                    end: parseStringToDate(toolRentUpdated.data.updateToolRent.requestedRangeOfDates.end),
                }
            };
        },
        async loadRatesAndConfig({ rootState }, {rent, publication}){
          
          rent.taxRate = rent.taxRate ? rent.taxRate : await getTaxRate(rent.publication?.locationInfo || publication?.locationInfo);
          rent.renterServiceFeeRate = rent.renterServiceFeeRate ? rent.renterServiceFeeRate : rootState.app.configuration.SERVICE_FEE_RENTER || 0
          rent.ownerServiceFeeRate = rent.ownerServiceFeeRate ? rent.ownerServiceFeeRate : rootState.app.configuration.SERVICE_FEE_OWNER || 0
          rent.limitDaysForCancelationFree = rent.limitDaysForCancelationFree ? rent.limitDaysForCancelationFree : publication.prices.offerCancelationFree ? publication.prices.limitDaysForCancelationFree : 999999

          return rent
        },
        async calculateTotals({ rootState, dispatch }, {rent, originalRangeOfDates, publication}) {

            // Se cargan los rates a utilizar en calculos
            rent = await dispatch("loadRatesAndConfig", {rent, publication})

            // Transformacion a bigDecimal para presicion en calculos
            rent.pricePerDay = new Big(rent.pricePerDay)
            rent.pricePerDayDiscounted = new Big(rent.pricePerDayDiscounted)
            rent.requestedDays = new Big(rent.requestedDays)
            rent.executedDays = new Big(rent.executedDays)
            rent.taxRate = new Big(rent.taxRate)
            rent.renterServiceFeeRate = new Big(rent.renterServiceFeeRate)
            rent.ownerServiceFeeRate = new Big(rent.ownerServiceFeeRate)
            // Se obtene el factor de moneda y se factoriza para mejorar precision en calculos
            const CURRENCY_FACTOR = new Big(rootState.app.configuration.CURRENCY_FACTOR || 1)
            const CURRENCY_PRECISION = rootState.app.configuration.CURRENCY_PRECISION || 0
            rent.pricePerDay = rent.pricePerDay.multiply(CURRENCY_FACTOR)
            rent.pricePerDayDiscounted = rent.pricePerDayDiscounted.multiply(CURRENCY_FACTOR)

            // Constante reutilizable durante el proceso de calculo
            const ZERO = new Big(0)
            const ONE = new Big(1)

            // Days with discount (all except days by delayed return)
            // Must revert bigDecimal before return
            rent.daysWithDiscount =
              rent.requestedDays.subtract(
              (rent.rangeOfDatesHistory
                ? new Big(rent.rangeOfDatesHistory
                    .filter((entry) => entry.type === "DELAYED_RETURN")
                    .reduce((partial, entry) => partial + entry.totalDays, 0))
                : ZERO));
    
            // Get amounts -----------------------------------------------------------------------

            // Must revert bigDecimal before return
            rent.baseCost = rent.pricePerDay.multiply(rent.requestedDays);

            // Must revert bigDecimal before return
            rent.discount =
              rent.pricePerDayDiscounted.compareTo(ZERO) > 0 && rent.daysWithDiscount.compareTo(ZERO) > 0
                ? rent.pricePerDay.subtract(rent.pricePerDayDiscounted).multiply(rent.daysWithDiscount)
                : ZERO;

            // Must revert bigDecimal before return
            rent.subtotal = rent.baseCost.subtract(rent.discount);
            
            if (rent.executedDays.compareTo(rent.requestedDays) > 0){
              // Must revert bigDecimal before return
              rent.extraDaysCost = rent.executedDays.subtract(rent.requestedDays).multiply(rent.pricePerDay);
              rent.subtotal = rent.subtotal.add(rent.extraDaysCost)
            }
            
            // Must revert bigDecimal before return
            rent.renterServiceFee =  rent.subtotal.multiply(rent.renterServiceFeeRate).round();
            // Must revert bigDecimal before return
            rent.ownerServiceFee =  rent.subtotal.multiply(rent.ownerServiceFeeRate).round();
            // Must revert bigDecimal before return
            rent.tax = rent.subtotal.add(rent.renterServiceFee).multiply(rent.taxRate).round();
            // Must revert bigDecimal before return
            rent.totalCost = rent.subtotal.add(rent.renterServiceFee).add(rent.tax);
            // Must revert bigDecimal before return
            rent.totalPayed = rent.paymentsInfo
              ? rent.paymentsInfo.filter((payment) => ["PENDING", "AUTHORIZED", "SUBMITTED_FOR_SETTLEMENT", "SETTLING", "SETTLED"].includes(payment.status)).reduce(
                  (partial, payment) => partial.add(new Big(payment.balance).multiply(CURRENCY_FACTOR)),
                  ZERO
                )
              : ZERO;
            
            // Must revert bigDecimal before return
            console.debug("rent.totalCost", rent.totalCost);
            console.debug("rent.totalPayed", rent.totalPayed);
            rent.pendingToPay = rent.totalCost.subtract(rent.totalPayed);
            console.debug("rent.pendingToPay", rent.pendingToPay);
            // Must revert bigDecimal before return
            rent.taxOfPendingToPay = rent.pendingToPay.subtract(rent.pendingToPay.divide(rent.taxRate.add(ONE)));
          
            // Generacion de historial de rango de dias que se estan pagando al calcular estos montos
            if (rent.state === "UNPAID") {
                rent.rangeOfDatesPaying = {
                  correlative: 1,
                  range: rent.requestedRangeOfDates,
                  type: "INITIAL",
                  datetime: new Date(),
                  totalDays: quantityDaysInRange(
                    rent.requestedRangeOfDates.start,
                    rent.requestedRangeOfDates.end
                  ),
                };
            } else if (rent.state === "IN_PROCESS") {
              rent.rangeOfDatesPaying =  {
                correlative: rent.rangeOfDatesHistory.length + 1,
                range: {
                  start: addDays(originalRangeOfDates.end, 1),
                  end: rent.requestedRangeOfDates.end,
                },
                type: "EXTENSION",
                datetime: new Date(),
                totalDays: quantityDaysInRange(
                  addDays(originalRangeOfDates.end, 1),
                  rent.requestedRangeOfDates.end
                ),
              };
            } else if (rent.state === "PENDING") {
              rent.rangeOfDatesPaying =  {
                correlative: rent.rangeOfDatesHistory.length + 1,
                range: rent.requestedRangeOfDates,
                type: "MODIFICATION",
                datetime: new Date(),
                totalDays: quantityDaysInRange(
                  rent.requestedRangeOfDates.start,
                  rent.requestedRangeOfDates.end
                ),
              };
            } else if (rent.state === "PENDING_FOR_PAY_EXTRA_DAYS") {
              rent.rangeOfDatesPaying = {
                correlative: rent.rangeOfDatesHistory.length + 1,
                range: {
                  start: addDays(rent.requestedRangeOfDates.end, 1),
                  end: rent.executedRangeOfDates.end,
                },
                type: "DELAYED_RETURN",
                datetime: new Date(),
                totalDays: quantityDaysInRange(
                  addDays(rent.requestedRangeOfDates.end, 1),
                  rent.requestedRangeOfDates.end
                ),
              };
            }
            
            rent.requestedDays = parseInt(rent.requestedDays.getValue())
            rent.executedDays = parseInt(rent.executedDays.getValue())
            rent.daysWithDiscount = parseInt(rent.daysWithDiscount.getValue())
            
            // Se reversa el factor de la moneda en los montos y se redondea con la precision configurada
            rent.pricePerDay = parseFloat(rent.pricePerDay.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.pricePerDayDiscounted = parseFloat(rent.pricePerDayDiscounted.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.baseCost = parseFloat(rent.baseCost.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.discount = parseFloat(rent.discount.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.subtotal = parseFloat(rent.subtotal.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.renterServiceFee = parseFloat(rent.renterServiceFee.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.ownerServiceFee = parseFloat(rent.ownerServiceFee.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.tax = parseFloat(rent.tax.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.totalCost = parseFloat(rent.totalCost.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.totalPayed = parseFloat(rent.totalPayed.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.pendingToPay = parseFloat(rent.pendingToPay.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            rent.taxOfPendingToPay = parseFloat(rent.taxOfPendingToPay.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            if (rent.extraDaysCost){
              rent.extraDaysCost = parseFloat(rent.extraDaysCost.divide(CURRENCY_FACTOR).round(CURRENCY_PRECISION).getValue())
            }
            
            rent.taxRate = parseFloat(rent.taxRate.getValue())
            rent.renterServiceFeeRate = parseFloat(rent.renterServiceFeeRate.getValue())
            rent.ownerServiceFeeRate = parseFloat(rent.ownerServiceFeeRate.getValue())


            return rent
        },
        async refreshPaymentsStatus({dispatch}, rent){
          console.debug("refreshPaymentsStatus - started", rent)
          let mustUpdate = false
          for (const payment of rent.paymentsInfo){
            console.debug("payment.status", payment.status)
            if (payment.status === "SUBMITTED_FOR_SETTLEMENT"){
              console.debug(" is SUBMITTED_FOR_SETTLEMENT, checking current status in processor")
              const transactionInfo = await dispatch("payments/getTransactionInfo", payment.transactionId, { root: true })
              console.debug("transactionInfo", transactionInfo)
              payment.status = transactionInfo.status
              if (payment.status !== "SUBMITTED_FOR_SETTLEMENT" && !mustUpdate){
                mustUpdate = true
              } 
            }
          }

          if (mustUpdate){
            console.debug("Updating rent version "+rent._version+": ", rent)
            const toolRentUpdated = await API.graphql({...graphqlOperation(
              updateToolRent, 
              {input: 
                  { 
                      id: rent.id, 
                      paymentsInfo: rent.paymentsInfo, 
                      _version: rent._version,
                  }
              }), authMode: 'AMAZON_COGNITO_USER_POOLS'});

              rent._version = toolRentUpdated.data.updateToolRent._version
              console.debug("new version: "+rent._version)
            
          }
          console.debug("refreshPaymentsStatus - end", rent)
          return rent
        },
        async isCancelationFree(_context, {rent, publication}){

          const daysBeforeStartRent = differenceInCalendarDays(
            rent.requestedRangeOfDates.start,
            new Date()
          );
          
          const limitDays = rent.limitDaysForCancelationFree || (publication.prices.offerCancelationFree ? publication.prices.limitDaysForCancelationFree : 999999)
          console.debug("daysBeforeStartRent", daysBeforeStartRent)
          console.debug("rent.limitDaysForCancelationFree", rent.limitDaysForCancelationFree)
          console.debug("publication.prices.limitDaysForCancelationFree", publication.prices.offerCancelationFree ? publication.prices.limitDaysForCancelationFree : 999999)
          console.debug("limitDays", limitDays)
          
          return daysBeforeStartRent > limitDays

        },
        async isOtherRentInProcess(_context, {rentId, publicationId}){
          const rents_raw = await API.graphql(
            {
              ...graphqlOperation(
                listToolRentsForMaintainer, 
                { 
                  filter: {
                    id: {
                      ne: rentId
                    }, 
                    publicationID: { 
                      eq: publicationId
                    },
                    state: { 
                      eq: 'IN_PROCESS' 
                    }
                  }
                }
              ),
              authMode: 'AMAZON_COGNITO_USER_POOLS'
            }
          );
          
          return rents_raw.data.listToolRents.items.length > 0
        },
        async updateRent(_context, rent) {
          const toolRentUpdated = await API.graphql({...graphqlOperation(
              updateToolRent, 
              {input: rent
              }), authMode: 'AMAZON_COGNITO_USER_POOLS'});
          return toolRentUpdated.data.updateToolRent;
        },
        async getRentsThatBlockVault({ rootGetters } ) {

          const filter = {
            "and": [
                {
                    "user": {
                        "eq": rootGetters['auth/user_id']
                    }
                },
                {
                    "or": [
                        {
                            "state": {
                                "eq": "PENDING"
                            }
                        },
                        {
                            "state": {
                                "eq": "IN_PROCESS"
                            }
                        },
                        {
                            "state": {
                                "eq": "ARBITRATION_IN_PROCESS"
                            }
                        },
                        {
                            "state": {
                                "eq": "PENDING_FOR_PAY_EXTRA_DAYS"
                            }
                        },
                        {
                            "state": {
                                "eq": "PENDING_FOR_CHANGE_PAYMENT_METHOD"
                            }
                        },
                        {
                            "state": {
                                "eq": "DEPOSIT_PENDING"
                            }
                        }
                    ]
                }
            ]
          }

          const rents_raw = await API.graphql({...graphqlOperation(listToolRentsForVaultManagerAvailability, filter), authMode: 'AMAZON_COGNITO_USER_POOLS'});
          return rents_raw.data.listToolRents.items
         
        },
        async modifyPaymentInRent(context, {rent, payment}){
          
            const toolRentUpdated = await API.graphql({...graphqlOperation(
              updateToolRent, 
              { input: 
                  { 
                      id: rent.id, 
                      totalPayed: payment.amount,
                      paymentsInfo: rent.paymentsInfo.map((paymentInfo) => paymentInfo.bin === payment.bin && paymentInfo.cardType === payment.cardType && paymentInfo.lastFour === payment.lastFour ? payment : paymentInfo), 
                      _version: rent._version,
                  }
              }
            ), authMode: 'AMAZON_COGNITO_USER_POOLS'});
           
            return toolRentUpdated.data.updateToolRent
            
        }
      }
    }
