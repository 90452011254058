import { API, graphqlOperation } from "aws-amplify";
import { listToolRentsForMaintainer, getToolRentDetail } from "../../../graphql/custom/queries";
import { parseStringToDate, getFileNameFromKey, quantityDaysInRange, removeAttrs }  from "@/shared/utils";
import {  updateChatRoomInToolRent, updateChecklistInToolRent, updateToolRent  } from "../../../graphql/custom/mutations";

export const maintainer_rents = {
    namespaced: true,
    state: () => ({
        rents: [],
        detail: null,
        balanceTransactions: []
    }),
    getters: {
    },
    mutations: {
        fillRents: (state, rents) =>  state.rents = rents,
        fillBalanceTransactions: (state, transactions) =>  state.balanceTransactions = transactions,
        setDetail: (state, detail) =>  state.detail = detail,
        setCancelationFee: (state, {cancelationFee, cancelationTax}) => { 
            state.detail.cancelationFee = cancelationFee;
            state.detail.cancelationTax = cancelationTax
        },
        setTaxRate: (state, taxRate) => state.detail.taxRate = taxRate
    },
    actions: {
        async fillRents({ commit }, userId) {
            const rents_raw = await API.graphql({...graphqlOperation(listToolRentsForMaintainer, { filter: { relatedUsers: { contains: userId}, state: { ne: 'UNPAID' } }}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('fillRents', rents_raw.data.listToolRents.items);
        },
        async loadDetail({ commit, dispatch }, { id, userId} ) {
            const rent_raw = await API.graphql({...graphqlOperation(getToolRentDetail, {id: id, user: userId}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const rent = rent_raw.data.getToolRent
            if (rent.requestedRangeOfDates){
                rent.requestedRangeOfDates = {
                    start: parseStringToDate(rent.requestedRangeOfDates.start),
                    end: parseStringToDate(rent.requestedRangeOfDates.end),
                }
            }
            if (rent.executedRangeOfDates){
                rent.executedRangeOfDates = {
                    start: parseStringToDate(rent.executedRangeOfDates.start),
                    end: rent.executedRangeOfDates.end ? parseStringToDate(rent.executedRangeOfDates.end) : null,
                }
            }
            const { paymentsInfo: updatedPaymentsInfo, _version: updatedVersion } = await dispatch("rent/refreshPaymentsStatus", rent, { root: true })
            rent.paymentsInfo = updatedPaymentsInfo
            rent._version = updatedVersion
            
            const publication = await dispatch('maintainer_publication/parseDates', rent.publication, { root: true });
            
            rent.publication = publication;
            
            commit('setDetail', rent)

            return rent;
           
        },
        async unloadDetail({ commit } ) {
            console.debug("unloadDetail - started")
            commit('setDetail', null)  
            console.debug("unloadDetail - end")          
        },
        async setChatRoom({state, commit}, chatRoomId){
            if (!state.detail){
                throw Error('No detail loaded')
            }

            const rentUpdated = await API.graphql({...graphqlOperation(updateChatRoomInToolRent, {input: {id: state.detail.id, chatRoomId: chatRoomId, _version: state.detail._version}})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('setDetail', {...state.detail, chatRoomId: rentUpdated.data.updateToolRent.chatRoomId, _version: rentUpdated.data.updateToolRent._version})
                                                            
        },
        async setRentPendingForDeposit({state, commit}){
            if (!state.detail){
                throw Error('No detail loaded')
            }
            if (state.detail.state != "IN_PROCESS"){
                throw Error('Wrong current status')
            }
            const rentUpdated = await API.graphql({...graphqlOperation(updateChecklistInToolRent, {input: {id: state.detail.id, state:"DEPOSIT_PENDING", executedRangeOfDates: null, _version: state.detail._version}})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('setDetail', {...state.detail, state: rentUpdated.data.updateToolRent.state, _version: rentUpdated.data.updateToolRent._version})                                                  
        },
        async setRentReturned({state, commit, dispatch}, {checklist, chargeExtraDays}){
            console.debug("setRentReturned - started", state.detail)
            if (!state.detail){
                throw Error('No detail loaded')
            }
            if (state.detail.state != "IN_PROCESS"){
                throw Error('Wrong current status')
            }

            const input = {
                id: state.detail.id,
                returnCheckList: checklist.map((checklistEntry) => removeAttrs(checklistEntry,['ref', 'hidden'])).filter((checklistEntry) => checklistEntry.value !== null),
                state:"RETURNED",
                executedRangeOfDates: {
                    start: state.detail.executedRangeOfDates.start, 
                    end: new Date() 
                }, 
                executedDays: quantityDaysInRange(state.detail.executedRangeOfDates.start, chargeExtraDays ? new Date() : state.detail.requestedRangeOfDates.end), 
                _version: state.detail._version
            }

            const profitInfo = {
                description: `Profit for rent of tool ${state.detail.publication.title}`, 
                amount: state.detail.subtotal - state.detail.ownerServiceFee, 
                user: state.detail.publication.user,
                rentId: state.detail.id
            }

            const taskResults = await Promise.all(
                [
                    API.graphql({...graphqlOperation(updateChecklistInToolRent, {input: input})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'}),
                    dispatch("maintainer_balance/addProfitTransactionToBalance", profitInfo, {root: true})
                ]
            );
            console.debug("taskResults", taskResults)
            const rentUpdated = taskResults[0]
            commit('setDetail', {
                ...state.detail, 
                returnCheckList: rentUpdated.data.updateToolRent.returnCheckList,
                state: rentUpdated.data.updateToolRent.state, 
                executedRangeOfDates: {
                    start: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.start),
                    end: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.end),
                }, 
                executedDays: rentUpdated.data.updateToolRent.executedDays, 
                _version: rentUpdated.data.updateToolRent._version
            })
            console.debug("setRentReturned - end", state.detail)                                                  
        },
        async createIssue({commit, dispatch, state}, issue){
            const issueCreated = await dispatch("issues/SaveIssue", issue, {root: true})
            commit('setDetail', {
                ...state.detail, 
                state: 'ARBITRATION_IN_PROCESS', 
                _version: issueCreated.rent._version
            })
        },
        async setRentPendingForPaymentAfterReturn({state, commit}, {checklist}){
            if (!state.detail){
                throw Error('No detail loaded')
            }
            if (state.detail.state != "IN_PROCESS"){
                throw Error('Wrong current status')
            }

            const executedDays = quantityDaysInRange(state.detail.executedRangeOfDates.start, new Date())

            const input = {
                id: state.detail.id,
                returnCheckList: checklist.map((checklistEntry) => removeAttrs(checklistEntry,['ref', 'hidden'])).filter((checklistEntry) => checklistEntry.value !== null),
                state:"PENDING_FOR_PAY_EXTRA_DAYS",
                executedRangeOfDates: {
                    start: state.detail.executedRangeOfDates.start, 
                    end: new Date() 
                }, 
                executedDays: executedDays,
                _version: state.detail._version
            }

            const rentUpdated = await API.graphql({...graphqlOperation(updateChecklistInToolRent, {input: input})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('setDetail', {
                ...state.detail, 
                returnCheckList: rentUpdated.data.updateToolRent.returnCheckList,
                state: rentUpdated.data.updateToolRent.state, 
                executedRangeOfDates: {
                    start: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.start),
                    end: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.end),
                }, 
                executedDays: rentUpdated.data.updateToolRent.executedDays, 
                _version: rentUpdated.data.updateToolRent._version
            })                                                  
        },
        async setRentFinished({state, commit, dispatch}){
            if (!state.detail){
                throw Error('No detail loaded')
            }
            if (state.detail.state != "PENDING"){
                throw Error('Wrong current status')
            }

            const profitInfo = {
                description: `Profit for rent of tool ${state.detail.publication.title}`, 
                amount: state.detail.subtotal - state.detail.ownerServiceFee, 
                user: state.detail.publication.user,
                rentId: state.detail.id
            }

            
            const taskResults = await Promise.all(
                [
                    API.graphql(
                        {
                            ...graphqlOperation(updateChecklistInToolRent, {
                            input: {
                                id: state.detail.id, state:"FINISHED", 
                                executedRangeOfDates: { start: new Date(), end: new Date() }, 
                                executedDays: 0, 
                                _version: state.detail._version
                            }})
                            , authMode: 'AMAZON_COGNITO_USER_POOLS'
                        }
                    ),
                    dispatch("maintainer_balance/addProfitTransactionToBalance", profitInfo, {root: true})
                ]
            );
            const rentUpdated = taskResults[0]
            commit('setDetail', {
                    ...state.detail, 
                    state: rentUpdated.data.updateToolRent.state, 
                    executedRangeOfDates: {
                        start: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.start),
                        end: parseStringToDate(rentUpdated.data.updateToolRent.executedRangeOfDates.end),
                    }, 
                    executedDays: rentUpdated.data.updateToolRent.executedDays, 
                    _version: rentUpdated.data.updateToolRent._version
                })                                                  
        },
        async getDeliveryCheckListImages({state}, {user, files}) { 
            const result = await Storage.list(`rent/${state.detail.id}/deliveryCheckList`, { level: 'protected', identityId: user });
            if (result?.length > 0) {
                if (files?.length > 0) {   
                    result.sort((a, b) => files.indexOf(getFileNameFromKey(a.key)) - files.indexOf(getFileNameFromKey(b.key)));
                }
            }
            return result;
        },
        async revertRentPayment({state, commit, dispatch}){
            console.debug("revertRentPayment - start")

            if (!state.detail){
                throw Error('No detail loaded')
            }
            if (state.detail.state === "UNPAID"){
                throw Error('Wrong current status')
            }

            if (!state.detail.paymentsInfo){
                throw Error('No Payment info')
            }

            const paymentsToRevert = [
                ...state.detail.paymentsInfo.filter((payment) => payment.status !=="PENDING" && payment.balance > 0)
            ]
        
            // Se reversan los pagos con saldo disponible
            for (const paymentInfo of paymentsToRevert) {
                const rentUpdated = await dispatch(
                    "payments/Reverse",
                    {
                        rentId: state.detail.id,
                        transactionId: paymentInfo.transactionId,
                    },
                    { root:true }
                );
                commit('setDetail', {
                    ...state.detail, 
                    paymentsInfo: rentUpdated.paymentsInfo, 
                    _version: rentUpdated._version
                })  
            }

            // Se marcan como reversados los pagos no procesados
            const payments = state.detail.paymentsInfo.map(
                (payment) => payment.status === "PENDING" ? {
                    ...payment, 
                    status:"REVERSED", 
                    reverseHistory: {
                        status:"REVERSED",
                        date: new Date(),
                        type: "REVERSE"
                    }
                } : payment)

            //Se actualiza el estado del rent
            const rentUpdated = await API.graphql({
                ...graphqlOperation(
                    updateToolRent, 
                    {
                        input: {
                            id: state.detail.id, 
                            state: state.detail.state,
                            paymentsInfo: payments,
                            _version: state.detail._version
                        }
                    })
                , authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            commit('setDetail', {
                ...state.detail, 
                state: rentUpdated.data.updateToolRent.state,
                _version: rentUpdated.data.updateToolRent._version
            })                                                  
            console.debug("revertRentPayment - end")
        },
        async savePaymentInfoForDelayedReturnAndFinish(context, {rentId, version, delayedDaysFee, delayedDaysTax, paymentsInfo}){

            const input = {
                id: rentId, 
                delayedDaysFee: delayedDaysFee,
                delayedDaysTax: delayedDaysTax,
                paymentsInfo: paymentsInfo, 
                _version: version,
                state:"FINISHED"

            }

            await API.graphql({...graphqlOperation(updateToolRent, {input: input})
                                                             , authMode: 'AMAZON_COGNITO_USER_POOLS'});


        },
        async cancelRent({state, dispatch, commit}, isCancelationFree){

            if (state.detail.state !== "PENDING"){
                throw Error('Wrong current status')
            }

            if (isCancelationFree){

                // Se fija el estado con el que se quiere que quede el rent despues de anular los pagos
                commit('setDetail', {
                    ...state.detail, 
                    state: "CANCELLED"
                })
                //Se llama al proceso de anulacion de pagos
                await dispatch("revertRentPayment");
            } else {
                //Se actualiza el estado directamente sin ningun otra operacion 
                const input = {
                    id: state.detail.id,
                    state:"CANCELLED",
                    _version: state.detail._version
                }
                const rentUpdated = await API.graphql({...graphqlOperation(updateChecklistInToolRent, {input: input})
                                                                , authMode: 'AMAZON_COGNITO_USER_POOLS'});
                commit('setDetail', {
                    ...state.detail, 
                    state: rentUpdated.data.updateToolRent.state, 
                    _version: rentUpdated.data.updateToolRent._version
                })
            }

            

        },
        async setDeliveryCheckList({state, dispatch, commit}, checklist){

            const rentUpdated = await dispatch("payments/CaptureTransactions",{ rent:state.detail, checklist: checklist }, { root:true });

            if (rentUpdated.executedRangeOfDates){
                rentUpdated.executedRangeOfDates = {
                    start: parseStringToDate(rentUpdated.executedRangeOfDates.start),
                    end: rentUpdated.executedRangeOfDates.end ? parseStringToDate(rentUpdated.executedRangeOfDates.end) : null,
                }
            }

            commit('setDetail', {
                ...state.detail, 
                ...rentUpdated
            })

        },
        async refreshTotals({state, dispatch, commit}){

            //Se calcula informacion de dias atrasados para complementar informacion de rent
            state.detail.executedDays = state.detail.executedRangeOfDates 
            ? quantityDaysInRange(state.detail.executedRangeOfDates.start, new Date())
            : 0;
            const rentUpdated = await dispatch("rent/calculateTotals", {
                    rent: state.detail,
                    originalRangeOfDates: state.detail.requestedRangeOfDates,
                }, 
                {
                    root: true
                }
            );

            commit('setDetail', {
                ...state.detail,
                ...rentUpdated
            })

        }
    }
}