<template>
  <n-grid :cols="fullSize ? '1' : '1 s:4'" responsive="screen" style="padding-top: 30px">
    <n-gi :span="fullSize ? '1' : '1 s:2'" :offset="fullSize ? '0' : '0 s:1'">
      <n-tabs type="segment">
        <n-tab-pane name="notifications" :tab="t('commons.labels.unread')">
          <notification
            v-for="option in options"
            :key="option.id"
            :notification="option"
            @onNavigate="emit('onNavigate')"
          >
          </notification>
          <n-empty
            v-if="options.length == 0"
            :description="t('home.notifications.tabs.unread.noNotifications')"
          ></n-empty>
        </n-tab-pane>
        <n-tab-pane name="settings" :tab="t('commons.labels.setting', 2)">
          <settings />
        </n-tab-pane>
      </n-tabs>
    </n-gi>
  </n-grid>
</template>

<script>
import { defineComponent, computed } from "vue";
import Notification from "@/components/notifications/Notification.vue";
import Settings from "@/components/notifications/Settings.vue";
import { NEmpty, NGrid, NGi, NTabs, NTabPane } from "naive-ui";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "NotificationsView",
  components: {
    Notification,
    Settings,
    NEmpty,
    NGrid,
    NGi,
    NTabs,
    NTabPane,
  },
  props: {
    fullSize: { type: Boolean, default: false },
  },
  emits: ["onNavigate"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const store = useStore();

    const notifications = computed(() =>
      store.state.notifications.list.filter((notification) => !notification.read)
    );

    return {
      t,
      props,
      emit,
      options: notifications,
    };
  },
});
</script>
