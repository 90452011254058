import { API, graphqlOperation } from "aws-amplify";
import { createCategory, updateCategory } from "../../../graphql/mutations";
import { getCategory, listCategories } from "../../../graphql/queries";


export const maintainer_category = {
    namespaced: true,
    state: () => ({
        categories: []
    }),
    mutations: {
        addCategory (state, newCategory) {
            state.categories.push(newCategory);
        },
        fillCategories(state, categories) {
            state.categories = categories
        }
    },
    actions: {
        async createCategory({ commit }, newCategory) {
            const categoryCreated = await API.graphql({...graphqlOperation(createCategory, {input: newCategory})
                                                          , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const data = categoryCreated.data.createCategory;
            commit('addCategory', data);
            return data;
        },
        // eslint-disable-next-line no-unused-vars
        async updateCategory(context, category) {   
            return API.graphql({...graphqlOperation(updateCategory, {input: category})
                                   , authMode: 'AMAZON_COGNITO_USER_POOLS'});
        },

        async fillCategories({ commit }) {
            const categories_raw = await API.graphql(graphqlOperation(listCategories));
            commit('fillCategories', categories_raw.data.listCategories.items);
        },
        async getCategories() {
            const categories_raw = await API.graphql(graphqlOperation(listCategories));
            return categories_raw.data.listCategories.items;
        },
        async getCategoryById(context, id) {
            const category = await API.graphql(graphqlOperation(getCategory, {id: id}));
            return category.data.getCategory;
        },
        async getChildsByCategoryId(context, id) {
            const childs = await API.graphql(graphqlOperation(listCategories, { filter: { parentID: { eq :id } } } ));
            return childs.data.listCategories.items;
        }
        ,
        async getAvailableParents(context, { id, childsIds } ) {
            const filter = {}
            if (id){
                filter.and = { 
                    id: {
                        ne: id
                    }
                }
            }
            const parentOptions = await API.graphql(graphqlOperation(listCategories, { filter: filter } ));
            const items = parentOptions.data.listCategories.items; 
            
            return items.filter((value) =>  !childsIds.includes(value.id))
        },
        
    }
}