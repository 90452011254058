/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchActivePublications = /* GraphQL */ `
  query SearchActivePublications(
    $title: String
    $from: Int!
    $size: Int!
    $minLat: Float
    $maxLat: Float
    $minLong: Float
    $maxLong: Float
    $categoryIDs: [String]
  ) {
    searchActivePublications(
      title: $title
      from: $from
      size: $size
      minLat: $minLat
      maxLat: $maxLat
      minLong: $minLong
      maxLong: $maxLong
      categoryIDs: $categoryIDs
    ) {
      items {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      total
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      parentID
      parent {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      checkListBaseFields {
        id
        name
        type
      }
      pricePerDayReference
      depositReference
      showInHome
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $id: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPublication = /* GraphQL */ `
  query GetPublication($id: ID!) {
    getPublication(id: $id) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      category {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      rents {
        items {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      questions {
        items {
          id
          question
          answer
          publicationID
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPublications = /* GraphQL */ `
  query ListPublications(
    $id: ID
    $filter: ModelPublicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPublications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPublications = /* GraphQL */ `
  query SyncPublications(
    $filter: ModelPublicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPublications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const searchPublications = /* GraphQL */ `
  query SearchPublications(
    $filter: SearchablePublicationFilterInput
    $sort: [SearchablePublicationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePublicationAggregationInput]
  ) {
    searchPublications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getToolRent = /* GraphQL */ `
  query GetToolRent($id: ID!) {
    getToolRent(id: $id) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      depositTransaction {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      depositTransactionHistory {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      limitDaysForCancelationFree
      paymentsInfo {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      issues {
        items {
          id
          subject
          description
          datetime
          state
          rentId
          relatedUsers
          snapshotRentState
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listToolRents = /* GraphQL */ `
  query ListToolRents(
    $id: ID
    $filter: ModelToolRentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listToolRents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncToolRents = /* GraphQL */ `
  query SyncToolRents(
    $filter: ModelToolRentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncToolRents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      name
      familyName
      phoneNumber
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notifications {
        items {
          id
          userId
          fromUserId
          title
          message
          datetime
          read
          sentState
          linkTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
        }
      }
      balanceTransaction {
        items {
          id
          date
          description
          type
          status
          amount
          notes
          rentId
          userId
          payoutTransactionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      verified
      acceptDoR
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      fromUserId
      title
      message
      datetime
      read
      sentState
      linkTitle
      avatar {
        publicationID
        publicationUser
        defaultImage
      }
      targetRoute {
        name
        params {
          key
          value
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          familyName
          phoneNumber
          paymentCustomerId
          verified
          acceptDoR
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        fromUserId
        title
        message
        datetime
        read
        sentState
        linkTitle
        avatar {
          publicationID
          publicationUser
          defaultImage
        }
        targetRoute {
          name
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifications = /* GraphQL */ `
  query SyncNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          familyName
          phoneNumber
          paymentCustomerId
          verified
          acceptDoR
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        fromUserId
        title
        message
        datetime
        read
        sentState
        linkTitle
        avatar {
          publicationID
          publicationUser
          defaultImage
        }
        targetRoute {
          name
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getParameter = /* GraphQL */ `
  query GetParameter($id: ID!) {
    getParameter(id: $id) {
      id
      name
      type
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listParameters = /* GraphQL */ `
  query ListParameters(
    $id: ID
    $filter: ModelParameterFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParameters(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncParameters = /* GraphQL */ `
  query SyncParameters(
    $filter: ModelParameterFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncParameters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        type
        value
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      indexId
      content
      senderId
      username
      avatar
      system
      files {
        name
        size
        type
        extension
        audio
        duration
        key
        url
      }
      saved
      distributed
      seen
      deleted
      failure
      date
      timestamp
      disableActions
      disableReactions
      relatedUsers
      chatRoomId
      chatRoom {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChatMessages = /* GraphQL */ `
  query SyncChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      roomName
      unreadCount
      index
      lastMessage {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        _id
        username
        avatar
        isOnline
      }
      messages {
        items {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      typingUsers {
        _id
        username
        avatar
        isOnline
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $id: ID
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatRooms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChatRooms = /* GraphQL */ `
  query SyncChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatRooms(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      question
      answer
      publicationID
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $id: ID
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        question
        answer
        publicationID
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQuestions = /* GraphQL */ `
  query SyncQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question
        answer
        publicationID
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRentIssue = /* GraphQL */ `
  query GetRentIssue($id: ID!) {
    getRentIssue(id: $id) {
      id
      subject
      description
      datetime
      state
      evidences {
        id
        subject
        datetime
        owner
        ownerId
        description
        keys
      }
      rentId
      rent {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        rentState
        type
        relatedTransactionsId
      }
      snapshotRentState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRentIssues = /* GraphQL */ `
  query ListRentIssues(
    $id: ID
    $filter: ModelRentIssueFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRentIssues(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        subject
        description
        datetime
        state
        evidences {
          id
          subject
          datetime
          owner
          ownerId
          description
          keys
        }
        rentId
        rent {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        relatedUsers
        resolution {
          summary
          message
          datetime
          rentState
          type
          relatedTransactionsId
        }
        snapshotRentState
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRentIssues = /* GraphQL */ `
  query SyncRentIssues(
    $filter: ModelRentIssueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRentIssues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        subject
        description
        datetime
        state
        evidences {
          id
          subject
          datetime
          owner
          ownerId
          description
          keys
        }
        rentId
        rent {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        relatedUsers
        resolution {
          summary
          message
          datetime
          rentState
          type
          relatedTransactionsId
        }
        snapshotRentState
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      id
      from
      to
      userRate
      toolRate
      userReview
      toolReview
      datetime
      publicationID
      rentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $id: ID
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReviews(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        from
        to
        userRate
        toolRate
        userReview
        toolReview
        datetime
        publicationID
        rentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReviews = /* GraphQL */ `
  query SyncReviews(
    $filter: ModelReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        from
        to
        userRate
        toolRate
        userReview
        toolReview
        datetime
        publicationID
        rentId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserBalanceTransaction = /* GraphQL */ `
  query GetUserBalanceTransaction($id: ID!) {
    getUserBalanceTransaction(id: $id) {
      id
      date
      description
      type
      status
      amount
      notes
      rentId
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payoutTransactionId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUserBalanceTransactions = /* GraphQL */ `
  query ListUserBalanceTransactions(
    $id: ID
    $filter: ModelUserBalanceTransactionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserBalanceTransactions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        description
        type
        status
        amount
        notes
        rentId
        userId
        user {
          id
          email
          name
          familyName
          phoneNumber
          paymentCustomerId
          verified
          acceptDoR
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payoutTransactionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserBalanceTransactions = /* GraphQL */ `
  query SyncUserBalanceTransactions(
    $filter: ModelUserBalanceTransactionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserBalanceTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        date
        description
        type
        status
        amount
        notes
        rentId
        userId
        user {
          id
          email
          name
          familyName
          phoneNumber
          paymentCustomerId
          verified
          acceptDoR
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        payoutTransactionId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDepositTransactionsToRefund = /* GraphQL */ `
  query GetDepositTransactionsToRefund($id: ID!) {
    getDepositTransactionsToRefund(id: $id) {
      id
      transactionId
      status
      amount
      rentId
      issueId
      step
      errorCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDepositTransactionsToRefunds = /* GraphQL */ `
  query ListDepositTransactionsToRefunds(
    $id: ID
    $filter: ModelDepositTransactionsToRefundFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDepositTransactionsToRefunds(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        transactionId
        status
        amount
        rentId
        issueId
        step
        errorCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDepositTransactionsToRefunds = /* GraphQL */ `
  query SyncDepositTransactionsToRefunds(
    $filter: ModelDepositTransactionsToRefundFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDepositTransactionsToRefunds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        transactionId
        status
        amount
        rentId
        issueId
        step
        errorCount
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
