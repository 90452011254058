/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      parentID
      parent {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      checkListBaseFields {
        id
        name
        type
      }
      pricePerDayReference
      depositReference
      showInHome
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      parentID
      parent {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      checkListBaseFields {
        id
        name
        type
      }
      pricePerDayReference
      depositReference
      showInHome
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      parentID
      parent {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      name
      checkListBaseFields {
        id
        name
        type
      }
      pricePerDayReference
      depositReference
      showInHome
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      category {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      rents {
        items {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      questions {
        items {
          id
          question
          answer
          publicationID
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      category {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      rents {
        items {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      questions {
        items {
          id
          question
          answer
          publicationID
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePublication = /* GraphQL */ `
  mutation DeletePublication(
    $input: DeletePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    deletePublication(input: $input, condition: $condition) {
      id
      user
      title
      state
      description
      preferredUnitTime
      prices {
        pricePerDay
        offerDiscount
        weeklyDiscount
        monthlyDiscount
        discountedPricePerDayForWeeks
        discountedPricePerDayForMonths
        offerCancelationFree
        limitDaysForCancelationFree
      }
      depositRequired
      haveDelivery
      locationInfo {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      checkListFields {
        id
        name
        type
      }
      categoryID
      orderPhotos
      category {
        id
        parentID
        parent {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        name
        checkListBaseFields {
          id
          name
          type
        }
        pricePerDayReference
        depositReference
        showInHome
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      rents {
        items {
          id
          state
          publicationID
          requestedDays
          executedDays
          daysWithDiscount
          pricePerDay
          pricePerDayDiscounted
          baseCost
          extraDaysCost
          discount
          subtotal
          renterServiceFee
          ownerServiceFee
          tax
          renterServiceFeeRate
          ownerServiceFeeRate
          taxRate
          totalCost
          totalPayed
          pendingToPay
          taxOfPendingToPay
          deposit
          limitDaysForCancelationFree
          user
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      maintenances {
        id
        rangeOfDates {
          start
          end
        }
        state
      }
      questions {
        items {
          id
          question
          answer
          publicationID
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createToolRent = /* GraphQL */ `
  mutation CreateToolRent(
    $input: CreateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    createToolRent(input: $input, condition: $condition) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      depositTransaction {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      depositTransactionHistory {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      limitDaysForCancelationFree
      paymentsInfo {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      issues {
        items {
          id
          subject
          description
          datetime
          state
          rentId
          relatedUsers
          snapshotRentState
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateToolRent = /* GraphQL */ `
  mutation UpdateToolRent(
    $input: UpdateToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    updateToolRent(input: $input, condition: $condition) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      depositTransaction {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      depositTransactionHistory {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      limitDaysForCancelationFree
      paymentsInfo {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      issues {
        items {
          id
          subject
          description
          datetime
          state
          rentId
          relatedUsers
          snapshotRentState
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteToolRent = /* GraphQL */ `
  mutation DeleteToolRent(
    $input: DeleteToolRentInput!
    $condition: ModelToolRentConditionInput
  ) {
    deleteToolRent(input: $input, condition: $condition) {
      id
      state
      publicationID
      publication {
        id
        user
        title
        state
        description
        preferredUnitTime
        prices {
          pricePerDay
          offerDiscount
          weeklyDiscount
          monthlyDiscount
          discountedPricePerDayForWeeks
          discountedPricePerDayForMonths
          offerCancelationFree
          limitDaysForCancelationFree
        }
        depositRequired
        haveDelivery
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        checkListFields {
          id
          name
          type
        }
        categoryID
        orderPhotos
        category {
          id
          parentID
          name
          pricePerDayReference
          depositReference
          showInHome
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        rents {
          nextToken
          startedAt
        }
        maintenances {
          id
          state
        }
        questions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      requestedDays
      executedDays
      daysWithDiscount
      pricePerDay
      pricePerDayDiscounted
      baseCost
      extraDaysCost
      discount
      subtotal
      renterServiceFee
      ownerServiceFee
      tax
      renterServiceFeeRate
      ownerServiceFeeRate
      taxRate
      totalCost
      totalPayed
      pendingToPay
      taxOfPendingToPay
      rangeOfDatesPaying {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deposit
      depositTransaction {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      depositTransactionHistory {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      limitDaysForCancelationFree
      paymentsInfo {
        index
        transactionId
        status
        date
        purchaseOrderNumber
        history {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        reverseHistory {
          id
          status
          orderId
          date
          type
        }
        statusHistory {
          status
          terminal
          timestamp
        }
        type
        amount
        taxAmount
        balance
        bin
        lastTwo
        lastFour
        cardType
      }
      requestedRangeOfDates {
        start
        end
      }
      executedRangeOfDates {
        start
        end
      }
      rangeOfDatesHistory {
        correlative
        range {
          start
          end
        }
        type
        datetime
        totalDays
      }
      deliveryInfo {
        renterWillPickItUp
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
      }
      user
      relatedUsers
      chatRoomId
      deliveryCheckList {
        name
        value
        type
      }
      returnCheckList {
        name
        value
        type
      }
      issues {
        items {
          id
          subject
          description
          datetime
          state
          rentId
          relatedUsers
          snapshotRentState
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      name
      familyName
      phoneNumber
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notifications {
        items {
          id
          userId
          fromUserId
          title
          message
          datetime
          read
          sentState
          linkTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
        }
      }
      balanceTransaction {
        items {
          id
          date
          description
          type
          status
          amount
          notes
          rentId
          userId
          payoutTransactionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      verified
      acceptDoR
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      name
      familyName
      phoneNumber
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notifications {
        items {
          id
          userId
          fromUserId
          title
          message
          datetime
          read
          sentState
          linkTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
        }
      }
      balanceTransaction {
        items {
          id
          date
          description
          type
          status
          amount
          notes
          rentId
          userId
          payoutTransactionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      verified
      acceptDoR
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      name
      familyName
      phoneNumber
      addresses {
        id
        locationInfo {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        useForPayout
      }
      paymentCustomerId
      payoutProfile {
        externalId
        address {
          id
          street
          city
          postalCode
        }
        paymentMethods {
          type
          active
          email
          rountingNumber
          accountNumber
          nameAccountHolder
          externalId
        }
      }
      currentLocation {
        address
        coordinates {
          lat
          long
        }
        availabilityRatio
        municipality
        region
        postalCode
      }
      notifications {
        items {
          id
          userId
          fromUserId
          title
          message
          datetime
          read
          sentState
          linkTitle
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notificationSettings {
        channels {
          web
          mobile
          mail
          sms
        }
      }
      balanceTransaction {
        items {
          id
          date
          description
          type
          status
          amount
          notes
          rentId
          userId
          payoutTransactionId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      verified
      acceptDoR
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      fromUserId
      title
      message
      datetime
      read
      sentState
      linkTitle
      avatar {
        publicationID
        publicationUser
        defaultImage
      }
      targetRoute {
        name
        params {
          key
          value
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      fromUserId
      title
      message
      datetime
      read
      sentState
      linkTitle
      avatar {
        publicationID
        publicationUser
        defaultImage
      }
      targetRoute {
        name
        params {
          key
          value
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      fromUserId
      title
      message
      datetime
      read
      sentState
      linkTitle
      avatar {
        publicationID
        publicationUser
        defaultImage
      }
      targetRoute {
        name
        params {
          key
          value
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createParameter = /* GraphQL */ `
  mutation CreateParameter(
    $input: CreateParameterInput!
    $condition: ModelParameterConditionInput
  ) {
    createParameter(input: $input, condition: $condition) {
      id
      name
      type
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateParameter = /* GraphQL */ `
  mutation UpdateParameter(
    $input: UpdateParameterInput!
    $condition: ModelParameterConditionInput
  ) {
    updateParameter(input: $input, condition: $condition) {
      id
      name
      type
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteParameter = /* GraphQL */ `
  mutation DeleteParameter(
    $input: DeleteParameterInput!
    $condition: ModelParameterConditionInput
  ) {
    deleteParameter(input: $input, condition: $condition) {
      id
      name
      type
      value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      indexId
      content
      senderId
      username
      avatar
      system
      files {
        name
        size
        type
        extension
        audio
        duration
        key
        url
      }
      saved
      distributed
      seen
      deleted
      failure
      date
      timestamp
      disableActions
      disableReactions
      relatedUsers
      chatRoomId
      chatRoom {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      indexId
      content
      senderId
      username
      avatar
      system
      files {
        name
        size
        type
        extension
        audio
        duration
        key
        url
      }
      saved
      distributed
      seen
      deleted
      failure
      date
      timestamp
      disableActions
      disableReactions
      relatedUsers
      chatRoomId
      chatRoom {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      indexId
      content
      senderId
      username
      avatar
      system
      files {
        name
        size
        type
        extension
        audio
        duration
        key
        url
      }
      saved
      distributed
      seen
      deleted
      failure
      date
      timestamp
      disableActions
      disableReactions
      relatedUsers
      chatRoomId
      chatRoom {
        id
        roomName
        unreadCount
        index
        lastMessage {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        users {
          _id
          username
          avatar
          isOnline
        }
        messages {
          nextToken
          startedAt
        }
        typingUsers {
          _id
          username
          avatar
          isOnline
        }
        relatedUsers
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      roomName
      unreadCount
      index
      lastMessage {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        _id
        username
        avatar
        isOnline
      }
      messages {
        items {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      typingUsers {
        _id
        username
        avatar
        isOnline
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      roomName
      unreadCount
      index
      lastMessage {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        _id
        username
        avatar
        isOnline
      }
      messages {
        items {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      typingUsers {
        _id
        username
        avatar
        isOnline
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      roomName
      unreadCount
      index
      lastMessage {
        id
        indexId
        content
        senderId
        username
        avatar
        system
        files {
          name
          size
          type
          extension
          audio
          duration
          key
          url
        }
        saved
        distributed
        seen
        deleted
        failure
        date
        timestamp
        disableActions
        disableReactions
        relatedUsers
        chatRoomId
        chatRoom {
          id
          roomName
          unreadCount
          index
          relatedUsers
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      users {
        _id
        username
        avatar
        isOnline
      }
      messages {
        items {
          id
          indexId
          content
          senderId
          username
          avatar
          system
          saved
          distributed
          seen
          deleted
          failure
          date
          timestamp
          disableActions
          disableReactions
          relatedUsers
          chatRoomId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      typingUsers {
        _id
        username
        avatar
        isOnline
      }
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      publicationID
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      publicationID
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      answer
      publicationID
      relatedUsers
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRentIssue = /* GraphQL */ `
  mutation CreateRentIssue(
    $input: CreateRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    createRentIssue(input: $input, condition: $condition) {
      id
      subject
      description
      datetime
      state
      evidences {
        id
        subject
        datetime
        owner
        ownerId
        description
        keys
      }
      rentId
      rent {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        rentState
        type
        relatedTransactionsId
      }
      snapshotRentState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRentIssue = /* GraphQL */ `
  mutation UpdateRentIssue(
    $input: UpdateRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    updateRentIssue(input: $input, condition: $condition) {
      id
      subject
      description
      datetime
      state
      evidences {
        id
        subject
        datetime
        owner
        ownerId
        description
        keys
      }
      rentId
      rent {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        rentState
        type
        relatedTransactionsId
      }
      snapshotRentState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRentIssue = /* GraphQL */ `
  mutation DeleteRentIssue(
    $input: DeleteRentIssueInput!
    $condition: ModelRentIssueConditionInput
  ) {
    deleteRentIssue(input: $input, condition: $condition) {
      id
      subject
      description
      datetime
      state
      evidences {
        id
        subject
        datetime
        owner
        ownerId
        description
        keys
      }
      rentId
      rent {
        id
        state
        publicationID
        publication {
          id
          user
          title
          state
          description
          preferredUnitTime
          depositRequired
          haveDelivery
          categoryID
          orderPhotos
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        requestedDays
        executedDays
        daysWithDiscount
        pricePerDay
        pricePerDayDiscounted
        baseCost
        extraDaysCost
        discount
        subtotal
        renterServiceFee
        ownerServiceFee
        tax
        renterServiceFeeRate
        ownerServiceFeeRate
        taxRate
        totalCost
        totalPayed
        pendingToPay
        taxOfPendingToPay
        rangeOfDatesPaying {
          correlative
          type
          datetime
          totalDays
        }
        deposit
        depositTransaction {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        depositTransactionHistory {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        limitDaysForCancelationFree
        paymentsInfo {
          index
          transactionId
          status
          date
          purchaseOrderNumber
          type
          amount
          taxAmount
          balance
          bin
          lastTwo
          lastFour
          cardType
        }
        requestedRangeOfDates {
          start
          end
        }
        executedRangeOfDates {
          start
          end
        }
        rangeOfDatesHistory {
          correlative
          type
          datetime
          totalDays
        }
        deliveryInfo {
          renterWillPickItUp
        }
        user
        relatedUsers
        chatRoomId
        deliveryCheckList {
          name
          value
          type
        }
        returnCheckList {
          name
          value
          type
        }
        issues {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      relatedUsers
      resolution {
        summary
        message
        datetime
        rentState
        type
        relatedTransactionsId
      }
      snapshotRentState
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      from
      to
      userRate
      toolRate
      userReview
      toolReview
      datetime
      publicationID
      rentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview(
    $input: UpdateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    updateReview(input: $input, condition: $condition) {
      id
      from
      to
      userRate
      toolRate
      userReview
      toolReview
      datetime
      publicationID
      rentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview(
    $input: DeleteReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    deleteReview(input: $input, condition: $condition) {
      id
      from
      to
      userRate
      toolRate
      userReview
      toolReview
      datetime
      publicationID
      rentId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createUserBalanceTransaction = /* GraphQL */ `
  mutation CreateUserBalanceTransaction(
    $input: CreateUserBalanceTransactionInput!
    $condition: ModelUserBalanceTransactionConditionInput
  ) {
    createUserBalanceTransaction(input: $input, condition: $condition) {
      id
      date
      description
      type
      status
      amount
      notes
      rentId
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payoutTransactionId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUserBalanceTransaction = /* GraphQL */ `
  mutation UpdateUserBalanceTransaction(
    $input: UpdateUserBalanceTransactionInput!
    $condition: ModelUserBalanceTransactionConditionInput
  ) {
    updateUserBalanceTransaction(input: $input, condition: $condition) {
      id
      date
      description
      type
      status
      amount
      notes
      rentId
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payoutTransactionId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUserBalanceTransaction = /* GraphQL */ `
  mutation DeleteUserBalanceTransaction(
    $input: DeleteUserBalanceTransactionInput!
    $condition: ModelUserBalanceTransactionConditionInput
  ) {
    deleteUserBalanceTransaction(input: $input, condition: $condition) {
      id
      date
      description
      type
      status
      amount
      notes
      rentId
      userId
      user {
        id
        email
        name
        familyName
        phoneNumber
        addresses {
          id
          useForPayout
        }
        paymentCustomerId
        payoutProfile {
          externalId
        }
        currentLocation {
          address
          availabilityRatio
          municipality
          region
          postalCode
        }
        notifications {
          nextToken
          startedAt
        }
        balanceTransaction {
          nextToken
          startedAt
        }
        verified
        acceptDoR
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      payoutTransactionId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createDepositTransactionsToRefund = /* GraphQL */ `
  mutation CreateDepositTransactionsToRefund(
    $input: CreateDepositTransactionsToRefundInput!
    $condition: ModelDepositTransactionsToRefundConditionInput
  ) {
    createDepositTransactionsToRefund(input: $input, condition: $condition) {
      id
      transactionId
      status
      amount
      rentId
      issueId
      step
      errorCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateDepositTransactionsToRefund = /* GraphQL */ `
  mutation UpdateDepositTransactionsToRefund(
    $input: UpdateDepositTransactionsToRefundInput!
    $condition: ModelDepositTransactionsToRefundConditionInput
  ) {
    updateDepositTransactionsToRefund(input: $input, condition: $condition) {
      id
      transactionId
      status
      amount
      rentId
      issueId
      step
      errorCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteDepositTransactionsToRefund = /* GraphQL */ `
  mutation DeleteDepositTransactionsToRefund(
    $input: DeleteDepositTransactionsToRefundInput!
    $condition: ModelDepositTransactionsToRefundConditionInput
  ) {
    deleteDepositTransactionsToRefund(input: $input, condition: $condition) {
      id
      transactionId
      status
      amount
      rentId
      issueId
      step
      errorCount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
