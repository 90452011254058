<template>
  <n-input-group class="mb-4">
    <n-button type="primary" @click="getCurrentAddress">
      <template #icon>
        <n-icon>
          <location-outline />
        </n-icon>
      </template>
      {{ isMobile ? "" : t("commons.labels.current") }}</n-button
    >
    <n-select
      v-model:value="addressInput"
      filterable
      :placeholder="t('locationDialog.form.address.placeholder')"
      :options="listAddress"
      :loading="loading"
      :clearable="clearable"
      remote
      @search="lookForAddress"
      @clear="clearLocationSelected"
    />
  </n-input-group>
  <n-form-item :label="t('locationDialog.form.postalCode.label')" v-show="addressInput"
    ><n-input
      :placeholder="t('locationDialog.form.postalCode.placeholder')"
      v-model:value="model.postalCode"
  /></n-form-item>

  <template v-if="showAvailabilityRadio && model.address" class="mb-4">
    <n-text>
      {{
        t("locationDialog.form.availabilityRatio.label", {
          ratio: model.availabilityRatio,
          unit: t("commons.distances.unit"),
        })
      }}</n-text
    >
    <n-slider
      v-model:value="model.availabilityRatio"
      :format-tooltip="(value) => `${value} ${t('commons.distances.unit')}`"
      :step="1"
      class="mt-3"
      @update:value="updateCircle"
    />
    <n-divider />
  </template>
  <n-spin :show="addressInput != '' && loadingMap">
    <div id="mapPublication" ref="mapDrawer" class="mb-4"></div>
  </n-spin>
</template>
<script>
import "maplibre-gl/dist/maplibre-gl.css";
import { createMap, drawPoints } from "maplibre-gl-js-amplify";
import { ref, onMounted, watch } from "vue";
import { Geo } from "@aws-amplify/geo";
import { LocationOutline } from "@vicons/ionicons5";
import {
  useMessage,
  NInputGroup,
  NButton,
  NIcon,
  NSelect,
  NFormItem,
  NInput,
  NText,
  NSlider,
  NDivider,
  NSpin,
} from "naive-ui";
import VueGeolocation from "vue-browser-geolocation";
import { createGeoJSONCircle } from "@/shared/geo-utils";
import responsive from "@/mixins/responsive";
import { useI18n } from "vue-i18n";

export default {
  components: {
    LocationOutline,
    NInputGroup,
    NButton,
    NIcon,
    NSelect,
    NFormItem,
    NInput,
    NText,
    NSlider,
    NDivider,
    NSpin,
  },
  props: {
    modelValue: Object,
    showAvailabilityRadio: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [responsive],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });

    const loadingMapRef = ref(false);
    let map = null;
    const message = useMessage();
    const loading = ref(false);
    const modelRef = ref(props.modelValue);
    const addressInputRef = ref("");
    const listAddressRef = ref([]);
    const mapDrawerRef = ref(null);
    const pointMarker = (item) => {
      try {
        drawPoints(
          item.address,
          [
            {
              coordinates: item.coordinates,
              address: item.address,
            },
          ],
          map,
          {
            showCluster: true,
            unclusteredOptions: {
              showMarkerPopup: true,
            },
            clusterOptions: {
              showCount: true,
            },
          }
        );
      } catch (error) {
        return true; /*dummy*/
      }
    };
    const selectAddress = async (item) => {
      loadingMapRef.value = true;
      if (!map) {
        map = await createMap({
          container: "mapPublication",
          center: item.coordinates, // [Longitude, Latitude]
          zoom: 10,
        });
        map.on("load", () => {
          if (props.showAvailabilityRadio) {
            map.addSource(
              "rtr-source-circle",
              createGeoJSONCircle(
                item.coordinates,
                item.availabilityRatio
                  ? item.availabilityRatio
                  : modelRef.value.availabilityRatio
              )
            );
            map.addLayer({
              id: "rtr-layer-circle",
              type: "fill",
              source: "rtr-source-circle",
              layout: {},
              paint: {
                "fill-color": "green",
                "fill-opacity": 0.2,
              },
            });
          }
          pointMarker(item);
        });
      } else {
        map.flyTo({ center: item.coordinates });
        pointMarker(item);
        if (props.showAvailabilityRadio) {
          map
            .getSource("rtr-source-circle")
            ?.setData(
              createGeoJSONCircle(
                item.coordinates,
                item.availabilityRatio
                  ? item.availabilityRatio
                  : modelRef.value.availabilityRatio
              ).data
            );
        }
      }
      modelRef.value = {
        address: item.address,
        coordinates: {
          long: item.coordinates.at(0),
          lat: item.coordinates.at(1),
        },
        availabilityRatio: modelRef.value.availabilityRatio,
        municipality: item.municipality,
        region: item.region,
        postalCode: item.postalCode,
      };

      emit("update:modelValue", modelRef.value);
      loadingMapRef.value = false;
    };
    const clearLocationSelected = () => {
      map = null;
      modelRef.value = {
        address: null,
        coordinates: null,
        availabilityRatio: 5,
        municipality: null,
        region: null,
        postalCode: null,
      };

      mapDrawerRef.value.innerHTML = "";
      emit("update:modelValue", modelRef.value);
    };

    const updateCircle = (value) => {
      map
        .getSource("rtr-source-circle")
        ?.setData(
          createGeoJSONCircle(
            [modelRef.value.coordinates.long, modelRef.value.coordinates.lat],
            value
          ).data
        );
    };
    watch(addressInputRef, (newAddress) => {
      const item = listAddressRef.value.filter(
        (address) => address.value === newAddress
      )[0];
      if (item) {
        selectAddress(item.data);
      }
    });
    watch(modelRef, () => {
      emit("update:modelValue", modelRef.value);
    });
    onMounted(async () => {
      if (modelRef.value?.coordinates?.long && modelRef.value?.coordinates?.lat) {
        addressInputRef.value = modelRef.value.address;
        await selectAddress({
          address: modelRef.value?.address,
          coordinates: [modelRef.value.coordinates.long, modelRef.value.coordinates.lat],
          availabilityRatio: modelRef.value?.availabilityRatio,
          municipality: modelRef.value?.municipality,
          region: modelRef.value?.region,
          postalCode: modelRef.value?.postalCode,
        });
      }
    });
    return {
      t,
      mapDrawer: mapDrawerRef,
      addressInput: addressInputRef,
      model: modelRef,
      listAddress: listAddressRef,
      selectAddress,
      getCurrentAddress() {
        VueGeolocation.getLocation({ enableHighAccuracy: true })
          .then(async (x) => {
            const address = await Geo.searchByCoordinates([x.lng, x.lat]);
            addressInputRef.value = address.label;
            await selectAddress({
              address: address.label,
              coordinates: address.geometry.point,
              availabilityRatio: 5,
              municipality: address.municipality,
              region: address.region,
              postMessage: address.postalCode,
            });
          })
          .catch((error) => message.error(error));
      },
      lookForAddress(query) {
        if (query.length > 3) {
          loading.value = true;
          Geo.searchByText(query, {
            countries: ["USA"],
            maxResults: 6,
          }).then((result) => {
            loading.value = false;
            listAddressRef.value = result
              .filter((item) => item.municipality)
              .map((item) => {
                return {
                  label: item.label,
                  value: item.label,
                  data: {
                    address: item.label,
                    coordinates: item.geometry.point,
                    municipality: item.municipality,
                    region: item.region,
                    postalCode: item.postalCode,
                  },
                };
              });
          });
        }
      },
      updateCircle,
      loading,
      loadingMap: loadingMapRef,
      clearLocationSelected,
    };
  },
};
</script>
<style>
.card-address-location-finder {
  max-width: 285px;
  text-decoration: none;
  cursor: pointer;
}
#mapPublication {
  height: 350px;
  width: 90%;
  margin: auto;
}
</style>
