import { API, graphqlOperation, Auth } from "aws-amplify";
import {
  updateUserDeep,
  createPartialUser,
  updateUserInformation,
} from "../../graphql/custom/mutations";
import { getUserDeep, getUsername } from "../../graphql/custom/queries";

const apiDeviceName = "device";
const pathDeviceCreate = "/create";
const pathDeviceUpdate = "/update";

const apiPayouts = "payouts";
const pathSaveProfile = "/save-profile";

export const user = {
  namespaced: true,
  state: () => ({
    userFromDB: null,
    tmpCurrentLocation: null,
  }),
  getters: {
    getCurrentLocation: (state) =>
      state.userFromDB
        ? state.userFromDB.currentLocation
        : state.tmpCurrentLocation,
    is_acceptedDoR: (state) => !!state.userFromDB?.acceptDoR,
    verified: (state) => state.userFromDB?.verified,
    isUserLoaded: (state) => !!state.userFromDB,
  },
  mutations: {
    setUserFromDB(state, userFromDB) {
      if (userFromDB && !userFromDB.addresses) {
        userFromDB.addresses = [];
      }
      if (userFromDB && !userFromDB.notificationSettings) {
        userFromDB.notificationSettings = {
          channels: {
            mail: true,
            mobile: true,
            sms: true,
            web: true,
            mobileDevices: null,
          },
        };
      }
      state.userFromDB = userFromDB;
    },
    setTmpCurrentLocation: (state, newCurrentLocation) =>
      (state.tmpCurrentLocation = newCurrentLocation),
  },
  actions: {
    async createUser({ dispatch }, newuser) {
      if (!newuser.addresses) {
        newuser.addresses = [];
      }
      const userCreated = await API.graphql(
        graphqlOperation(createPartialUser, { input: newuser })
      );
      const userId = userCreated.data.createUser.id;
      return dispatch("getUserById", userId);
    },
    async updateUser({ commit }, updatedUser) {
      updatedUser = (({
        id,
        email,
        name,
        familyName,
        phoneNumber,
        addresses,
        paymentCustomerId,
        _version,
      }) => ({
        id,
        email,
        name,
        familyName,
        phoneNumber,
        addresses,
        paymentCustomerId,
        _version,
      }))(updatedUser);
      const userUpdated = await API.graphql({
        ...graphqlOperation(updateUserDeep, { input: updatedUser }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const data = userUpdated.data?.updateUser;
      commit("setUserFromDB", data);
      return data;
    },
    async updateUserAddresses({ commit }, { userId, addresses, version }) {
      const userUpdated = await API.graphql({
        ...graphqlOperation(updateUserDeep, {
          input: { id: userId, addresses: addresses, _version: version },
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setUserFromDB", userUpdated.data?.updateUser);
      return {
        addresses: userUpdated.data?.updateUser.addresses,
        version: userUpdated.data?.updateUser._version,
      };
    },
    async getUserById({ commit, state, dispatch }, id) {
      const result = await API.graphql({
        ...graphqlOperation(getUserDeep, { id: id }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setUserFromDB", result.data?.getUser);

      if (state.tmpCurrentLocation) {
        dispatch("updateCurrentLocation", state.tmpCurrentLocation).then(() =>
          commit("setTmpCurrentLocation", null)
        );
      }

      return state.userFromDB;
    },
    async getUsernameById(context, id) {
      const result = await API.graphql(
        graphqlOperation(getUsername, { id: id })
      );
      return result.data?.getUser;
    },
    async updateCurrentLocation({ commit, state }, currentLocation) {
      if (state.userFromDB) {
        const userUpdated = await API.graphql({
          ...graphqlOperation(updateUserDeep, {
            input: {
              id: state.userFromDB.id,
              currentLocation: currentLocation,
              _version: state.userFromDB._version,
            },
          }),
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        commit("setUserFromDB", userUpdated.data?.updateUser);
      } else {
        commit("setTmpCurrentLocation", currentLocation);
      }
    },
    async cleanUser({ commit }) {
      commit("setUserFromDB", null);
    },
    async updateNotificationSettings({ state, commit }, notificationSettings) {
      const userUpdated = await API.graphql({
        ...graphqlOperation(updateUserDeep, {
          input: {
            id: state.userFromDB.id,
            notificationSettings: notificationSettings,
            _version: state.userFromDB._version,
          },
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setUserFromDB", userUpdated.data?.updateUser);
    },
    async registerDevice({ state, commit }, { token, deviceId }) {
      API.post(apiDeviceName, pathDeviceCreate, {
        body: {
          token,
        },
      })
        .then(async (response) => {
          const notificationSettings = state.userFromDB.notificationSettings;
          notificationSettings.channels.mobileDevices ??= [];
          notificationSettings.channels.mobileDevices.push({
            id: deviceId,
            endpoint: response.endpoint,
            enable: true,
          });
          console.warn(
            JSON.stringify(notificationSettings.channels.mobileDevices)
          );
          const userUpdated = await API.graphql({
            ...graphqlOperation(updateUserDeep, {
              input: {
                id: state.userFromDB.id,
                notificationSettings: notificationSettings,
                _version: state.userFromDB._version,
              },
            }),
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          commit("setUserFromDB", userUpdated.data?.updateUser);
        })
        .catch((error) => {
          console.warn("error");
          console.warn(JSON.stringify(error));
        });
    },
    async updateDevice({ state, commit }, { token, endpoint, deviceId }) {
      console.warn("send response");
      API.post(apiDeviceName, pathDeviceUpdate, {
        body: {
          token,
          endpoint,
        },
      })
        .then(async () => {
          const notificationSettings = state.userFromDB.notificationSettings;
          const devices = (notificationSettings.channels.mobileDevices ??= []);
          const device = devices.filter((x) => x.id == deviceId);
          if (device.length > 0) {
            device[0].enable = true;
            const userUpdated = await API.graphql({
              ...graphqlOperation(updateUserDeep, {
                input: {
                  id: state.userFromDB.id,
                  notificationSettings: notificationSettings,
                  _version: state.userFromDB._version,
                },
              }),
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            commit("setUserFromDB", userUpdated.data?.updateUser);
          }
        })
        .catch((error) => {
          console.warn("error");
          console.warn(JSON.stringify(error));
        });
    },
    async updatePayoutProfile({ state, commit }, payoutProfile) {
      const session = await Auth.currentSession();
      const response = await API.post(apiPayouts, pathSaveProfile, {
        body: {
          auth: session.getAccessToken().getJwtToken(),
          user: { ...state.userFromDB, payoutProfile: payoutProfile },
        },
      });
      commit("setUserFromDB", response);
    },
    async updateContactInfo({ state, commit }, user) {
      const userUpdated = await API.graphql({
        ...graphqlOperation(updateUserInformation, {
          input: {
            id: user.id,
            name: user.name,
            familyName: user.familyName,
            phoneNumber: user.phoneNumber,
            _version: user._version,
          },
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setUserFromDB", {
        ...state.userFromDB,
        name: user.name,
        familyName: user.familyName,
        phoneNumber: user.phoneNumber,
        _version: userUpdated.data?.updateUser._version,
      });
    },
    async updateDoR({ state, commit }, acceptDoR) {
      const input = {
        id: state.userFromDB.id,
        _version: state.userFromDB._version,
        acceptDoR: acceptDoR,
      };
      const userUpdated = await API.graphql({
        ...graphqlOperation(updateUserDeep, {
          input,
        }),
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      commit("setUserFromDB", userUpdated.data?.updateUser);
    },
  },
};
