import { API, graphqlOperation } from "aws-amplify";
import { createQuestion, updateQuestion, deleteQuestion } from "../../graphql/mutations";
import { listQuestions } from "../../graphql/queries";

const apiName = "questions";
const pathCreate = "/check-content";

export const questions = {
    namespaced: true,
    state: () => ({
        list: []
    }),
    mutations: {
        add(state, newQuestion) {
            state.list.push(newQuestion);
        },
        fillQuestions(state, list) {
            state.list = list
        },
        updateQuestion(state, questionUpdated) {
            state.list = state.list.map((question) => question.id === questionUpdated.id ? questionUpdated : question)
        },
        removeQuestion(state, id){
            state.list = state.list.filter((question) => question.id != id)
        }
    },
    actions: {
        async createQuestion({ commit }, newQuestion) {
            const questionCreated = await API.graphql({...graphqlOperation(createQuestion, {input: newQuestion})
                                                          , authMode: 'AMAZON_COGNITO_USER_POOLS'});
            const data = questionCreated.data.createQuestion;
            commit('add', data);
        },
        async addAnswer({state, commit}, {id, answer}) {
            const questionToUpdate  = state.list.filter((question) => question.id === id)[0]

            const questionUpdateRaw = await API.graphql({...graphqlOperation(updateQuestion, {input: {id: questionToUpdate.id, answer: answer, _version: questionToUpdate._version}})
                                   , authMode: 'AMAZON_COGNITO_USER_POOLS'});

            commit('updateQuestion', questionUpdateRaw.data.updateQuestion);
            
        },
        async fillQuestions({ commit }, publicationId) {
            const questions_raw = await API.graphql(graphqlOperation(listQuestions,  { filter: { publicationID: { eq: publicationId} }}));
            commit('fillQuestions', questions_raw.data.listQuestions.items);
        },
        async checkContent(context, content){
            return API.post(apiName, pathCreate, {
                body: {
                    content: content
                }
            });
        },
        async deleteQuestion({ commit }, {id, version}){
            await API.graphql({...graphqlOperation(deleteQuestion,  { input: {id: id, _version: version}}), authMode: 'AMAZON_COGNITO_USER_POOLS'});
            commit('removeQuestion', id);
        }
        
    }
}