export const notificationActionToRoute = (route) => {
    if (route) {
        const result = {
            name: route.name
        }
        if (route.params) {
            result.params = {};
            route.params.forEach((item) => result.params[item.key] = item.value);
        }
        result.query = {
            q: Date.now()
        }
        return result;
    }
}