import { getBounds, isPointWithinRadius } from 'geolib';


export const isCoordinatesInBounds = (point, centerPoint, radiusInMiles) => {
  var meters = radiusInMiles * 1.609344 * 1000;
  return isPointWithinRadius(
    { latitude: point.lat, longitude: point.long },
    { latitude: centerPoint.lat, longitude: centerPoint.long },
    meters
  );
}


export const getBoundsForCircle = (center, radiusInMiles, points) => {
    const coordinates = createCoordinateCircle(center, radiusInMiles, points);
    const coordinatesObj = coordinates.map(x => convertArrayToObjectCoordinates(x));
    return getBounds(coordinatesObj);
}

const convertArrayToObjectCoordinates = (coordinates) => {
    return { longitude: coordinates[0], latitude: coordinates[1] }
}


export const createCoordinateCircle = (center, radiusInMiles, points) => {
    if (!points) points = 64;

    var coords = {
      latitude: center[1],
      longitude: center[0],
    };

    var km = radiusInMiles * 1.609344;

    var ret = [];
    var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    var distanceY = km / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return ret;
}

export const createGeoJSONCircle = (center, radiusInMiles, points) => {
    var ret = createCoordinateCircle(center, radiusInMiles, points);
    
    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [ret],
            },
          },
        ],
      },
    };
  };