import { createApp } from "vue";
import { App } from '@capacitor/app';
import VueApp from "./App.vue";
import router from "./router";
import store from "./store";
import { Amplify } from "aws-amplify";
import {
  AmazonAIPredictionsProvider
} from '@aws-amplify/predictions';
import aws_exports from "./aws-exports";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import i18n from './i18n'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import  awsmobile  from './aws-exports'


Amplify.configure(aws_exports);
Amplify.addPluggable(new AWSIoTProvider({
  aws_pubsub_region: 'us-east-1',
  aws_pubsub_endpoint: 'wss://a3qb1cl2xa2t9q-ats.iot.us-east-1.amazonaws.com/mqtt',
}));
Amplify.addPluggable(new AmazonAIPredictionsProvider());
applyPolyfills().then(() => {
  defineCustomElements(window);
});
const app = createApp(VueApp)


const env = awsmobile.aws_user_files_s3_bucket.split('-')[1]
let traceOrigin = ''
console.log(`Current env: ${env}`)

switch (env){
  case 'dev':
  case 'qa':
    traceOrigin = `${env}.righttoolrental.com`
    break;
  default:
    traceOrigin = 'righttoolrental.com'

}

console.log(`trace origin ${traceOrigin}`)

Sentry.init({
  app,
  dsn: "https://86e0fe9c1ab7496f87f5794a978b37cf@o4503939459645440.ingest.sentry.io/4503939466133504",
  environment: env,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', traceOrigin, /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


app.use(i18n).use(store).use(router).mount("#app");

App.addListener('appUrlOpen', function (event) {
  console.log('event', event)
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('.com').pop();
  console.log('slug', slug)

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});