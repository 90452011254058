import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import { notificationActionToRoute } from "../shared/navigations";
import {
  LogInOutline as LoginIcon,
  LogOutOutline as LogoutIcon,
  Options as ParametersIcon,
  BuildOutline as RentIcon,
} from "@vicons/ionicons5";
import {
  Category as CategoryIcon,
  Tools as ToolsIcon,
  Folders as IssuesIcon,
  User as UserIcon,
} from "@vicons/carbon";

const ADMIN = "admins";
const USERS = "users";
const ASSISTANT = "assistant";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/nav/:to",
    redirect: (navTo) => notificationActionToRoute(JSON.parse(navTo.params.to)),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/Test.vue"),
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/Notifications.vue"),
  },
  {
    path: "/rent/:publicationId/:rentId?",
    name: "rent",
    props: true,
    component: () => import("../views/Rent.vue"),
    meta: {
      sessionRequired: true,
      roles: [USERS],
    },
  },
  {
    path: "/publications/:publicationId",
    name: "view-publication",
    props: true,
    component: () => import("../views/ViewPublication.vue"),
  },
  {
    path: "/my-profile",
    redirect: "/my-profile",
    name: "profile",
    meta: {
      sessionRequired: true,
      roles: [USERS],
      render: {
        name: "My Profile",
        position: 1,
        inSessionActive: true,
        inSessionInactive: false,
        icon: UserIcon,
      },
    },
    component: () => import("../views/myProfile"),
    children: [
      {
        path: "dischargeResponsibility",
        name: "discharge-responsibility",
        props: true,
        component: () =>
          import("../views/myProfile/DischargeResponsibility.vue"),
      },
      {
        path: "",
        name: "my-profile",
        props: true,
        component: () => import("../views/myProfile/MyProfile.vue"),
      },
    ],
  },
  {
    path: "/maintainers/categories",
    redirect: "/maintainers/categories",
    name: "categories",
    meta: {
      sessionRequired: true,
      roles: [ADMIN],
      render: {
        name: "Categories",
        position: 2,
        inSessionActive: true,
        inSessionInactive: false,
        icon: CategoryIcon,
      },
    },
    component: () => import("../views/maintainers/categories"),
    children: [
      {
        path: "create",
        name: "create-category",
        component: () =>
          import("../views/maintainers/categories/CreateUpdate.vue"),
      },
      {
        path: ":categoryId/update",
        name: "update-category",
        props: true,
        component: () =>
          import("../views/maintainers/categories/CreateUpdate.vue"),
      },
      {
        path: "",
        name: "list-categories",
        component: () =>
          import("../views/maintainers/categories/ListCategories.vue"),
      },
    ],
  },
  {
    path: "/publications",
    redirect: "/publications",
    name: "my-publications",
    meta: {
      sessionRequired: true,
      roles: [ADMIN, USERS],
      render: {
        name: "My Publications",
        position: 3,
        inSessionActive: true,
        inSessionInactive: false,
        icon: ToolsIcon,
      },
    },
    component: () => import("../views/maintainers/publication"),
    children: [
      {
        path: "create",
        name: "create-publication",
        component: () =>
          import("../views/maintainers/publication/CreateUpdate.vue"),
      },
      {
        path: ":publicationId/update",
        name: "update-publication",
        props: true,
        component: () =>
          import("../views/maintainers/publication/CreateUpdate.vue"),
      },
      {
        path: ":publicationId/calendar",
        name: "calendar",
        props: true,
        component: () =>
          import("../views/maintainers/publication/StatsAndCalendar.vue"),
      },
      {
        path: ":publicationId/questions",
        name: "questions",
        props: true,
        component: () =>
          import("../views/maintainers/publication/Questions.vue"),
      },
      {
        path: "",
        name: "list-publication",
        component: () =>
          import("../views/maintainers/publication/ListPublications.vue"),
      },
    ],
  },
  {
    path: "/maintainers/parameters",
    redirect: "/maintainers/parameters",
    name: "parameters",
    meta: {
      sessionRequired: true,
      roles: [ADMIN],
      render: {
        name: "Parameters",
        position: 4,
        inSessionActive: true,
        inSessionInactive: false,
        icon: ParametersIcon,
      },
    },
    component: () => import("../views/maintainers/parameters"),
    children: [
      {
        path: "create",
        name: "create-parameter",
        component: () =>
          import("../views/maintainers/parameters/CreateUpdate.vue"),
      },
      {
        path: ":id/update",
        name: "update-parameter",
        props: true,
        component: () =>
          import("../views/maintainers/parameters/CreateUpdate.vue"),
      },
      {
        path: "",
        name: "list-parameters",
        component: () => import("../views/maintainers/parameters/List.vue"),
      },
    ],
  },
  {
    path: "/rents",
    redirect: "/rents",
    name: "my-rents",
    meta: {
      sessionRequired: true,
      roles: [ADMIN, USERS],
      render: {
        name: "My Rents",
        position: 5,
        inSessionActive: true,
        inSessionInactive: false,
        icon: RentIcon,
      },
    },
    component: () => import("../views/maintainers/rents"),
    children: [
      {
        path: ":id/detail",
        name: "view-rent-detail",
        props: true,
        component: () => import("../views/maintainers/rents/Details.vue"),
      },
      {
        path: ":id/chat",
        name: "rent-chat",
        props: true,
        component: () => import("../views/maintainers/rents/Chat.vue"),
      },
      {
        path: ":rentId/checklist/:checklistType",
        name: "checklist",
        props: true,
        component: () =>
          import("../views/maintainers/rents/Checklist/index.vue"),
      },
      {
        path: ":rentId/issue/create",
        name: "issue-create",
        props: true,
        component: () => import("../views/issues/CreateIssue.vue"),
      },
      {
        path: ":rentId/review",
        name: "review",
        props: true,
        component: () => import("../views/maintainers/rents/Review.vue"),
      },
      {
        path: "",
        name: "list-rent",
        component: () => import("../views/maintainers/rents/List.vue"),
      },
    ],
  },
  {
    path: "/my-profits",
    redirect: "/my-profits",
    name: "my-profits",
    meta: {
      sessionRequired: true,
      roles: [ADMIN, USERS],
    },
    component: () => import("../views/maintainers/balance"),
    children: [
      {
        path: "",
        name: "list-transactions",
        component: () => import("../views/maintainers/balance/List.vue"),
      },
    ],
  },
  {
    path: "/issues",
    redirect: "/issues",
    name: "issues",
    props: true,
    meta: {
      render: {
        name: "Issues",
        position: 6,
        inSessionActive: true,
        inSessionInactive: false,
        icon: IssuesIcon,
      },
      sessionRequired: true,
      roles: [ADMIN, ASSISTANT],
    },
    component: () => import("../views/issues/index.vue"),
    children: [
      {
        path: ":id",
        name: "issue-detail",
        props: true,
        component: () => import("../views/issues/DetailIssue.vue"),
      },
      {
        path: "",
        name: "list-issues",
        component: () => import("../views/issues/ListIssues.vue"),
      },
    ],
  },
  {
    path: "/signIn/:redirect?",
    name: "sign-in",
    props: true,
    component: () => import("../views/SignIn.vue"),
    meta: {
      sessionRequired: false,
      render: {
        name: "Sign In",
        position: 10,
        inSessionActive: false,
        inSessionInactive: true,
        icon: LoginIcon,
      },
    },
  },
  {
    path: "/",
    name: "sign-out",
    component: Home,
    meta: {
      sessionRequired: true,
      render: {
        name: "Sign Out",
        position: 10,
        inSessionActive: true,
        inSessionInactive: false,
        icon: LogoutIcon,
      },
      handleUpdateValue: "signout",
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/privacy"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/terms"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: "hash",
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

const isValidateParams = (routeName, params) => {
  if (routeName === "checklist") {
    return (
      params.checklistType === "DELIVERY" || params.checklistType === "RETURN"
    );
  }
  return true;
};

const goNext = (to, next) => {
  if (isValidateParams(to.name, to.params)) {
    next();
  } else {
    next("/not-found");
  }
};

router.beforeResolve((to, from, next) => {
  if (to?.meta?.sessionRequired) {
    if (store.getters["auth/is_authenticated"]) {
      const roles = store.getters["auth/roles"]?.flat();
      if (
        to?.meta?.roles === undefined ||
        roles?.some((r) => to?.meta?.roles?.includes(r))
      ) {
        goNext(to, next);
      } else {
        next("/not-found");
      }
    } else {
      next({
        name: "sign-in",
        params: {
          redirect: to.path,
        },
      });
    }
  } else {
    goNext(to, next);
  }
});

export default router;
