import { API, graphqlOperation } from "aws-amplify";
import { createParameter, updateParameter } from "../../../graphql/mutations";
import { getParameter, listParameters } from "../../../graphql/queries";


export const maintainer_parameters = {
    namespaced: true,
    state: () => ({
        parameters: []
    }),
    getters: {
        options_parameter: (state) => state.parameters.map((x) => { return { label: x.name, value: x.id }})
    },
    mutations: {
        addParameter (state, newParameter) {
            state.parameters.push(newParameter);
        },
        fillParameters(state, parameters) {
            state.parameters = parameters
        }
    },
    actions: {
        async createParameter({ commit, dispatch }, newParameter) {
            const parameterCreated = await API.graphql({...graphqlOperation(createParameter, {input: newParameter}), authMode: 'AMAZON_COGNITO_USER_POOLS' });
            const data = parameterCreated.data.createParameter;
            commit('addParameter', data);
            dispatch("app/updateConfiguration", data, { root: true })
            return data;
        },
        // eslint-disable-next-line no-unused-vars
        async updateParameter({ dispatch }, parameter) {   
            const parameterUpdated = await API.graphql({...graphqlOperation(updateParameter, {input: parameter}), authMode: 'AMAZON_COGNITO_USER_POOLS' });
            const data = parameterUpdated.data.updateParameter
            dispatch("app/updateConfiguration", data, { root: true })
            return data
        },

        async fillParameters({ commit }) {
            const parameters_raw = await API.graphql(graphqlOperation(listParameters));
            commit('fillParameters', parameters_raw.data.listParameters.items);
        },
        async getParameters() {
            const parameters_raw = await API.graphql(graphqlOperation(listParameters));
            return parameters_raw.data.listParameters.items;
        },
        async getParameterById(context, id) {
            const parameter = await API.graphql(graphqlOperation(getParameter, {id: id}));
            return parameter.data.getParameter;
        },
        
    }
}